import firebase from 'firebase/app';
import { UserInfo, setUserInfo, setIsAdmin } from '../reducers/app';
import { fire } from '../config/fire';
import { store } from '../store';
import { User } from '../types/users';

const db = fire.firestore();
export const getAllUsers = async () => {
  try {
    const usersRef = db.collection('users');
    const snapshot = (await usersRef.get()) as firebase.firestore.QuerySnapshot<User>;
    const users: Array<User> = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      users.push({ ...data });
    });
    return users;
  } catch (e) {
    console.warn(e);
  }
  return Promise.resolve();
};

export const getSingleUser = async (id: string) => {
  if (id) {
    try {
      const userSnapShot = (await db.collection('users').doc(id).get()) as firebase.firestore.DocumentSnapshot<User>;
      const user = userSnapShot.data();
      return user;
    } catch (e) {
      console.warn(e);
    }
  }
  return '';
};

export const getAllUserFromTraining = async (trainingId: string) => {
  const userFromTraining: string[] = [];
  try {
    const ordersRef = db.collection('orders');
    const querieResponse = await ordersRef.where('trainingId', '==', trainingId).get();
    querieResponse.forEach((doc) => {
      userFromTraining.push(doc.data().userId);
    });
    return userFromTraining;
  } catch (e) {
    console.warn(e);
  }
  return null;
};

export const updateUserEmail = (id: string, updatedValue: string) => {
  try {
    if (id && updatedValue) {
      const collection = db.collection('users').doc(id);
      return collection.update({
        email: updatedValue,
      });
    }
  } catch (error) {
    console.warn(error);
  }
  return Promise.resolve();
};
export const updateUserHandicappedInfo = (id: string, userData: User) => {
  if (id && userData) {
    try {
      const collection = db.collection('users').doc(id);
      return collection.update({
        handicapped: userData.handicapped,
      });
    } catch (e) {
      console.warn(e);
    }
  }
  return Promise.resolve();
};

export const updateUserInfo = (id: string, userData: User) => {
  if (id && userData) {
    try {
      const collection = db.collection('users').doc(id);
      return collection.update({
        firstName: userData.firstName,
        lastName: userData.lastName,
        profession: userData.profession,
        zipCode: userData.zipCode,
        city: userData.city,
        country: userData.country,
        address: userData.address,
        compAddress: userData.compAddress,
        phone: userData.phone,
        birthPlace: userData.birthPlace,
        birthDate: userData.birthDate,
      });
    } catch (e) {
      console.warn(e);
    }
  }
  return Promise.resolve();
};

export const getUserInfo = async (id: string | undefined) => {
  if (id) {
    try {
      const userSnapShot = (await db
        .collection('users')
        .doc(id)
        .get()) as firebase.firestore.DocumentSnapshot<UserInfo>;
      const user = userSnapShot.data();
      if (user) {
        store.dispatch(setUserInfo(user));
        if (!user.admin) {
          console.warn('not admin');
        } else {
          store.dispatch(setIsAdmin(true));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
};

/* CREATING USER DOC TO DB WITH ID & EMAIL */

export const createUserToDb = (id: string, email: string | null) => {
  const collection = db.collection('users').doc(id);
  collection.set({
    uId: id,
    email,
  });
};
