/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import logo from '../../images/logo-iffco-transparent.png';
import PictureDisplayer from '../picture-displayer/PictureDisplayer';

export default function Footer() {
  return (
    <div className="main-container-footer">
      <div className="sub-container-footer">
        <div className="left-side-container-footer">
          <div className="logo-container-footer">
            <img src={logo} alt="logo-footer" />
          </div>
          <div className="description-container-footer">
            <div className="description-text-footer">
              IFFCO est un institut de formation continue spécialisé dans le domaine de l'ostéopathie à Montpellier.
              Nous proposons toute l'année des thématiques variées, adaptées à vos besoins. Contactez-nous pour plus de
              renseignements.
            </div>
          </div>
          <div className="social-network-icons-container-footer">
            <div className="facebook-icon-footer">
              <a href="https://www.facebook.com/iffcoosteopathie/" rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
            </div>
            <div className="linkedin-icon-footer">
              <a href="https://www.linkedin.com/in/iffcoosteo/?originalSubdomain=fr" rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </div>
        </div>
        <div className="right-side-container-footer">
          <div className="picture-displayer-main-container-footer">
            <div className="title-container-displayer-footer">
              <div className="title-pic-displayer-footer">Photos</div>
            </div>
            <PictureDisplayer />
          </div>
        </div>
      </div>
    </div>
  );
}
