/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { Link } from 'react-router-dom';
import './Drawer.css';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Divider from '@material-ui/core/Divider';
import { RootReducer, store } from '../../store';
import { AppState, setDrawerState } from '../../reducers/app';
import logo from '../../images/logo-header.png';
import logoDrawer from '../../images/iffco-logo-drawer.png';
import { logOut } from '../../firebase/firebase';

export default function DrawerHeader() {
  const { menu, drawerState, isAdmin } = useSelector<RootReducer, AppState>((state) => state.app);

  const toggleDrawer = (open: boolean) => {
    store.dispatch(setDrawerState(open));
  };

  const rotateChevron = (currentTarget: EventTarget & HTMLDivElement) => {
    const findClass = currentTarget;
    if (findClass) {
      if (findClass.classList.contains('rotate-chevron-drawer') === false) {
        findClass.classList.add('rotate-chevron-drawer');
      } else {
        findClass.classList.remove('rotate-chevron-drawer');
      }
    }
  };

  const mouseClick = (currentTarget: EventTarget & HTMLDivElement) => {
    const findClass = currentTarget.querySelector<HTMLDivElement>('.dropdown-content-drawer');
    if (findClass) {
      if (findClass.classList.contains('show-dropdown-content-drawer') === false) {
        findClass.classList.add('show-dropdown-content-drawer');
      } else {
        findClass.classList.remove('show-dropdown-content-drawer');
      }
    }
  };
  const closeDrawer = () => {
    store.dispatch(setDrawerState(false));
  };

  return (
    <div className="main-container-drawer">
      <div className="logo-drawer-container">
        <FontAwesomeIcon icon={faBars} onClick={() => toggleDrawer(true)} />
        <Link to="/">
          <img src={logo} alt="logo-header" />
        </Link>
      </div>
      <Drawer className="content-container-drawer-open" open={drawerState} onClose={() => toggleDrawer(false)}>
        <div className="main-div-drawer">
          <div className="drop-down-drawer close-icon-drawer">
            <img src={logoDrawer} alt="sigle-iffco" />
          </div>
          <Divider />
          {menu.map((item) => (
            <div
              key={item.title}
              onClick={({ currentTarget }) => mouseClick(currentTarget)}
              className="drop-down-drawer"
            >
              {item.pathName !== undefined ? (
                <Link to={`/${item.pathName}`} onClick={closeDrawer}>
                  <div className="dropbutton-drawer">{item.title}</div>
                </Link>
              ) : (
                <div className="nested-links-drawer" onClick={({ currentTarget }) => rotateChevron(currentTarget)}>
                  <div>{item.title}</div>
                  <div>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </div>
              )}
              <Divider />
              <div className="dropdown-content-drawer">
                {item.children.map((child) => (
                  <div key={child.title}>
                    <Link key={child.pathName} to={`/${child.pathName}`} onClick={closeDrawer}>
                      <div>{child.title}</div>
                    </Link>
                    <Divider />
                  </div>
                ))}
              </div>
            </div>
          ))}
          {isAdmin ? (
            <>
              <div className="log-out-button-drawer">
                <Link to="/admin">
                  <div>Admin</div>
                </Link>
              </div>
              <Divider />
            </>
          ) : (
            ''
          )}
          <div className="drop-down-drawer">
            <div className="log-out-button-drawer" onClick={logOut} role="button">
              <div>déconnexion</div>
            </div>
            <Divider />
          </div>
          <div className="social-network-icons-container-drawer">
            <div className="facebook-icon-drawer">
              <FontAwesomeIcon icon={faFacebookSquare} />
            </div>
            <div className="linkedin-icon-drawer">
              <FontAwesomeIcon icon={faLinkedin} />
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
