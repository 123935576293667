/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './CurrentOrderDisplayer.css';
import { getTraining } from '../../firestore/trainings';
import { FetchedTraining, TrainingsWithNameAndProfessionNames } from '../../types/trainings';
import { getTrainerName, getTrainerProfessions } from '../../firestore/trainers';
import showDates from '../../utils/dates-utils';

interface Props {
  trainingId: string;
}

export default function CurrentOrderDisplayer({ trainingId }: Props) {
  const [trainingInfos, setTrainingInfos] = useState<FetchedTraining>();
  const [newTrainingWithName, setNewTrainingWithName] = useState<TrainingsWithNameAndProfessionNames>();

  // FETCH TRAINING

  const getTrainingInfo = async () => {
    const training = await getTraining(trainingId);
    if (training) {
      setTrainingInfos(training);
    }
    return '';
  };

  useEffect(() => {
    getTrainingInfo();
  }, [trainingId]);

  // FETCH TRAINERS NAME

  const newTrainingList = async () => {
    if (trainingInfos) {
      const clonedTraining = JSON.parse(JSON.stringify(trainingInfos)) as TrainingsWithNameAndProfessionNames;
      const trainingObjectWithName = await Promise.all(clonedTraining.trainers.map((i) => getTrainerName(i)));
      clonedTraining.trainerName = trainingObjectWithName;
      const trainingObjectWithProfession = await Promise.all(
        clonedTraining.trainersProfession.map((i) => getTrainerProfessions(i)),
      );
      clonedTraining.professionName = trainingObjectWithProfession;
      setNewTrainingWithName(clonedTraining);
    }
  };

  useEffect(() => {
    newTrainingList();
  }, [trainingInfos]);

  return (
    <>
      {trainingInfos ? (
        <div className="main-container-current-order-displayer">
          <div className="current-order-displayer-title">Votre réservation :</div>
          <div>
            <h3>Formation:</h3>
            <div>{trainingInfos.title}</div>
          </div>
          <div>
            <h3>Formateur(s):</h3>
            <div> {newTrainingWithName?.trainerName ? newTrainingWithName.trainerName.join(' & ') : ''}</div>
          </div>
          <div>
            <h3>Dates:</h3>
            <div>
              {trainingInfos
                ? trainingInfos?.dates?.map((date) => {
                    return <div key={date.id}>{showDates(date.startDate.seconds, date.endDate.seconds)}</div>;
                  })
                : ''}
            </div>
          </div>

          <div>
            <h3>Prix:</h3>
            <div>{trainingInfos.price} €</div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
