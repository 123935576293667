/* eslint-disable @typescript-eslint/no-unused-vars */
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import BreadCrumbs from '../../components/Bread-Crumbs/BreadCrumbs';
import TrainingsDisplayer from '../../components/Trainings-Displayer/TrainingsDisplayer';
import './Trainings.css';

export default function Trainings() {
  const breadCrumbs = [
    {
      link: '/',
      name: 'Accueil',
      icon: 'faHome',
    },
    {
      link: '/formations',
      name: 'Formations',
    },
  ];

  useEffect(() => {
    document.title = `IFFCO - Formations`;
  }, []);

  return (
    <>
      <BreadCrumbs breadCrumb={breadCrumbs} />
      <div className="container-banner-formations">
        <FontAwesomeIcon icon={faCalendarAlt} />
        <h2>Prochaines formations</h2>
      </div>
      <div className="subtitle-banner-formations">
        <h4>Renseignez-vous dès maintenant sur les prochaines formations de l’IFFCO</h4>
      </div>
      <div className="main-container-content-formations">
        <div className="sub-container-content-formations">
          <TrainingsDisplayer />
        </div>
      </div>
    </>
  );
}
