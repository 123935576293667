/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface DialProps {
  title: string;
  description: string;
}

interface ActionType {
  openDial: boolean;
  setDial: (a: boolean) => void;
  confirm: () => void;
  dialData: DialProps;
}

export default function AdminDialog({ openDial, setDial, confirm, dialData }: ActionType) {
  const handleClose = () => {
    setDial(false);
  };

  return (
    <div>
      <Dialog
        open={openDial}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialData.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialData.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Non
          </Button>
          <Button color="primary" autoFocus onClick={() => confirm()}>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
