/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import './AdminTrainingView.css';
import {
  faUser,
  faBriefcaseMedical,
  faCalendarAlt,
  faMapMarkerAlt,
  faEuroSign,
  faStopwatch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { Link, useParams } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import imagePriseEnCharge from '../../images/prise-en-charge-image.png';
import { getTraining, updateTrainingState } from '../../firestore/trainings';
import { FetchedTraining, TrainingsWithNameAndProfessionNames } from '../../types/trainings';
import { getTrainerName, getTrainerProfessions } from '../../firestore/trainers';
import { diffDays } from '../Admin-Trainings/AdminTrainings';
import { getAllUserFromTraining, getSingleUser } from '../../firestore/user';
import { User } from '../../types/users';
import { sendEmailStartTraining } from '../../Email/email';
import showDates from '../../utils/dates-utils';

export interface EmailAndName {
  email: string;
  name: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export default function AdminTrainingView() {
  const classes = useStyles();
  const [training, setTraining] = useState<FetchedTraining>();
  const [loading, setLoading] = useState<Boolean>(false);
  const [trainingState, setTrainingState] = useState<string>('');
  const [newTrainingWithName, setNewTrainingWithName] = useState<TrainingsWithNameAndProfessionNames>();
  const { formationId } = useParams<{ formationId?: string }>();
  const [userFromTraining, setUserFromTraining] = useState<Array<User>>([]);

  const fetchTraining = async () => {
    if (formationId) {
      setLoading(true);
      const fetchTrainingStore = await getTraining(formationId);
      if (fetchTrainingStore) {
        setTrainingState(fetchTrainingStore.statut ? fetchTrainingStore.statut : '');
        setTraining(fetchTrainingStore);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchTraining();
  }, []);

  // GET NEW TRAINING ARRAY WITH TRAINERS NAME

  const newTrainingList = async () => {
    setLoading(true);
    if (training) {
      const clonedTraining = JSON.parse(JSON.stringify(training)) as TrainingsWithNameAndProfessionNames;
      const trainingObjectWithName = await Promise.all(clonedTraining.trainers.map((i) => getTrainerName(i)));
      clonedTraining.trainerName = trainingObjectWithName;
      const trainingObjectWithProfession = await Promise.all(
        clonedTraining.trainersProfession.map((i) => getTrainerProfessions(i)),
      );
      clonedTraining.professionName = trainingObjectWithProfession;
      setNewTrainingWithName(clonedTraining);
    }
    setLoading(false);
  };

  useEffect(() => {
    newTrainingList();
  }, [training]);

  const sendStartingTrainingEmail = async () => {
    if (formationId) {
      const getUsersFromTraining = await getAllUserFromTraining(formationId);
      if (getUsersFromTraining) {
        const user = (await Promise.all(getUsersFromTraining.map((userId) => getSingleUser(userId)))) as object[];
        if (training && user) {
          sendEmailStartTraining(training, user);
        }
      }
    }
  };

  const diffBetweenDays = (startDate: any, endingDate: any) => {
    if (startDate === endingDate) {
      return '1 journée';
    }
    return `${diffDays(endingDate, startDate) + 1} jours`;
  };

  const handleTrainingStateChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    if (training) {
      setTrainingState(event.target.value as string);
      const state = event.target.value as string;
      await updateTrainingState(training.trainingId, state);
    }
  };

  return (
    <div className="main-container-admin-formation-view">
      {loading ? (
        <div className="loading-container-trainer-view">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="sub-container-admin-formation-view">
            <div className="active-select-admin-formation-view">
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  value={trainingState}
                  onChange={handleTrainingStateChange}
                  id="demo-simple-select-outlined"
                  label="Age"
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                  <MenuItem value="terminée">Terminée</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="container-left-admin-formation-view">
              <h3>{training?.title}</h3>
              {training?.subtitle ? <h5>{`«${training?.subtitle}»`}</h5> : null}
              {training?.image?.id ? <img src={training?.image?.url} alt="formation" /> : ''}
              <div className="colored-content-admin-formation-view">
                <div className="content-admin-formation-view">
                  <FontAwesomeIcon icon={faUser} />
                  <Link to={`/admin/trainerview/${training?.trainers[0]}`}>
                    <Tooltip
                      title={newTrainingWithName?.trainerName ? newTrainingWithName.trainerName.join(' & ') : ''}
                    >
                      <div className="margin-bottom-admin-formation-view">
                        {newTrainingWithName?.trainerName ? newTrainingWithName.trainerName.join(' & ') : ''}
                      </div>
                    </Tooltip>
                  </Link>
                </div>
              </div>
              <div>
                <div className="content-admin-formation-view">
                  <FontAwesomeIcon icon={faBriefcaseMedical} />
                  <Tooltip
                    title={newTrainingWithName?.professionName ? newTrainingWithName.professionName.join(' & ') : ''}
                  >
                    <div className="margin-bottom-admin-formation-view">
                      {newTrainingWithName?.professionName ? newTrainingWithName.professionName.join(' & ') : ''}
                    </div>
                  </Tooltip>
                </div>
              </div>
              {training?.dates?.map((date) => {
                return (
                  <div key={date.id} className="content-admin-formation-view">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <div className="margin-bottom-admin-formation-view">
                      {training ? showDates(date.startDate.seconds, date.endDate.seconds) : ''}
                    </div>
                  </div>
                );
              })}
              {(training?.dates?.length ? training.dates.length : 0) < 2 ? (
                <div className="content-admin-formation-view">
                  <FontAwesomeIcon icon={faStopwatch} />
                  <div className="margin-bottom-admin-formation-view duration-admin-formation-view">
                    {training
                      ? diffBetweenDays(
                          training.dates?.filter((date) => date.id === 0)[0].startDate.seconds,
                          training.dates?.filter((date) => date.id === 0)[0].endDate.seconds,
                        )
                      : ''}
                  </div>
                </div>
              ) : null}
              <div className="content-admin-formation-view">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <div className="margin-bottom-admin-formation-view">{training?.location}</div>
              </div>
              <div className="content-admin-formation-view">
                <FontAwesomeIcon icon={faEuroSign} />
                <div className="margin-bottom-admin-formation-view">{training?.price}</div>
              </div>
              <div className="food-and-shelter-not-included">(Repas et hébergement non inclus)</div>
              <h4>Possibilité de prise en charge :</h4>
              <img src={imagePriseEnCharge} alt="prise-en-charge" />
            </div>
            <div className="container-right-admin-formation-view">
              <h4>Formateur :</h4>
              <div>{training?.description}</div>
              <h4>Objectif :</h4>
              <div>
                <div dangerouslySetInnerHTML={{ __html: training?.targets || '' }} />
              </div>
              <h4>Public :</h4>
              <div>{training?.peopleConcerned}</div>
              <h4>Télécharger le programme :</h4>
              <div className="pdf-icon-link-admin-formation-view">
                <a href={training?.pdf?.url} target="_blank" rel="noreferrer">
                  <div>
                    <FontAwesomeIcon icon={faFilePdf} />
                    <div>Programme</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="container-button-trigger-email">
              <Button variant="contained" onClick={sendStartingTrainingEmail} color="primary">
                Envoyer e-mail de départ de formation
              </Button>
            </div>
            <div className="container-button-reserve-admin-formation-view">
              <Link to={`/admin/edittraining/${formationId}`}>
                <Button variant="contained" color="primary">
                  Modifier la formation
                </Button>
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
