/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import './AdminAddUser.css';
import { User } from '../../types/users';
import { functions } from '../../config/fire';

export interface InputValues {
  field: string;
  error: boolean;
  value: string;
  type?: string;
  label: string;
  defaultValue?: string;
  required: boolean;
  errorMessage: string;
  disable?: boolean;
}

export default function AdminAddUser() {
  const [loading, setLoading] = useState<Boolean>(false);

  const [validationMessage, setValidationMessage] = useState(false);

  const [inputValues, setInputValues] = useState<Array<InputValues>>([
    {
      field: 'email',
      label: 'E-mail',
      value: '',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },

    {
      field: 'firstName',
      label: 'Nom*',
      value: '',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'lastName',
      label: 'Prénom*',
      value: '',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'profession',
      label: 'Profession',
      value: '',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: false,
    },
    {
      field: 'birthDate',
      label: 'Date de naissance',
      type: 'date',
      defaultValue: '2021-01-01',
      error: false,
      value: '',
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'birthPlace',
      label: 'Lieu de naissance*',
      value: '',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'address',
      label: 'Adresse*',
      value: '',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'compAddress',
      label: "Complément d'adresse",
      value: '',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: false,
    },
    {
      field: 'zipCode',
      label: 'Code Postal*',
      value: '',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'city',
      label: 'Ville*',
      value: '',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'phone',
      label: 'Téléphone*',
      value: '',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
  ]);

  const changeHandler = (e: { target: { name: string; value: string } }) => {
    const index = inputValues.findIndex((a) => a.field === e.target.name);
    inputValues[index].value = e.target.value;
    setInputValues(inputValues);
  };

  const submitValues = async () => {
    const newAllValues = inputValues.map((value) => {
      if (!value.value && value.required === true) {
        value.error = true;
      } else {
        value.error = false;
      }
      return value;
    });

    setInputValues(newAllValues);
    if (newAllValues.some((v) => v.error)) {
      console.warn('error');
    } else {
      const userData: User = {
        email: inputValues[0].value,
        lastName: inputValues[1].value,
        firstName: inputValues[2].value,
        profession: inputValues[3].value || '',
        birthDate: inputValues[4].value,
        birthPlace: inputValues[5].value,
        address: inputValues[6].value,
        compAddress: inputValues[7].value || '',
        zipCode: inputValues[8].value,
        city: inputValues[9].value,
        phone: inputValues[10].value,
      };
      setLoading(true);

      const createUser = functions.httpsCallable('createUser');
      await createUser(userData);

      setValidationMessage(true);
      setLoading(false);
    }
  };

  return (
    <div className="main-container-admin-add-user">
      <div className="sub-container-admin-add-user">
        <div className="main-title-admin-add-user">Ajouter un utilisateur</div>
        {inputValues.map((value) => (
          <div className="container-text-field-admin-add-user" key={value.field}>
            <TextField
              error={value.error}
              defaultValue={value.defaultValue}
              key={value.field}
              name={value.field}
              label={value.label}
              helperText={value.error ? value.errorMessage : ''}
              fullWidth
              variant="outlined"
              type={value.type ? value.type : ''}
              onChange={changeHandler}
              disabled={value.disable}
            />
          </div>
        ))}
        {validationMessage ? (
          <div className="validation-message-admin-add-user">
            <Alert severity="success">L&apos;utilisateur à été crée</Alert>
          </div>
        ) : (
          ''
        )}

        <div className="next-buttons-container-admin-add-user">
          {loading ? (
            <div className="circular-progress-admin-add-user">
              <CircularProgress />
            </div>
          ) : (
            <Button variant="contained" color="primary" onClick={submitValues}>
              Ajouter
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
