/* eslint-disable react/no-unescaped-entities */
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './legalPage.css';

export default function LegalPage() {
  return (
    <div className="main-container-legalpage">
      <div className="container-banner-legalpage">
        <FontAwesomeIcon icon={faBalanceScale} />
        <h2>Mentions Legales</h2>
      </div>
      <div className="subtitle-banner-legalpage">
        <h4>Retrouvez ci-dessous les mentions legales de notre site</h4>
      </div>
      <div className="sub-container-legalpage">
        <div className="content-container-legalpage">
          <div className="title-content-legalpage">
            <h2>éditeur</h2>
          </div>
          <div className="content-legalpage">
            <div>
              Le site est édité par IFFCO
              <br />
              51, Rue de la Méditerranée – 34000 Montpellier
              <br />
              Directeur de la publication : Gaël Arvieux
              <br />
              Email : contact@iffco.fr
            </div>
          </div>
          <div className="title-content-legalpage">
            <h2>Réalisation</h2>
          </div>
          <div className="content-legalpage">
            <div>Rousseau David</div>
          </div>
          <div className="title-content-legalpage">
            <h2>hébergement</h2>
          </div>
          <div className="content-legalpage">
            <div>Site hébergé par OVH</div>
          </div>
          <div className="title-content-legalpage">
            <h2>propriété intellectuelle</h2>
          </div>
          <div className="content-legalpage">
            <div>
              <div>
                Tout le contenu du site, les graphismes, images, textes, vidéos, logos, gifs et icônes ainsi que leur
                mise en forme est soumis au droit de propriété intellectuelle au titre du droit d’auteur sur ces
                éléments ou sur le site en tant qu’œuvre d’auteur ou en tant que base de données (conformément à l’art.
                L.341-1 al.1 du code de la propriété intellectuelle). Les marques, logos ou contenus appartenant à
                d’autres sociétés partenaires ou auteurs ne sont mentionnées qu’à titre signalétique.
              </div>
              <br />
              <div>
                Tous les textes reproduits sur le site sont la propriété de leurs auteurs (personne physique ayant créé
                des éléments présents sur ce site, notamment auteurs des textes, graphistes, maquettistes et concepteurs
                de l’arborescence). L’usage de ceux-ci s’effectue dans les conditions habituelles du droit d’auteur tel
                que défini par le code de la propriété intellectuelle française.
              </div>
              <br />
              <div>
                Toute reproduction, distribution, modification, retransmission ou publication de ces différents éléments
                est strictement interdite sans l’accord par écrit de l’institut IFFCO. Tout manquement aux règles du
                droit d’auteur ou du droit des bases de données est passible de sanctions pénales au titre du délit de
                contrefaçon.
              </div>
            </div>
          </div>
          <div className="title-content-legalpage">
            <h2>confidentialité</h2>
          </div>
          <div className="content-legalpage">
            <div>
              Le Titulaire du site est responsable du traitement des données à caractère personnel (données saisies dans
              les formulaires). Les informations personnelles concernant les visiteurs de notre site, y compris leur
              identité, sont confidentielles. Le responsable du site s’engage sur l’honneur à respecter les conditions
              légales de confidentialité applicables en France et à ne pas divulguer ces informations à des tiers. Le
              site Internet utilise un fichier local de session (également appelé « cookie », non conservé une fois que
              votre fenêtre de navigateur est fermée) pour faciliter la navigation. Ce site utilise Google Analytics, un
              service d’analyse de site internet fourni par Google Inc. (« Google ») uniquement dans le but d’analyser
              le cheminement des utilisateurs. Google Analytics utilise des cookies.
              <br />
              <div>En savoir plus sur notre politique de confidentialité</div>
              <br />
              <div>
                L’exercice du droit d’accès et de rectification prévu par la loi peut être exercé à l’adresse mentionnée
                ci-dessous :
              </div>
              <br />
              <div className="last-child-content-legalpage">IFFCO – 51, Rue de la Méditerranée – 34000 Montpellier</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
