import { faFrown } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React from 'react';
import './ErrorPagePayment.css';
import { Button } from '@material-ui/core';
import ScrollToTop from '../scroll-To-Top/ScrollToTop';

export default function ErrorPagePayment() {
  return (
    <>
      <ScrollToTop />
      <div className="main-container-error-payment">
        <div className="sub-container-error-payment">
          <FontAwesomeIcon icon={faFrown} />
          <h3>404</h3>
          <h4>Erreur</h4>
          <div>
            Nous avons eu une erreur, veuillez contacter notre service client au :<span>04 90 57 80 83</span> vous
            pouvez aussi suivre le liens suivant pour vous rendre sur la page &apos;Nous contacter&apos; pour pouvoir
            nous envoyer un mail nous vous répondrons dans les plus brefs délais
          </div>
          <div className="container-button-error-payment">
            <Link to="/contact">
              <Button
                variant="contained"
                onClick={() => {
                  window.history.go(-1);
                }}
                color="primary"
              >
                Contact
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
