/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import './MenuMobileDisplayer.css';
import MenuItem from '@material-ui/core/MenuItem';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { logOut } from '../../firebase/firebase';

interface TabProp {
  name: string;
  pathName: string;
  isActive: boolean;
  highlights: Array<string>;
  id: string;
}

interface Props {
  tabName: Array<TabProp>;
}

export default function MenuMobileDisplayer({ tabName }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [clickedElement, setClickedElement] = useState<String | null>('Tableau de bord');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (name: string) => {
    setClickedElement(name);
    setAnchorEl(null);
  };

  return (
    <div className="main-container-menu-mobile-displayer">
      <div className="sub-container-menu-mobile-displayer">
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <div className="title-menu-mobile-displayer">{clickedElement}</div>
          <FontAwesomeIcon icon={faChevronDown} />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {tabName.map((tab) => (
            <Link key={tab.name} to={`/myspace/${tab.pathName}`}>
              <MenuItem onClick={() => handleClose(tab.name)}>{tab.name}</MenuItem>
            </Link>
          ))}
          <div className="log-out-button-menu-mobile-drawer" onClick={logOut} role="button">
            Déconnexion
          </div>
        </Menu>
      </div>
    </div>
  );
}
