import React, { useEffect } from 'react';
import './howToReserve.css';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BannerContact from '../../components/Banner-Contact/BannerContact';
import BreadCrumbs from '../../components/Bread-Crumbs/BreadCrumbs';
import AccordionDisplayer from '../../components/Accordion/Accordion';

export default function HowToReserve() {
  const breadCrumbs = [
    {
      link: '/',
      name: 'Accueil',
    },
    {
      link: '/howtoreserve',
      name: 'Comment Réserver ?',
    },
  ];

  useEffect(() => {
    document.title = `IFFCO - Comment réserver`;
  }, []);

  const content = [
    {
      title: '1 - Choississez votre formation',
      mainContent:
        'Choisissez votre prochaine formation en parcourant notre « calendrier des formations » ou dans la liste figurant sur la page d’accueil, pour valider votre sélection, cliquer sur le bouton « En savoir plus ».',
    },
    {
      title: '2 - Sélectionné votre formation',
      mainContent: 'Une fois sur la page de la formation, pour la sélectionner, cliquez sur le bouton « Réserver ».',
    },
    {
      title: '3 - Connectez-vous ou créez un compte client',
      mainContent:
        'Afin de poursuivre votre commande et de la valider, vous devez obligatoirement : Vous connectez à votre espace client ou créez un nouveau compte client en veillant bien à remplir tous les champs demandés.',
    },
    {
      title: '4 - Signez le contrat',
      mainContent:
        "Cette étape est importante pour la validation de votre dossier. Lisez et signez le contrat. En signant le contrat vous certifiez avoir pris connaissance du reglement de l'iffco",
    },
    {
      title: '5 - Réglez votre commande',
      mainContent:
        "Finalisez votre commande et votre inscription en choisissant votre mode de règlement. Suite au paiement, un mail de confirmation d'inscription vous sera envoyé par e-mail",
    },
  ];

  return (
    <div className="main-container-how-to-reserve">
      <BreadCrumbs breadCrumb={breadCrumbs} />
      <div className="container-banner-how-to-reserve">
        <FontAwesomeIcon icon={faStopwatch} />
        <h2>Comment réserver votre formation ?</h2>
      </div>
      <div className="subtitle-banner-how-to-reserve">
        <h4>
          Retrouvez ci-dessous les différentes étapes à réaliser pour mener à bien la réservation de votre formation
        </h4>
      </div>
      <div className="accordion-container-how-to-reserve">
        <AccordionDisplayer content={content} />
      </div>
      <BannerContact />
    </div>
  );
}
