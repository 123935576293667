import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminMenu from '../Admin-Menu/AdminMenu';
import './Admin.css';
import { RootReducer } from '../../store';
import { AppState } from '../../reducers/app';
import ScrollToTop from '../scroll-To-Top/ScrollToTop';

export default function Admin() {
  const { isAdmin } = useSelector<RootReducer, AppState>((state) => state.app);
  return (
    <>
      {isAdmin ? (
        <div className="main-container-admin">
          <ScrollToTop />
          <div className="sub-container-admin">
            <AdminMenu />
            <Outlet />
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
