/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { sendPasswordReset } from '../../firebase/firebase';
import './ForgetPassword.css';

export default function ForgetPassword() {
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [inputError, setInputError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string | null>(null);
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const handleEmailInput = (e: { target: { value: string } }) => {
    const email = e.target.value;
    setUserEmail(email);
  };

  const submitForgetPass = async () => {
    if (userEmail) {
      setInputError(false);
      setHelperText('');
      const returnFromFire = await sendPasswordReset(userEmail);
      if (returnFromFire === undefined) {
        setEmailSent(true);
      }
      if (returnFromFire) {
        if (returnFromFire === 'auth/invalid-email') {
          setInputError(true);
          setHelperText("L'email n'est pas au bon format");
        } else if (returnFromFire === 'auth/user-not-found') {
          setInputError(true);
          setHelperText('Adresse introuvable');
        } else if (returnFromFire === 'auth/too-many-requests') {
          setInputError(true);
          setHelperText('Trop de tentatives veuillez réessayer plus tard');
        }
      }
    } else {
      setInputError(true);
      setHelperText('Veuillez entrer votre adresse e-mail pour continuer');
    }
  };

  return (
    <div className="main-container-forgetpassword">
      <div className="sub-container-forgetpassword">
        {emailSent ? (
          <div className="validation-card-container-forgetpassword">
            <div className="validation-icon-forgetpassword">
              <FontAwesomeIcon icon={faPaperPlane} />
            </div>
            <div className="validation-main-title-forgetpassword">
              <h3>L&apos;email à était envoyé avec succès !</h3>
            </div>
            <h3>
              Une fois que vous aurez changé votre mot de passe veuillez cliquer sur ce lien pour vous rendre sur la
              page de connexion
            </h3>
            <Link to="/myspace">
              <div className="button-redirect-forgetpassword">Page connexion</div>
            </Link>
          </div>
        ) : (
          <div className="card-container-forgetpassword">
            <FontAwesomeIcon icon={faLock} />
            <h3>Mot de passe perdu ?</h3>
            <h3> Veuillez entrer votre e-mail</h3>
            <div className="container-input-forgetpassword">
              <TextField
                id="outlined-basic"
                label="E-mail"
                fullWidth
                variant="outlined"
                helperText={helperText}
                error={inputError}
                onChange={handleEmailInput}
              />
            </div>
            <div className="button-container-forgetpassword" onClick={submitForgetPass} role="button">
              Envoyer le lien
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
