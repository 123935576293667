import React, { useEffect, useState } from 'react';
import './OrderCardPayment.css';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import OrderCardElement from '../Order-Card-Element/OrderCardElement';
import { UserInfo } from '../../reducers/app';
import { RootReducer } from '../../store';
import logoVisa from '../../images/logoVisa.png';
import logoMasterCard from '../../images/logoMastercard.png';
import logoAmex from '../../images/logoAmex.png';
import { getTraining } from '../../firestore/trainings';
import { afterPaymentOrderByCardProcess } from '../../firestore/orders';
import { functions } from '../../config/fire';

interface Props {
  trainingId: string;
  handleNext: () => void;
}

export default function OrderCardPayment({ trainingId, handleNext }: Props) {
  // USERINFOS
  const userInfo = useSelector<RootReducer, UserInfo | null>((state) => state.app.user);

  // TRAINING STATES
  const [trainingPrice, setTrainingPrice] = useState<string>('Payer');
  const [currentTrainingId, setCurrentTrainingId] = useState<string>('');

  // ERROR MESSAGE
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [checkBoxErrorMessage, setCheckBoxErrorMessage] = useState<string>('');

  // LOADING
  const [loading, setLoading] = useState<boolean>(false);

  // STRIPE JS STATE
  const stripe = useStripe();
  const elements = useElements();

  // CHECK BOX
  const [checked, setChecked] = useState(false);

  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  // GET TRAINING PRICE

  const getTrainingPrice = async () => {
    const training = await getTraining(trainingId);
    if (training) {
      setTrainingPrice(training.price);
      setCurrentTrainingId(training.trainingId);
    }
    return '';
  };

  useEffect(() => {
    getTrainingPrice();
  }, [trainingId]);

  // HANDLE SUBMIT FOR PAYMENT

  const handleSubmit = async (event: any) => {
    if (checked) {
      setErrorMessage('');
      setCheckBoxErrorMessage('');
      setLoading(true);
      const secret = functions.httpsCallable('secret');
      const secretResponse = await secret({ trainingId });
      const { client_secret: clientSecret } = secretResponse.data;
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          // @ts-ignore
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${userInfo?.lastName} ${userInfo?.firstName}`,
            email: userInfo?.email,
          },
        },
      });

      if (result.error) {
        setLoading(false);
        if (result.error.code) {
          setErrorMessage(
            result.error.message ? result.error.message : 'Il y a un problème veuillez reesayer plus tard',
          );
        }
      } else if (result.paymentIntent.status === 'succeeded') {
        const paymentType = 'creditCard';
        if (userInfo?.uId && currentTrainingId) {
          await afterPaymentOrderByCardProcess(currentTrainingId, userInfo?.uId, paymentType, trainingPrice);
          setLoading(false);
          handleNext();
        }
      }
    } else {
      setErrorMessage('Veuillez lire et accepter les conditions générales pour poursuivre votre commande.');
      setCheckBoxErrorMessage('Veuillez lire et accepter les conditions générales pour poursuivre votre commande.');
    }
  };

  return (
    <div className="main-container-order-card-payment">
      <div className="container-card-element">
        <OrderCardElement />
      </div>
      <div className="card-logo-card-element">
        <Tooltip title="Visa">
          <img src={logoVisa} alt="visa" />
        </Tooltip>
        <Tooltip title="MasterCard">
          <img src={logoMasterCard} alt="masterCard" />
        </Tooltip>
        <Tooltip title="American Express">
          <img src={logoAmex} alt="amex" />
        </Tooltip>
      </div>
      <div className="content-order-card-element">
        Vos données personnelles seront utilisées pour le traitement de votre commande, vous accompagner au cours de
        votre visite du site web, et pour d’autres raisons décrites dans notre
        <Link target="_blank" to="/privacypolicy">
          politique de confidentialité
        </Link>
        .
      </div>
      <div className="general-terms-container-card-element">
        <Checkbox
          checked={checked}
          onChange={handleCheckBox}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
        <div>
          <div className={checkBoxErrorMessage ? 'error-checkbox-card-element' : ''}>J’ai lu et j’accepte les </div>
          <div className={checkBoxErrorMessage ? 'error-checkbox-card-element' : ''}>
            <Link target="_blank" to="/salesterms">
              conditions générales {checkBoxErrorMessage ? '!' : ''}
            </Link>
          </div>
        </div>
      </div>
      {errorMessage ? (
        <div className="container-alert-card-element">
          <Alert severity="error">{errorMessage}</Alert>
        </div>
      ) : (
        ''
      )}
      <div className="container-submit-cardpayment-button">
        {loading ? (
          <CircularProgress />
        ) : (
          <Button onClick={handleSubmit} variant="contained" color="primary" disabled={!stripe}>
            {trainingPrice ? `Payer ${trainingPrice} €` : 'Payer'}
          </Button>
        )}
      </div>
    </div>
  );
}
