/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UserInfo } from '../../reducers/app';
import { fire } from '../../config/fire';
import { getUserInfo, updateUserEmail } from '../../firestore/user';
import { RootReducer } from '../../store';
import './setUserAccountDetails.css';

interface Inputs {
  error: boolean;
  value: string | undefined;
  label: string;
  errorMessage: string;
  field: string;
}

export default function SetUserAccountDetails() {
  const userInfo = useSelector<RootReducer, UserInfo | null>((state) => state.app.user);
  const user = fire.auth().currentUser;

  const [validationMessageEmail, setValidationMessageEmail] = useState<Boolean>(false);
  const [validationMessagePassword, setValidationMessagePassword] = useState<Boolean>(false);
  const [loadingEmail, setLoadingEmail] = useState<Boolean>(false);
  const [loadingPassword, setLoadingPassword] = useState<Boolean>(false);
  const [emailInput, setEmailInput] = useState<Inputs>({
    label: 'E-mail',
    field: 'email',
    error: false,
    errorMessage: '',
    value: userInfo?.email,
  });
  const [passwordInputs, setPasswordInputs] = useState<Array<Inputs>>([
    {
      label: 'Mot de passe',
      field: 'password',
      error: false,
      errorMessage: '',
      value: '',
    },
    {
      label: 'Confirmez le mot de passe',
      field: 'confirmPassword',
      error: false,
      errorMessage: '',
      value: '',
    },
  ]);

  /*   SUBMIT NEW EMAIL HANDLER  */
  const emailInputHandler = (e: { target: { name: string; value: string } }) => {
    emailInput.value = e.target.value;
    setEmailInput(emailInput);
  };

  const submitNewEmail = async () => {
    setLoadingEmail(true);
    const inputClone = { ...emailInput };
    const newEmail = emailInput.value;
    try {
      if (newEmail && user) {
        await user.updateEmail(newEmail);
        await updateUserEmail(user.uid, newEmail);
        await getUserInfo(user.uid);
        inputClone.error = false;
        inputClone.errorMessage = '';
        setEmailInput(inputClone);
        setLoadingEmail(false);
        setValidationMessageEmail(true);
      } else {
        inputClone.error = true;
        inputClone.errorMessage = 'veuillez renseigner le champ';
        setEmailInput(inputClone);
        setLoadingEmail(false);
      }
    } catch (e) {
      setLoadingEmail(false);
      if (e.code === 'auth/invalid-email') {
        inputClone.error = true;
        inputClone.errorMessage = "L'adresse mail n'est pas au bon format";
        setEmailInput(inputClone);
      } else if (e.code === 'auth/email-already-in-use') {
        inputClone.error = true;
        inputClone.errorMessage = 'Cette adresse e-mail est déjà utilisé';
        setEmailInput(inputClone);
      }
    }
  };

  /*   SUBMIT NEW PASSWORD HANDLER  */
  const passwordInputsHandler = (e: { target: { name: string; value: string } }) => {
    const index = passwordInputs.findIndex((a) => a.field === e.target.name);
    passwordInputs[index].value = e.target.value;
    setPasswordInputs(passwordInputs);
  };

  const submitNewPassword = async () => {
    setLoadingPassword(true);
    const clonePasswordArray = [...passwordInputs];
    const newPassword = passwordInputs[0].value;
    const confirmNewPassword = passwordInputs[1].value;
    try {
      if (user && newPassword && confirmNewPassword) {
        if (newPassword === confirmNewPassword) {
          await user.updatePassword(confirmNewPassword);
          setValidationMessagePassword(true);
          setLoadingPassword(false);
        } else {
          setLoadingPassword(false);
          clonePasswordArray[0].error = true;
          clonePasswordArray[0].errorMessage = 'Les mots de passe ne correspondent pas';
          clonePasswordArray[1].error = true;
          clonePasswordArray[1].errorMessage = 'Les mots de passe ne correspondent pas';
          setPasswordInputs(clonePasswordArray);
        }
        setLoadingPassword(false);
      }
    } catch (error) {
      if (error.code === 'auth/weak-password') {
        clonePasswordArray[0].error = true;
        clonePasswordArray[0].errorMessage = 'Le mot de passe doit faire plus de 6 caractères';
        clonePasswordArray[1].error = true;
        clonePasswordArray[1].errorMessage = 'Le mot de passe doit faire plus de 6 caractères';
        setPasswordInputs(clonePasswordArray);
        setLoadingPassword(false);
      }
    }
  };

  const handlePasswordSubmit = () => {
    setLoadingPassword(true);
    const clonePasswordArray = [...passwordInputs];
    const newLoginValues = passwordInputs.map((value) => {
      if (!value.value) {
        value.error = true;
      } else {
        value.error = false;
      }
      return value;
    });
    setPasswordInputs(newLoginValues);
    if (newLoginValues.some((v) => v.error)) {
      setLoadingPassword(false);
      clonePasswordArray[0].error = true;
      clonePasswordArray[0].errorMessage = 'Veuillez renseigner ce champ';
      clonePasswordArray[1].error = true;
      clonePasswordArray[1].errorMessage = 'Veuillez renseigner ce champ';
      setPasswordInputs(clonePasswordArray);
    } else {
      setLoadingPassword(false);
      submitNewPassword();
    }
  };

  return (
    <div className="main-container-user-account-details">
      <div className="sub-container-user-account-details">
        <div className="container-content-account-details">
          <h3>Modifier votre adresse mail :</h3>
          <div className="content-account-details">
            <TextField
              fullWidth
              defaultValue={emailInput.value}
              id={emailInput.field}
              label={emailInput.label}
              error={emailInput.error}
              helperText={emailInput.errorMessage}
              onChange={emailInputHandler}
              variant="outlined"
            />
          </div>
          {validationMessageEmail ? (
            <div className="content-account-details">
              <Alert severity="success">Votre adresse e-mail à été mis à jour</Alert>
            </div>
          ) : (
            ''
          )}
          <div className="content-account-details">
            {loadingEmail ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" color="primary" onClick={submitNewEmail}>
                Enregistrez l&apos;adressse mail
              </Button>
            )}
          </div>
        </div>
        <div className="container-content-account-details">
          <h3>Modifier votre mot de passe :</h3>
          {passwordInputs.map((value) => (
            <>
              <div className="content-account-details">
                <TextField
                  fullWidth
                  id={value.field}
                  name={value.field}
                  label={value.label}
                  error={value.error}
                  onChange={passwordInputsHandler}
                  helperText={value.errorMessage}
                  variant="outlined"
                  type="password"
                />
              </div>
            </>
          ))}
          {validationMessagePassword ? (
            <div className="content-account-details">
              <Alert severity="success">Votre mot de passe à été mis à jour</Alert>
            </div>
          ) : (
            ''
          )}

          <div className="content-account-details">
            {loadingPassword ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" color="primary" onClick={handlePasswordSubmit}>
                Enregistrez le mot de passe
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
