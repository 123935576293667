/* eslint-disable no-console */
import firebase from 'firebase/app';
import format from 'date-fns/format';
import fr from 'date-fns/locale/fr';
import { sendEmailConfirmationCheckAndSepa, sendEmailConfirmationCreditCard } from '../Email/email';
import { fire, firebaseConfig, functions } from '../config/fire';
import { Order, SubmitedValuesOrder } from '../types/orders';

const db = fire.firestore();

export const checkIfOrderAlreadyExist = async (userId: string, trainingId: string) => {
  const checkIfOrderExist = await db
    .collection('orders')
    .where('userId', '==', userId)
    .where('trainingId', '==', trainingId)
    .get();
  if (checkIfOrderExist.empty) {
    return 'ok';
  }
  return 'error';
};

export const setOrderIsNewToFalse = async (orderId: string) => {
  if (orderId) {
    await db.collection('orders').doc(orderId).update({
      newOrder: false,
    });
  }
};

export const getNewOrder = async () => {
  const checkIfNewOrder = await db.collection('orders').where('newOrder', '==', true).get();
  if (checkIfNewOrder.empty) {
    return 0;
  }
  const numberOfNewOrder = checkIfNewOrder.docs.length;
  return numberOfNewOrder;
};

export const addOrder = async (order: SubmitedValuesOrder) => {
  if (order) {
    try {
      const res = await db.collection('orders').add({
        userId: order.userId,
        trainingId: order.trainingId,
        timeStamp: order.timeStamp,
        contractId: order.contractId,
        paymentState: order.paymentState,
        paymentType: order.paymentType,
      });
      await db.collection('orders').doc(res.id).update({
        orderId: res.id,
      });
    } catch (e) {
      console.warn(e);
    }
  } else {
    console.warn('the order already exist');
  }
};

export const updateOrder = async (orderId: string, paymentState: string) => {
  if (orderId && paymentState) {
    try {
      // CREATE THE REF AND UPLOADING THE STATE OF THE PAYMENT
      const orderRef = db.collection('orders').doc(orderId);
      await orderRef.update({
        paymentState,
      });
      // CHECK IF PAYMENTSTATE = CONFIRME SO WE CAN SEND THE CONFIRMATION MAIL WITH ALL THE INFOS FETCH AT THE TOP
      if (paymentState === 'Confirmé') {
        sendEmailConfirmationCreditCard(orderId);
        const generateInvoice = functions.httpsCallable('generateInvoice');
        await generateInvoice({
          orderId,
          storageBucket: firebaseConfig.storageBucket,
          paymentDate: format(new Date(), 'dd/MM/yyyy', { locale: fr }),
        });
      }
      return 'updated';
    } catch (e) {
      console.warn(e);
    }
  }
  return Promise.resolve();
};

export const getSingleOrder = async (orderId: string) => {
  if (orderId) {
    try {
      const orderRef = db.collection('orders').doc(orderId);
      const doc = await orderRef.get();
      if (doc) {
        const order = doc.data();
        return order;
      }
    } catch (e) {
      console.warn(e);
    }
  }
  return Promise.resolve();
};

export const getAllOrders = async (sort: 'desc' | 'asc' = 'desc') => {
  try {
    const ordersRef = db.collection('orders').orderBy('timeStamp', sort);
    const snapshot = await ordersRef.get();
    const orders: Array<Order> = [];
    snapshot.forEach((doc) => {
      const data = doc.data() as Order;
      orders.push(data);
    });
    return orders;
  } catch (e) {
    console.warn(e);
  }
  return [] as Array<Order>;
};

export const getCustomerOrders = async (userId: string) => {
  if (userId) {
    try {
      const ordersRef = db.collection('orders');
      const queriesUid = await ordersRef.where('userId', '==', userId).get();
      const allCustomerOrders: Array<Order> = [];
      queriesUid.forEach((doc) => {
        allCustomerOrders.push(doc.data() as Order);
      });
      return allCustomerOrders;
    } catch (e) {
      console.warn(e);
    }
  }
  return null;
};

export const getOrder = async (trainingId: string, userId: string) => {
  if (trainingId && userId) {
    try {
      const ordersRef = db.collection('orders');
      const queriesUid = await ordersRef.where('userId', '==', userId).where('trainingId', '==', trainingId).get();
      return queriesUid.docs[0].data() as Order;
    } catch (e) {
      console.warn(e);
    }
  }
  return null;
};

export const afterPaymentOrderByCardProcess = async (
  trainingId: string,
  userId: string,
  paymentType: string,
  price: string,
) => {
  if (trainingId && userId) {
    try {
      const contractRef = db.collection('contracts');
      const queriesUid = await contractRef.where('userId', '==', userId).where('trainingId', '==', trainingId).get();
      if (!queriesUid.empty) {
        const doc = queriesUid.docs[0].data();
        const { contractId } = doc;
        const orderRef = db.collection('orders');
        await orderRef.add({
          paymentType,
          trainingId,
          userId,
          price,
          timeStamp: firebase.firestore.Timestamp.fromDate(new Date()),
          paymentState: 'Confirmé',
          newOrder: true,
          contractId,
        });
        // GET THE ORDER ID AND UPDATE IT IN THE ORDER OBJECT / STORE
        const getOrderId = await orderRef.where('userId', '==', userId).where('trainingId', '==', trainingId).get();
        if (!getOrderId.empty) {
          const orderDocument = getOrderId.docs[0];
          const orderId = orderDocument.id;
          await orderRef.doc(orderId).update({
            orderId,
          });
          // CALL THE EMAIL SEND FUNCTION FOR CONFIRMATION PAYMENT CREDIT CARD
          if (orderId) {
            sendEmailConfirmationCreditCard(orderId);
            const generateInvoice = functions.httpsCallable('generateInvoice');
            await generateInvoice({
              orderId,
              storageBucket: firebaseConfig.storageBucket,
              paymentDate: format(new Date(), 'dd/MM/yyyy', { locale: fr }),
            });
          }
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }
  return '';
};

export const afterPaymentOrderByCheckAndSepaProcess = async (
  trainingId: string,
  userId: string,
  paymentType: string,
  price: string,
) => {
  if (trainingId && userId) {
    try {
      // GET THE CONTRACT ID WITH A QUERY ON CONTRACT
      const ordersRef = db.collection('contracts');
      const queriesUid = await ordersRef.where('userId', '==', userId).where('trainingId', '==', trainingId).get();
      if (queriesUid.empty) {
        console.warn(200);
      } else {
        // CHECK IF ORDER ALREADY EXIST BEFORE CREATING IT
        const checkIfOrderExist = await db
          .collection('orders')
          .where('userId', '==', userId)
          .where('trainingId', '==', trainingId)
          .get();
        // IF ORDER DOES NOT EXIST WE CREATE IT
        if (checkIfOrderExist.empty) {
          queriesUid.forEach(async (doc) => {
            const { contractId } = doc.data();
            const orderRef = db.collection('orders');
            await orderRef.add({
              paymentType,
              trainingId,
              price,
              userId,
              timeStamp: firebase.firestore.Timestamp.fromDate(new Date()),
              paymentState: 'En attente',
              newOrder: true,
              contractId,
            });
            // GET THE ORDER ID AND UPDATE IT IN THE ORDER OBJECT / STORE
            const getOrderId = await orderRef.where('userId', '==', userId).where('trainingId', '==', trainingId).get();
            if (getOrderId.empty) {
              console.warn(200);
            } else {
              const orderDocument = getOrderId.docs[0];
              const orderId = orderDocument.id;
              await orderRef.doc(orderId).update({
                orderId,
              });
              // CALL THE EMAIL SEND FUNCTION FOR CONFIRMATION PRE PAYMENT SEPA
              if (orderId) {
                sendEmailConfirmationCheckAndSepa(orderId);
              }
            }
          });
        } else {
          return 'order_already_exist';
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }
  return '';
};

export const queriesOrdersFromUid = async (trainingId: string, userId: string) => {
  if (trainingId && userId) {
    try {
      const ordersRef = db.collection('orders');
      const queriesUid = await ordersRef.where('userId', '==', userId).where('trainingId', '==', trainingId).get();
      if (queriesUid.empty) {
        console.warn('No matching documents.');
        return false;
      }
      return true;
    } catch (e) {
      console.warn(e);
    }
  }
  return '';
};

export const queriesContractsFromUid = async (trainingId: string, userId: string) => {
  if (trainingId && userId) {
    try {
      const ordersRef = db.collection('contracts');
      const queriesUid = await ordersRef.where('userId', '==', userId).where('trainingId', '==', trainingId).get();
      if (queriesUid.empty) {
        return false;
      }
      return true;
    } catch (e) {
      console.warn(e);
    }
  }
  return '';
};

export const getAnnualOrderAmount = async (startingDate: Date, endingDate: Date) => {
  try {
    const orderRef = db.collection('orders');
    const queries = await orderRef.where('timeStamp', '>', startingDate).where('timeStamp', '<', endingDate).get();
    if (queries.empty) {
      console.warn('no matching docs');
    }
    const matchingOrders: Array<Order> = [];
    queries.forEach((doc) => {
      matchingOrders.push(doc.data() as Order);
    });
    return matchingOrders;
  } catch (e) {
    console.warn(e);
  }
  return null;
};

export const getMonthlyOrderAmount = async (startingDate: Date, endingDate: Date) => {
  try {
    const orderRef = db.collection('orders');
    const queries = await orderRef.where('timeStamp', '>', startingDate).where('timeStamp', '<', endingDate).get();
    if (queries.empty) {
      console.warn('no matching docs');
    }
    const matchingOrders: Array<Order> = [];
    queries.forEach((doc) => {
      matchingOrders.push(doc.data() as Order);
    });
    return matchingOrders;
  } catch (e) {
    console.warn(e);
  }
  return null;
};

export const getAllPendingsPayment = async () => {
  try {
    const orderRef = db.collection('orders');
    const queries = await orderRef.where('paymentState', '==', 'En attente').get();
    const matchingOrders: Array<Order> = [];
    if (!queries.empty) {
      queries.forEach((doc) => {
        matchingOrders.push(doc.data() as Order);
      });
      return matchingOrders;
    }
    return false;
  } catch (e) {
    console.warn(e);
  }
  return null;
};
