/* eslint-disable no-console */
import { fire } from '../config/fire';
import randomId from '../utils/utils';

export interface FileToStorage {
  url: string;
  id: string;
  fileName: string;
}

const storageRef = fire.storage().ref();

/* export const getImages = async (folder: string, imageName: string) => {
  if (folder && imageName) {
    try {
      const image = await storageRef.child(`${folder}/${imageName}`).getDownloadURL();
    } catch (e) {
      console.warn(e);
    }
  }
}; */

export const addContractStorage = async (file: Blob, userName: string, trainingName: string) => {
  const generatedId = randomId();
  if (file) {
    const metadata = {
      customMetadata: {
        userName,
        trainingName,
      },
    };
    try {
      const ref = storageRef.child(`users-contracts/${generatedId}.pdf`);
      await ref.put(file, metadata);
      const url: string = await ref.getDownloadURL();
      return url;
    } catch (e) {
      console.warn(e);
    }
  }
  return '';
};

export const addFileStorage = async (
  id: string,
  file: File,
  path: string,
  fileName: string,
): Promise<FileToStorage> => {
  if (file) {
    const metadata = {
      customMetadata: {
        name: fileName,
      },
    };
    try {
      const ref = storageRef.child(`${path}/${id}`);
      await ref.put(file, metadata);
      const url: string = await ref.getDownloadURL();
      return { url, id, fileName };
    } catch (e) {
      console.warn(e);
    }
  }
  return { url: '', id: '', fileName: '' };
};

export const addVariantFileToStorage = async (file: File, path: string, fileName: string) => {
  if (file) {
    const metadata = {
      customMetadata: {
        name: fileName,
      },
    };
    try {
      const ref = storageRef.child(`${path}/${fileName}`);
      await ref.put(file, metadata);
      const url: string = await ref.getDownloadURL();
      return { url, fileName };
    } catch (e) {
      console.warn(e);
    }
  }
  return { url: '', fileName: '' };
};

export const deleteFileStorage = async (path: string, fileId: string) => {
  if (fileId) {
    try {
      const fileRef = storageRef.child(`${path}/${fileId}`);
      fileRef.delete();
    } catch (e) {
      console.warn(e);
    }
  }
};
