import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import startOfYear from 'date-fns/startOfYear';
import { /*  Button, */ CircularProgress } from '@material-ui/core';
import endOfYear from 'date-fns/endOfYear';
import format from 'date-fns/format';
import fr from 'date-fns/locale/fr';
import { Link } from 'react-router-dom';
import { endOfMonth, startOfMonth } from 'date-fns';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UserInfo } from '../../reducers/app';
import { RootReducer } from '../../store';
import './AdminResume.css';
import { getAllPendingsPayment, getAnnualOrderAmount, getNewOrder } from '../../firestore/orders';
import { getTraining } from '../../firestore/trainings';
import { getSingleUser } from '../../firestore/user';
import { FetchedTraining } from '../../types/trainings';
import { User } from '../../types/users';
import { TrainingOrderAndUser } from '../Admin-Orders/AdminOrders';
import { getFormatedDate } from '../Admin-Trainings/AdminTrainings';
/* import { testEmail } from '../../Email/email'; */

export default function AdminResume() {
  const userInfo = useSelector<RootReducer, UserInfo | null>((state) => state.app.user);

  const [firstUserNameLetter, setFirstUserNameLetter] = useState<string | null>(null);

  const [totalYearOrderAmount, setTotalYearOrderAmount] = useState<number>(0);

  const [totalMonthlyOrderAmount, setTotalMonthlyOrderAmount] = useState<number>(0);

  const [numberOfPendingPayments, setNumberOfPendingPayments] = useState<number>(0);

  const [annualSubs, setAnnualSubs] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);

  const [expanded] = useState<boolean>(true);

  const [numberOfNewOrder, setNumberOfNewOrder] = useState<Number>(0);

  const [firstDayOfCurrentYear, setFirstDayOfCurrentYear] = useState<string>();

  const [firstDayOfCurrentMonth, setFirstDayOfCurrentMonth] = useState<string>();

  const [currentDate, setCurrentDate] = useState<string>();

  const [allOrdersAndUsersAndTrainings, setAllOrdersAndUsersAndTrainings] = useState<Array<TrainingOrderAndUser>>([]);

  const columnName = [
    {
      name: 'Id',
    },
    {
      name: 'Formation',
    },
    {
      name: 'Nom & prénom',
    },
    {
      name: 'Date',
    },
    {
      name: 'Montant',
    },
    {
      name: 'Methode',
    },
  ];

  useEffect(() => {
    document.title = `IFFCO - Admin`;
  }, []);

  const getFirstUserNameLetter = () => {
    if (userInfo?.firstName) {
      const firstLetter = userInfo.firstName.charAt(0).toUpperCase();
      setFirstUserNameLetter(firstLetter);
    }
  };

  const startingCurrentYear = startOfYear(new Date());
  const endingCurrentYear = endOfYear(new Date());
  const startingCurrentMonth = startOfMonth(new Date());
  const endingCurrentMonth = endOfMonth(new Date());

  const getAnnualOrderTotal = async () => {
    setLoading(true);
    const matchOrders = await getAnnualOrderAmount(startingCurrentYear, endingCurrentYear);
    if (matchOrders) {
      let total: number = 0;
      matchOrders?.forEach((order) => {
        const orderPrice = parseInt(order.price, 10);
        total += orderPrice;
      });
      setAnnualSubs(matchOrders?.length);
      setTotalYearOrderAmount(total);
      setLoading(false);
    }
  };

  const getMonthlyOrderTotal = async () => {
    setLoading(true);
    const matchOrders = await getAnnualOrderAmount(startingCurrentMonth, endingCurrentMonth);
    if (matchOrders) {
      let total: number = 0;
      matchOrders?.forEach((order) => {
        const orderPrice = parseInt(order.price, 10);
        total += orderPrice;
      });
      setTotalMonthlyOrderAmount(total);
      setLoading(false);
    }
  };

  const getPendingPayments = async () => {
    setLoading(true);
    const matchOrders = await getAllPendingsPayment();
    if (matchOrders) {
      setNumberOfPendingPayments(matchOrders.length);
      const remapOrdersFromStore = await Promise.all(
        matchOrders.map(async (order) => {
          const training = (await getTraining(order.trainingId)) as unknown as FetchedTraining;
          const user = (await getSingleUser(order.userId)) as User;
          if (training) {
            return {
              id: order.orderId,
              newOrder: order.newOrder,
              date: order.timeStamp.seconds,
              training,
              order,
              user,
              trainingTitle: training.title || '',
              paymentType: order.paymentType,
              paymentState: order.paymentState,
              price: training.price || '',
            };
          }
          return {};
        }),
      );
      setAllOrdersAndUsersAndTrainings(remapOrdersFromStore as unknown as Array<TrainingOrderAndUser>);
    }
  };

  const getNewOrderOnStore = async () => {
    const newOrder = await getNewOrder();
    setNumberOfNewOrder(newOrder);
  };

  const getCurrentYear = () => {
    const formatedDate = format(startingCurrentYear, 'dd MMMM yyyy', { locale: fr });
    setFirstDayOfCurrentYear(formatedDate);
  };

  const getCurrentDate = () => {
    const currentDay = new Date();
    const formatedDate = format(currentDay, 'dd MMMM yyyy', { locale: fr });
    setCurrentDate(formatedDate);
  };

  const getCurrentMonth = () => {
    const formatedDate = format(startingCurrentMonth, 'dd MMMM yyyy', { locale: fr });
    setFirstDayOfCurrentMonth(formatedDate);
  };

  const translatePaymentMethod = (name: string) => {
    if (name === 'sepa') {
      return 'Virement Bancaire';
    }
    if (name === 'checks') {
      return 'Chèque';
    }
    if (name === 'creditCard') {
      return 'Carte bancaire';
    }
    return 'Moyen de paiment';
  };

  useEffect(() => {
    getFirstUserNameLetter();
  }, []);

  useEffect(() => {
    getAnnualOrderTotal();
    getMonthlyOrderTotal();
    getNewOrderOnStore();
    getPendingPayments();
    getCurrentYear();
    getCurrentMonth();
    getCurrentDate();
  }, []);

  /*   const triggerTestEmail = () => {
    if (userInfo?.uId) {
      testEmail(userInfo?.uId);
    }
  }; */

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <div className="main-container-admin-resume">
          <div className="sub-container-admin-resume">
            <div className="header-admin-resume">
              <div>{firstUserNameLetter || '?'}</div>
              <p>Hello {userInfo?.firstName} !</p>
            </div>
            <div className="content-container-admin-resume">
              <div className="info-container-admin-resume">
                <div>montant annuel :</div>
                <div>
                  <span>{totalYearOrderAmount} €</span>
                </div>
                <div>{firstDayOfCurrentYear}</div>
              </div>
              <div className="divider-admin-resume" />
              <div className="info-container-admin-resume">
                <div>nombre d&apos;inscriptions :</div>
                <div>
                  <span>{annualSubs}</span>
                </div>
                <div>{firstDayOfCurrentYear}</div>
              </div>
              <div className="divider-admin-resume" />
              <div className="info-container-admin-resume">
                <div>montant mensuel :</div>
                <div>
                  <span>{totalMonthlyOrderAmount} €</span>
                </div>
                <div>{firstDayOfCurrentMonth}</div>
              </div>
              <div className="divider-admin-resume" />
              <div className="info-container-admin-resume">
                <div>Nouvelle commandes :</div>
                <div>
                  <span>{numberOfNewOrder.toString()}</span>
                </div>
                <div>{currentDate}</div>
              </div>
              <div className="divider-admin-resume" />
              <div className="info-container-admin-resume">
                <div>Paiement en attente :</div>
                <div>
                  <span>{numberOfPendingPayments}</span>
                </div>
                <div>{currentDate}</div>
              </div>
            </div>
          </div>
          <div className="container-accordion-admin-resume">
            <Accordion defaultExpanded={expanded}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Paiements en attente :</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <div>
                    <table>
                      <thead>
                        <tr>
                          {columnName.map((column) => (
                            <th scope="col" key={column.name}>
                              {column.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {allOrdersAndUsersAndTrainings?.map((order) => (
                          <tr key={order.order.orderId}>
                            <td data-label="training" className="order-link-title-user-orders">
                              <Link to={`/admin/orderview/${order.order.orderId}`}>
                                {order.order.orderId.toUpperCase().slice(15)}
                              </Link>
                            </td>
                            <td data-label="training" className="order-link-title-user-orders">
                              <Link to={`/admin/trainingview/${order.training.trainingId}`}>{order.trainingTitle}</Link>
                            </td>
                            <td data-label="lastName" className="order-link-title-user-orders">
                              <Link to={`/admin/userview/${order.user.uId}`}>
                                {order.user.lastName} {order.user.firstName}
                              </Link>
                            </td>
                            <td data-label="Date">{getFormatedDate(order.order.timeStamp.seconds)}</td>
                            <td data-label="Total">{order.order.price} €</td>
                            <td data-label="firstName">{translatePaymentMethod(order.order.paymentType)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )}
    </>
  );
}
