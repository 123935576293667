/* eslint-disable no-param-reassign */
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { fire } from '../../config/fire';
import './Login.css';

export interface InputValues {
  error: boolean;
  value: string;
  label: string;
  errorMessage: string;
  field: string;
  type: string;
}

export default function Login() {
  const [loginValues, setLoginValues] = useState<Array<InputValues>>([
    {
      label: 'E-mail*',
      field: 'email',
      error: false,
      errorMessage: 'Ce champ est obligatoire',
      value: '',
      type: '',
    },
    {
      label: 'Mot de passe*',
      field: 'password',
      error: false,
      errorMessage: 'Ce champ est obligatoire',
      value: '',
      type: 'password',
    },
  ]);

  const inputLoginHandler = (e: { target: { name: string; value: string } }) => {
    const index = loginValues.findIndex((a) => a.field === e.target.name);
    loginValues[index].value = e.target.value;
    setLoginValues(loginValues);
  };

  const logIn = async () => {
    try {
      const email = loginValues[0].value;
      const password = loginValues[1].value;
      await fire.auth().signInWithEmailAndPassword(email, password);
    } catch (error) {
      const errorLogin = error.code;
      if (errorLogin === 'auth/wrong-password') {
        loginValues[0].error = true;
        loginValues[0].errorMessage = '';
        loginValues[1].error = true;
        loginValues[1].errorMessage = "L'adresse mail ou le mot de passe ne correspondent pas";
        setLoginValues(loginValues);
      } else if (errorLogin === 'auth/too-many-requests') {
        loginValues[0].error = true;
        loginValues[0].errorMessage = '';
        loginValues[1].error = true;
        loginValues[1].errorMessage = 'veuillez réessayer ultérieurement';
        setLoginValues(loginValues);
      } else if (errorLogin === 'auth/invalid-email') {
        loginValues[0].error = true;
        loginValues[0].errorMessage = '';
        loginValues[1].error = true;
        loginValues[1].errorMessage = "L'adresse mail ou le mot de passe ne correspondent pas";
        setLoginValues(loginValues);
      } else if (errorLogin === 'auth/user-not-found') {
        loginValues[0].error = true;
        loginValues[0].errorMessage = '';
        loginValues[1].error = true;
        loginValues[1].errorMessage = "L'adresse mail ou le mot de passe ne correspondent pas";
        setLoginValues(loginValues);
      }
    }
  };

  const handleLogin = () => {
    const newLoginValues = loginValues.map((value) => {
      if (!value.value) {
        value.error = true;
      } else {
        value.error = false;
      }
      return value;
    });
    setLoginValues(newLoginValues);
    if (newLoginValues.some((v) => v.error)) {
      console.warn('il y a une erreur');
    } else {
      logIn();
    }
  };

  return (
    <div className="main-container-login">
      <div className="container-login">
        <h3>formulaire de connexion</h3>
        {loginValues.map((loginValue) => (
          <div key={loginValue.label} className="inputs-login">
            <TextField
              className="textfield-login"
              label={loginValue.label}
              error={loginValue.error}
              name={loginValue.field}
              helperText={loginValue.error ? loginValue.errorMessage : ''}
              type={loginValue.type}
              onChange={inputLoginHandler}
              variant="outlined"
            />
          </div>
        ))}
        <div className="container-button-login">
          <Button variant="contained" color="primary" onClick={handleLogin}>
            s&apos;identifier
          </Button>
          <Link to="/forgotpass">
            <div className="forgot-password-login">Mot de passe oublié ?</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
