import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';

export const firebaseConfig = {
  apiKey: 'AIzaSyCxzn1w6b8wLlTvFF-Aa9yRyBOGPvEVmUM',
  authDomain: 'iffca-5f10f.firebaseapp.com',
  projectId: 'iffca-5f10f',
  storageBucket: 'iffca-5f10f.appspot.com',
  messagingSenderId: '775415580151',
  appId: '1:775415580151:web:bf6a6e1a91cbca9b8a5af2',
  measurementId: 'G-ZB0XBEFK6B',
};

export const fire = firebase.initializeApp(firebaseConfig);

// Initialize Firebase
export const { auth } = firebase;

export const functions = firebase.app().functions('europe-west1');

if (window.location.hostname === 'localhost') {
  functions.useEmulator('localhost', 5001);
}
