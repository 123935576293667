import { PhotoCamera } from '@material-ui/icons';
import React, { ChangeEvent, useState, useEffect } from 'react';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import makeAnimated from 'react-select/animated';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './AdminEditTrainer.css';
import CreatableSelect from 'react-select/creatable';
import Alert from '@material-ui/lab/Alert';
import { OptionTypeBase, OptionsType } from 'react-select';
import { Navigate, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import randomId from '../../utils/utils';
import {
  addNewProfession,
  getAllProfessions,
  getTrainer,
  getTrainerProfessions,
  updateTrainer,
} from '../../firestore/trainers';
import { Profession, FetchedTrainer, Trainer } from '../../types/trainers';
import { ImageAndPdf, SelectValue } from '../../types/trainings';
import { addFileStorage, deleteFileStorage } from '../../storage/storage';

interface InputValues {
  error: boolean;
  value: string;
  label: string;
  errorMessage: string;
  field: string;
  helperText?: string;
}

export default function AdminEditTrainer() {
  // PARAMS
  const { trainerId } = useParams<{ trainerId?: string }>();

  // TRAINER
  const [trainerToChange, setTrainerToChange] = useState<FetchedTrainer>();

  // SELECTEUR PROFESSIONS
  const [selectProfessionFromStore, setSelectProfessionFromStore] = useState<Array<SelectValue>>([]);
  const [currentProfessions, setCurrentProfessions] = useState<Array<SelectValue>>([]);

  // WYSIWYG EDITOR
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  // UPLOAD / PROGRESS
  const [uploadPending, setUploadPending] = useState<Boolean>(false);

  // ERROR & VALIDATION MESSAGE
  const [error, setError] = useState<Boolean>(false);
  const [validationMessage, setValidationMessage] = useState<Boolean>(false);

  // IMAGE
  const [imageToUpload, setImageToUpload] = useState<File>();
  const [currentImage, setCurrentImage] = useState<ImageAndPdf>();

  // INPUTS VALUES
  const [inputValues, setInputValues] = useState<Array<InputValues>>([
    {
      label: 'Nom & Prénom',
      field: 'name',
      error: false,
      errorMessage: 'Ce champs est obligatoire',
      value: '',
      helperText: 'Format: Prénom NOM',
    },
  ]);

  // FETCH CURRENT TRAINER TO EDIT

  const fetchTrainer = async () => {
    if (trainerId) {
      const trainer = await getTrainer(trainerId);
      if (trainer) {
        setTrainerToChange(trainer);
        setCurrentImage(trainer.image);
        const targetsFromHTML = convertFromHTML(trainer.description);
        const editorStateParsed = EditorState.createWithContent(
          ContentState.createFromBlockArray(targetsFromHTML.contentBlocks),
        );
        setEditorState(editorStateParsed);
      }
    }
  };

  useEffect(() => {
    fetchTrainer();
  }, [trainerId]);

  // INPUT VALUE HANDLER

  const inputValuesHandler = (e: { target: { name: string; value: string } }) => {
    const index = inputValues.findIndex((a) => a.field === e.target.name);
    inputValues[index].value = e.target.value;
    setInputValues(inputValues);
  };

  // GET ALL AVAILABLE PROFESSION AND SET THEM

  const animatedComponents = makeAnimated();

  const fetchAllProfessions = async () => {
    const allProfessions = await getAllProfessions();
    if (allProfessions) {
      const newListSelectProfession: Array<SelectValue> = allProfessions.map((profession) => ({
        value: profession.id,
        label: profession.name,
      }));
      setSelectProfessionFromStore(newListSelectProfession);
    }
  };

  useEffect(() => {
    fetchAllProfessions();
  }, []);

  // GET CURRENT PROFESSION AND SET IT

  const getSelectCurrentProfession = async () => {
    if (trainerToChange) {
      const professionsFromFirestore = await Promise.all(
        trainerToChange.professionIds.map((profession: string) => getTrainerProfessions(profession)),
      );
      const newSelectProfession: Array<SelectValue> = professionsFromFirestore.map((trainer, i) => ({
        value: trainerToChange.professionIds[i],
        label: trainer,
      }));
      setCurrentProfessions(newSelectProfession);
    }
  };

  useEffect(() => {
    getSelectCurrentProfession();
  }, [trainerToChange]);

  // HANDLE SELECT INPUT PROFESSION

  const handlingSelectProfession = (value: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
    if (value) {
      setCurrentProfessions(value.map((v: OptionTypeBase) => ({ value: v.value, label: v.label })));
    }
    return '';
  };

  // CHECK IF PROFESSION IS NEW

  const checkIfProfessionIsNew = () => {
    const existingIds = selectProfessionFromStore.map((p) => p.value);
    return currentProfessions.filter((p) => existingIds.indexOf(p.value) === -1);
  };

  // SORT PROFESSIONS

  const getProfessionsWithFirebaseData = async () => {
    const allProfessions = await getAllProfessions();
    const noExistingProfessions = currentProfessions.filter((p) => {
      return allProfessions.findIndex((ap) => ap.id === p.value) === -1;
    });
    const existingProfessions = currentProfessions
      .filter((p) => {
        return allProfessions.findIndex((ap) => ap.id === p.value) !== -1;
      })
      .map((p) => ({ name: p.label, id: p.value } as Profession));

    const noExistingProfessionsWithData = noExistingProfessions.map((p) => {
      const a = allProfessions!.find((ap) => ap.name === p.value);
      return a as Profession;
    });

    return [...existingProfessions, ...noExistingProfessionsWithData];
  };

  // IMAGE HANDLING

  const addImageStorage = async () => {
    const generatedId = randomId();
    const path = 'trainers-images';
    if (imageToUpload) {
      const newImageName = imageToUpload.name;
      setUploadPending(true);
      const uploadImage = await addFileStorage(generatedId, imageToUpload, path, newImageName);
      setUploadPending(false);
      return uploadImage;
    }
    return { url: '', imageId: '' };
  };

  // CHECKING IF NEW IMAGE AND DELETE THE OLD ONE

  const checkIfNewImage = async () => {
    const imagePath = 'trainers-images';
    if (imageToUpload) {
      if (trainerToChange) {
        deleteFileStorage(imagePath, trainerToChange?.image?.id);
        setUploadPending(true);
        const image = await addImageStorage();
        setUploadPending(false);
        return image;
      }
    }
    return '';
  };

  const onChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      setImageToUpload(event.target.files[0]);
      setCurrentImage({
        url: URL.createObjectURL(event.target.files[0]),
        id: '',
        fileName: event.target.files[0].name,
      });
    }
  };

  // DESCRIPTION / EDITOR AND CONVERT FUNCTION

  const convertContentToHTML = () => {
    const currentContentAsHTML = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(currentContentAsHTML);
    return markup;
  };

  const handleEditorChange = (state: React.SetStateAction<EditorState>) => {
    setEditorState(state);
    convertContentToHTML();
  };

  // SUBMIT FUNCTION

  const submitAddTrainer = async () => {
    // ADD NEW PROFESSIONS
    const newProfessions = checkIfProfessionIsNew();
    await Promise.all(newProfessions.map((v) => addNewProfession(v.value)));

    // GET DESCRIPTION
    const targets = convertContentToHTML();

    // GET NEW AND OLD PROFESSION
    const professions = await getProfessionsWithFirebaseData();

    // CHECK IF IMAGE TO UPLOAD AND DELETE THE OLD ONE
    const image: ImageAndPdf = (await checkIfNewImage()) as ImageAndPdf;

    // CREATE OBJECT TO SUBMIT
    if (trainerToChange) {
      const trainer: Trainer = {
        name: inputValues[0].value || trainerToChange.name,
        professionIds: professions.map((p) => p.id),
        image: image || currentImage,
        description: targets || trainerToChange.description,
      };
      if (trainer) {
        await updateTrainer(trainerToChange.id, trainer);
        setValidationMessage(true);
      } else {
        setError(true);
      }
    }
  };

  // RETURN

  return (
    <div className="main-container-edit-trainer">
      <div className="sub-container-edit-trainer">
        {!trainerToChange ? (
          <div className="container-circular-progress-edit-trainer">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="info-edit-trainer">
              <h4>Informations :</h4>
              {inputValues.map((inputValue) => (
                <div key={inputValue.label} className="inputs-edit-trainer">
                  <TextField
                    className="textfield-login"
                    label={inputValue.label}
                    error={inputValue.error}
                    name={inputValue.field}
                    helperText={inputValue.helperText}
                    defaultValue={trainerToChange?.name}
                    onChange={inputValuesHandler}
                    variant="outlined"
                  />
                </div>
              ))}
            </div>
            <div className="select-profession-edit-trainer">
              <CreatableSelect
                placeholder="Professions"
                isMulti
                value={currentProfessions}
                options={selectProfessionFromStore}
                components={animatedComponents}
                onChange={handlingSelectProfession}
              />
            </div>
            <div className="add-image-edit-trainer">
              <h4>Photo :</h4>
              {currentImage ? (
                <>
                  <label htmlFor="icon-button-file">
                    <input accept="image/*" id="icon-button-file" type="file" onChange={onChangeImage} />
                    <img src={currentImage.url} alt="trainers" />
                  </label>
                </>
              ) : (
                <div className="drag-drop-edit-trainer">
                  <label htmlFor="icon-button-file">
                    <input accept="image/*" id="icon-button-file" type="file" onChange={onChangeImage} />
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </div>
              )}
            </div>
            <div className="add-description-edit-trainer">
              <h4>description :</h4>
              <Editor
                editorState={editorState as any}
                onEditorStateChange={handleEditorChange as any}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
            {error ? <Alert severity="error">Veuillez entrer tout les champs avant de continuer</Alert> : null}
            <div className="submit-button-container-edit-trainer">
              {uploadPending ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" color="primary" onClick={submitAddTrainer}>
                  Soumettre
                </Button>
              )}
            </div>
            {validationMessage ? <Navigate to={`/admin/trainerview/${trainerToChange.id}`} /> : ''}
          </>
        )}
      </div>
    </div>
  );
}
