/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import './TrainingView.css';
import {
  faUser,
  faBriefcaseMedical,
  faCalendarAlt,
  faMapMarkerAlt,
  faEuroSign,
  faStopwatch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { Link, useParams } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import imagePriseEnCharge from '../../images/prise-en-charge-image.png';
import { getTraining } from '../../firestore/trainings';
import { FetchedTraining, TrainingsWithNameAndProfessionNames } from '../../types/trainings';
import { getTrainerName, getTrainerProfessions } from '../../firestore/trainers';
import { diffDays } from '../Admin-Trainings/AdminTrainings';
import showDates from '../../utils/dates-utils';

export default function FormationView() {
  const [training, setTraining] = useState<FetchedTraining>();
  const [loading, setLoading] = useState<Boolean>(false);
  const [newTrainingWithName, setNewTrainingWithName] = useState<TrainingsWithNameAndProfessionNames>();
  const { formationId } = useParams<{ formationId?: string }>();

  const fetchTraining = async () => {
    if (formationId) {
      setLoading(true);
      const fetchTrainingStore = await getTraining(formationId);
      if (fetchTrainingStore) {
        setTraining(fetchTrainingStore);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchTraining();
  }, []);

  // GET NEW TRAINING ARRAY WITH TRAINERS NAME

  const newTrainingList = async () => {
    setLoading(true);
    if (training) {
      const clonedTraining = JSON.parse(JSON.stringify(training)) as TrainingsWithNameAndProfessionNames;
      const trainingObjectWithName = await Promise.all(clonedTraining.trainers.map((i) => getTrainerName(i)));
      clonedTraining.trainerName = trainingObjectWithName;
      const trainingObjectWithProfession = await Promise.all(
        clonedTraining.trainersProfession.map((i) => getTrainerProfessions(i)),
      );
      clonedTraining.professionName = trainingObjectWithProfession;
      setNewTrainingWithName(clonedTraining);
    }
    setLoading(false);
  };

  useEffect(() => {
    newTrainingList();
  }, [training]);

  return (
    <div className="main-container-training-view">
      {loading ? (
        <div className="loading-container-trainer-view">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="sub-container-training-view">
            <div className="container-left-training-view">
              <h3>{training?.title}</h3>
              {training?.subtitle ? <h5>{`«${training?.subtitle}»`}</h5> : null}
              <img src={training?.image?.url} alt="formation" />
              <div className="colored-content-training-view">
                <div className="container-trainers-name-training-view">
                  <div className="container-trainer-infos-training-view">
                    <FontAwesomeIcon icon={faUser} />

                    <Link to={`/trainerview/${training?.trainers.map((e) => e)}`}>
                      <Tooltip
                        title={newTrainingWithName?.trainerName ? newTrainingWithName.trainerName.join(' & ') : ''}
                      >
                        <div className="margin-bottom-training-view">
                          {newTrainingWithName?.trainerName ? newTrainingWithName.trainerName.join(' & ') : ''}
                        </div>
                      </Tooltip>
                    </Link>
                  </div>
                </div>
              </div>
              <div>
                <div className="container-trainers-prof-training-view">
                  <div className="container-trainer-infos-training-view">
                    <FontAwesomeIcon icon={faBriefcaseMedical} />
                    <Tooltip
                      title={newTrainingWithName?.professionName ? newTrainingWithName.professionName.join(' & ') : ''}
                    >
                      <div className="margin-bottom-training-view">
                        {newTrainingWithName?.professionName ? newTrainingWithName.professionName.join(' & ') : ''}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {training?.dates?.map((date) => {
                return (
                  <div key={date.id} className="container-trainer-infos-training-view">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <div className="margin-bottom-training-view">
                      {training ? showDates(date.startDate.seconds, date.endDate.seconds) : ''}
                    </div>
                  </div>
                );
              })}
              <div className="container-trainer-infos-training-view">
                <div>
                  <FontAwesomeIcon icon={faStopwatch} />
                  {training?.dates
                    ? ` ${training.dates.reduce((acc, item) => {
                        return diffDays(item.endDate.seconds, item.startDate.seconds) + 1 + acc;
                      }, 0)} jours`
                    : ''}
                </div>
              </div>
              <div className="container-trainer-infos-training-view">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <div className="margin-bottom-training-view">{training?.location}</div>
              </div>
              <div className="container-trainer-infos-training-view">
                <FontAwesomeIcon icon={faEuroSign} />
                <div className="margin-bottom-training-view">{training?.price}</div>
              </div>
              <div className="food-and-shelter-not-included">(Repas et hébergement non inclus)</div>
              <h4>Possibilité de prise en charge :</h4>
              <img src={imagePriseEnCharge} alt="prise-en-charge" />
            </div>
            <div className="container-right-training-view">
              <h4>Formateur :</h4>
              <div>{training?.description}</div>
              <h4>Objectif :</h4>
              <div>
                <div dangerouslySetInnerHTML={{ __html: training?.targets || '' }} />
              </div>
              <h4>Public :</h4>
              <div>{training?.peopleConcerned}</div>
              <h4>Télécharger le programme :</h4>
              <div className="pdf-icon-link-training-view">
                <a href={training?.pdf?.url} target="_blank" rel="noreferrer">
                  <div>
                    <FontAwesomeIcon icon={faFilePdf} />
                    <div>Programme</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="container-button-reserve-training-view">
              <Link to={`/ordersteps/${formationId}`}>
                <Button variant="contained" color="primary">
                  Reserver
                </Button>
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
