import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './salesTerms.css';

export default function SalesTerms() {
  return (
    <div className="main-container-salesterms">
      <div className="container-banner-formations">
        <FontAwesomeIcon icon={faHandshake} />
        <h2>Conditions de ventes</h2>
      </div>
      <div className="subtitle-banner-formations">
        <h4>Retrouvez ci-dessous nos conditions de ventes</h4>
      </div>
      <div className="sub-container-salesterms">
        <div className="content-container-salesterms">
          <div className="content-first-child-salesterms">
            Ces conditions générales s’appliquent à toutes les formations de l’IFFCO, et régissent les relations entre
            les stagiaires et l’organisme de formation.
          </div>
          <div className="title-content-salesterms">
            <h2>I. MODALITES D’INSCRIPTION </h2>
          </div>
          <div className="content-salesterms">
            <div>
              <div>
                À réception de la demande d’inscription du stagiaire, le secrétariat lui enverra un dossier à remplir
                pour confirmer son inscription.
              </div>
              <br />
              <div>
                Celle-ci sera prise en compte et validée par l’IFFCO à réception du dossier complet, qui doit lui
                parvenir 8 semaines au moins avant la date de la formation projetée.
              </div>
              <br />
              <div>Il doit contenir :</div>
              <ul>
                <li>
                  Le contrat de formation professionnelle continue en ostéopathie (contrat IFFCO), ainsi que les
                  annexes, le tout paraphé et signé
                </li>
                <li>L’attestation d’assurance RCP du participant</li>
                <li>L’autorisation du droit à l’image</li>
                <li>Le règlement intérieur signé</li>
                <li>Le chèque d’acompte</li>
                <li>Le chèque de solde</li>
                <li>Un récépissé de dépôt du dossier sera envoyé au stagiaire dès réception des documents.</li>
              </ul>
            </div>
          </div>
          <div className="title-content-salesterms">
            <h2>II. MODALITES DE REGLEMENT</h2>
          </div>
          <div className="content-salesterms">
            <div>
              <div>
                L’organisme de formation accepte le règlement par chèque bancaire libellé à l’ordre de l’IFFCO, par
                virement et par carte bancaire sur le site sécurisé www.iffco.fr via la plateforme de paiement en ligne
                Stripe. Sans accusé de réception de paiement validé, la demande d’inscription ne pourra pas être prise
                en compte.
              </div>
            </div>
          </div>
          <div className="title-content-salesterms">
            <h2>III. CONDITIONS D’ADMISSION DES STAGIAIRES, PREREQUIS, RESPONSABILITE CIVILE PROFESIONNELLE</h2>
          </div>
          <div className="content-salesterms">
            <div>
              <div>
                L’IFFCO se réserve le droit de refuser toute personne qui ne présente pas les prérequis nécessaires et
                définis pour les formations.
              </div>
              <br />
              <div>
                Pour les stages à caractère pratique, ne peuvent participer que les stagiaires couverts par une
                assurance responsabilité civile professionnelle (RCP). Une copie de cette attestation doit être fournie
                à l’IFFCO lors de l’inscription.
              </div>
              <br />
              <div>
                Tout stagiaire présentant un problème de santé doit le signaler au responsable de stage et à
                l’enseignant en début de séminaire. Par défaut, en l’absence de signalement, les stagiaires sont
                considérés comme aptes à suivre l’ensemble des ateliers pratiques proposés dans la formation.
              </div>
              <br />
              <div>
                En aucun cas, l’IFFCO ou un membre de son équipe ne pourra être tenu responsable en cas de lésions
                survenues à l’occasion d’une manipulation entre deux stagiaires, ou en cas de dégradation de l’état de
                santé du stagiaire survenu pendant ou après le stage.
              </div>
              <br />
              <div>
                Chaque stagiaire engage sa propre responsabilité s’il pratique des techniques qui n’ont pas été
                démontrées durant le stage.
              </div>
            </div>
          </div>
          <div className="title-content-salesterms">
            <h2>IV. PRIX D’UN STAGE – DOSSIER FIFPL</h2>
          </div>
          <div className="content-salesterms">
            <div>
              <div>
                Le prix du stage s’entend toutes taxes comprises (TTC), l’IFFCO n’étant pas soumis à la TVA. Ce prix
                inclut l’organisation et l’animation du séminaire avec le matériel adapté, ainsi que les pauses café. Ce
                prix n’inclut pas le coût des repas, ni celui de l’hébergement et des transports. À titre indicatif, le
                stagiaire peut demander une prise en charge par le FIFPL, sous certaines conditions et dans tous les cas
                (sous réserve de l’évolution des textes applicables en la matière) du Crédit d’Impôt Formation Continue
                du chef d’entreprise.
              </div>
            </div>
          </div>
          <div className="title-content-salesterms">
            <h2>V. CONDITIONS D’ANNULATION</h2>
          </div>
          <div className="content-salesterms">
            <div>
              <div>
                Pour être prise en compte, toute demande d’annulation d’inscription devra être renvoyée à l’IFFCO par
                lettre recommandée avec accusé de réception.
              </div>
              <br />
              <div>Le remboursement éventuel des frais d’inscription se fera aux conditions suivantes :</div>
              <br />
              <ul>
                <li>
                  Pour un courrier RAR reçu plus de 8 semaines avant le début de la formation : remboursement de 100%
                  des sommes acquittées,
                </li>
                <li>
                  Pour un courrier RAR reçu entre 4 et 7 semaines avant le début de la formation : remboursement de 50%
                  des sommes acquittées,
                </li>
                <li>
                  Pour un courrier RAR reçu mois de 4 semaines avant le début de la formation : aucun remboursement En
                  cas d’abandon par le participant en cours de formation, aucun remboursement ne sera effectué.
                </li>
              </ul>
              <br />
              <div>
                L’IFFCO se réserve le droit de reporter à sa convenance les actions de formation si des circonstances
                indépendantes de sa volonté l’y obligent (tels que des grèves de transport, maladie impromptue…). Dans
                l’hypothèse où l’IFFCO décide d’annuler une action de formation, il s’engage à rembourser les frais
                d’inscription déjà encaissés, sans autre droit à dédommagement que ce soit. En cas d’annulation à l’une
                des formations appartenant à un pack promotionnel (formations groupées), les procédures d’annulation
                sont identiques et les tarifs recalculés sur la base du prix de vente de la formation hors déduction.
              </div>
            </div>
          </div>
          <div className="title-content-salesterms">
            <h2>VI. ATTESTATION DE STAGE</h2>
          </div>
          <div className="content-salesterms">
            <div>
              <div>
                Conformément aux lois régissant la formation continue professionnelles des ostéopathes, une attestation
                de présence sera remise aux participants en fin de stage.
              </div>
            </div>
          </div>
          <div className="title-content-salesterms">
            <h2>VII. CONTENU PEDAGOGIQUE – RESPONSABILITES</h2>
          </div>
          <div className="content-salesterms">
            <div>
              <div>
                Le contenu et les moyens pédagogiques prévus peuvent être modifiés à l’initiative du responsable de la
                formation, sans que les participants puissent en tenir rigueur à l’IFFCO et réclamer un quelconque
                dédommagement. Le participant à la formation est tenu de respecter le Règlement Intérieur qui a cours
                dans les locaux du lieu de la formation.
              </div>
              <br />
              <div>
                IFFCO
                <br />
                51 Rue de la Méditerranée
                <br />
                34000 Montpellier
                <br />
                Tél : 04 90 57 80 83
                <br />
                Contact : contact@iffco.fr
                <br />
                Site : www.iffco.fr
              </div>
            </div>
          </div>
          <div className="title-content-salesterms">
            <h2>
              VIII. ENREGISTREMENT VIDEO DES STAGES – PRISES DE PHOTOGRAPHIES – DROITS D’AUTEUR SUR LES SUPPORTS DE
              COURS
            </h2>
          </div>
          <div className="content-salesterms">
            <div>
              <div className="last-child-content-salesterms">
                <div>
                  L’IFFCO se réserve le droit exclusif d’enregistrer sous forme vidéo ses formations. Il en est de même
                  pour la prise de photographies.
                </div>
                <br />
                <div>
                  À ce titre, chaque stagiaire recevra en même temps que sa convention de formation (contrat IFFCO) une
                  demande d’autorisation permettant à l’IFFCO de le filmer dans le cadre du stage auquel il participe et
                  de le prendre en photographie (contrat IFFCO).
                </div>
                <br />
                <div>
                  Cette autorisation devra être retournée à l’IFFCO avec les autres pièces du dossier d’inscription.
                  L’utilisation de téléphone portable, de caméscope ou tout autre moyen d’enregistrer les vidéos ou de
                  prendre des photographies pendant le stage n’est pas autorisé.
                </div>
                <br />
                <div>
                  Les vidéos et photographies des stages seront remises en fin de formation au stagiaire sur demande de
                  sa part.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
