/* eslint-disable import/prefer-default-export */

import { functions } from '../config/fire';
import { FetchedTraining } from '../types/trainings';

// WELCOMING EMAIL NEW USERS
export const sendWelcomEmail = async (userId: string) => {
  const sendWelcomeEmail = functions.httpsCallable('emailSigninConfirmation');
  await sendWelcomeEmail({ userId });
};

// EMAIL CREDIT CARD CONFIRMATION
export const sendEmailConfirmationCreditCard = async (orderId: string) => {
  const sendConfirmationCreditCard = functions.httpsCallable('emailConfirmationCreditCard');
  await sendConfirmationCreditCard({ orderId });
};

// EMAIL CHECK SEPA PRE-CONFIRMATION
export const sendEmailConfirmationCheckAndSepa = async (orderId: string) => {
  const sendConfirmationSepa = functions.httpsCallable('emailPrePaymentCheckAndSepa');
  await sendConfirmationSepa({ orderId });
};

export const sendEmailStartTraining = async (training: FetchedTraining, usersFromTraining: object[]) => {
  const sendConfirmationSepa = functions.httpsCallable('emailStartTraining');
  await sendConfirmationSepa({ training, usersFromTraining });
};

export const userContactEmail = async (content: Object) => {
  const sendContactEmail = functions.httpsCallable('emailUserContact');
  await sendContactEmail({ content });
};

export const testEmail = async (userId: string) => {
  const testData =
    '<ul><li>Aborder les points clés de la prise en charge ostéopathique de la sphère uro génitale chez la femme</li><li>Comprendre la femme de la puberté à la ménopause</li><li>Lister les troubles périnéaux et les algies pelvi périnéales</li><li>Aborder de façon clinique et pratique cette sphère.</li></ul>';
  const sendTestEmail = functions.httpsCallable('test');
  await sendTestEmail({ testData, userId });
};
