/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import './AdminUsers.css';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getAllUsers } from '../../firestore/user';
import { User } from '../../types/users';

/* INTERFACE */

interface Data {
  email: string;
  firstName: string;
  lastName: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

/* STYLE */

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.info.dark,
            backgroundColor: lighten(theme.palette.info.main, 0.85),
          }
        : {
            color: theme.palette.info.main,
            backgroundColor: theme.palette.info.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export default function AdminUsers() {
  // SELECT
  const [selected, setSelected] = useState<Array<any>>([]);

  // PAGE & ROW PER PAGE
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // USERS
  const [users, setUsers] = useState<Array<User>>([]);

  // LOADING
  const [loading, setLoading] = useState<Boolean>(false);

  // STYLE
  const classes = useStyles();
  const classesToolBar = useToolbarStyles();

  // HEAD CELLS & DIAL DATA
  const headCells: HeadCell[] = [
    { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
    { id: 'lastName', numeric: false, disablePadding: true, label: 'Nom' },
    { id: 'firstName', numeric: false, disablePadding: true, label: 'Prénom' },
  ];

  // GET ALL TRAINERS FROM STORE

  const setUsersFromFirebase = async () => {
    setLoading(true);
    const usersFromFirebase = await getAllUsers();
    if (usersFromFirebase) {
      setUsers(usersFromFirebase);
      setLoading(false);
    }
  };

  useEffect(() => {
    setUsersFromFirebase();
  }, []);

  // HANDLE SELECTALLCLICK

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // HANDLE SELECT CLICK

  /* const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };
 */
  // HANDLE CHANGE PAGE

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // HANDLE CHANGE OF ROWS PER PAGE

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  return (
    <>
      {loading ? (
        <div className="loading-container-admin-users">
          <CircularProgress />
        </div>
      ) : (
        <div className="main-container-admin-users">
          <div className="sub-container-admin-users">
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <Toolbar
                  className={clsx(classes.root, {
                    [classesToolBar.highlight]: selected.length > 0,
                  })}
                >
                  {selected.length > 0 ? (
                    <Typography className={classesToolBar.title} color="inherit" variant="subtitle1" component="div">
                      {`${selected.length} sélection(s)`}
                    </Typography>
                  ) : (
                    <Typography className={classesToolBar.title} variant="h6" id="tableTitle" component="div">
                      Utilisateurs
                    </Typography>
                  )}
                  {selected.length > 0 ? (
                    <Tooltip title="Supprimer">
                      <DeleteIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Liste de filtres">
                      <IconButton aria-label="filter list">
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Toolbar>
                <TableContainer>
                  <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            indeterminate={selected.length > 0 && selected.length < users.length}
                            checked={users.length > 0 && selected.length === users.length}
                            onChange={handleSelectAllClick}
                            disabled
                            inputProps={{ 'aria-label': 'disabled checkbox' }}
                            color="primary"
                          />
                        </TableCell>
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                          >
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        const isItemSelected = isSelected(row);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.uId}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                disabled
                                checked={isItemSelected}
                                inputProps={{ 'aria-label': 'disabled checkbox' }}
                                color="primary"
                              />
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              <Link to={`/admin/userview/${row.uId}`}>{row.email}</Link>
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              <Link to={`/admin/userview/${row.uId}`}>{row.lastName}</Link>
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              <Link to={`/admin/userview/${row.uId}`}>{row.firstName}</Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={users.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
