import React from 'react';
import Login from '../login/Login';
import SignIn from '../sign-in/SignIn';
import './OrderLoginRegister.css';

export default function OrderLoginRegister() {
  return (
    <div className="main-container-order-login-register">
      <div className="sub-container-order-login-register">
        <Login />
        <SignIn />
      </div>
    </div>
  );
}
