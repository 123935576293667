/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import image1 from '../../images/iffco-1.jpg';
import image2 from '../../images/iffco-2.jpg';
import image3 from '../../images/iffco-3.jpg';
import image4 from '../../images/iffco-4.jpg';
import image5 from '../../images/iffco-5.jpg';
import image6 from '../../images/iffco-6.jpg';
import image7 from '../../images/iffco-7.jpg';
import image8 from '../../images/iffco-8.jpg';
import image9 from '../../images/iffco-9.jpg';
import image10 from '../../images/iffco-10.jpg';
import image11 from '../../images/iffco-11.jpg';
import image12 from '../../images/iffco-12.jpg';
import image13 from '../../images/iffco-13.jpg';
import image14 from '../../images/iffco-14.jpg';
import image15 from '../../images/iffco-15.jpg';
import image16 from '../../images/iffco-16.jpg';
import './PictureDisplayer.css';

export default function PictureDisplayer() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    { src: image1, key: 1 },
    { src: image2, key: 2 },
    { src: image3, key: 3 },
    { src: image4, key: 4 },
    { src: image5, key: 5 },
    { src: image6, key: 6 },
    { src: image7, key: 7 },
    { src: image8, key: 8 },
    { src: image9, key: 9 },
    { src: image10, key: 10 },
    { src: image11, key: 11 },
    { src: image12, key: 12 },
    { src: image13, key: 13 },
    { src: image14, key: 14 },
    { src: image15, key: 15 },
    { src: image16, key: 16 },
  ];

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div className="main-container-pic-displayer-footer">
      <div className="first-line-pic-container-footer">
        {images.map((image, index) => {
          return (
            <div key={image.key} className="images-first-line-container-pic-displayer">
              <img
                src={image.src}
                onClick={() => openImageViewer(index)}
                width="85px"
                height="85px"
                key={image.src}
                style={{ margin: '2px' }}
                alt=""
              />
            </div>
          );
        })}
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={images.map((i) => i.src)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
        />
      )}
    </div>
  );
}
