import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { TrainingsWithNameAndProfessionNames } from '../../types/trainings';
import showDates from '../../utils/dates-utils';
import { diffDays } from '../Admin-Trainings/AdminTrainings';
import './OrderCreditCardConfirmation.css';

interface Props {
  newTrainingWithName: TrainingsWithNameAndProfessionNames | null;
}

export default function OrderCreditCardConfirmation({ newTrainingWithName }: Props) {
  return (
    <div className="main-container-credit-card-confirmation">
      <div className="sub-container-credit-card-confirmation">
        <div>
          <FontAwesomeIcon icon={faCheckCircle} />
        </div>
        <div className="main-content-sepa-confirmation">
          <div>
            <span>Confirmation de l&apos;inscription à la formation</span> : {newTrainingWithName?.title}
          </div>
          <div>
            <span>Dispensé par le(s) formateur(s)</span>
            {` :
            ${newTrainingWithName?.trainerName ? newTrainingWithName.trainerName.join(' & ') : ''}`}
          </div>
          <div>
            <span>D&apos;une durée de</span> :&nbsp;
            {newTrainingWithName
              ? newTrainingWithName?.dates?.reduce((acc, item) => {
                  return diffDays(item.endDate.seconds, item.startDate.seconds) + acc;
                }, 0) || 0 + 1
              : ''}
          </div>
          <div>
            <span>Date(s) </span>
            {`: 
            ${
              newTrainingWithName
                ? newTrainingWithName.dates?.map((date) => {
                    return showDates(date.startDate.seconds, date.endDate.seconds);
                  })
                : ''
            }`}
          </div>
        </div>
        <div className="main-content-credit-card-confirmation link-credit-card-confirmation">
          <div>Nous vous remercions pour votre paiement.</div>
          <div>
            Un mail devrait vous parvenir vous confirmation l’inscription
            <div>
              Vous pourrez accéder au suivi de celle-ci ainsi que consulter votre facture dans votre
              <Link target="_blank" to="/myspace/orders">
                espace client.
              </Link>
            </div>
          </div>
        </div>
        <div className="main-content-credit-card-confirmation">
          <div>Veuillez recevoir nos sincères salutations,</div>
          <div>L’équipe Iffco</div>
        </div>
      </div>
    </div>
  );
}
