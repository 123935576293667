import { faFrown } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React from 'react';
import './PageNotFound.css';
import { Button } from '@material-ui/core';

export default function PageNotFound() {
  return (
    <div className="main-container-page-not-found">
      <div className="sub-container-page-not-found">
        <FontAwesomeIcon icon={faFrown} />
        <h3>404</h3>
        <h4>Page not found</h4>
        <div>
          Oups la page que vous demandez n&apos;existe pas ou il y a eu une erreur vous pouvez retourner en arrière en
          suivant cliquant sur ce lien :
        </div>
        <div className="container-button-page-not-found">
          <Link to="/">
            <Button
              variant="contained"
              onClick={() => {
                window.history.go(-1);
              }}
              color="primary"
            >
              retour
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
