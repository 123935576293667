/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { fire, auth } from '../config/fire';
import { setUserInfo } from '../reducers/app';
import { store } from '../store';

export const logOut = async () => {
  try {
    await fire.auth().signOut();
    store.dispatch(setUserInfo(null));
  } catch (error: unknown) {
    console.warn();
  }
};

export async function getUserCredentials(email: string | undefined | null, password: string) {
  const user = fire.auth().currentUser;
  return new Promise((resolve, reject) => {
    if (email && password && user) {
      const credentials = auth.EmailAuthProvider.credential(email, password);
      user
        .reauthenticateWithCredential(credentials)
        .then((userCreds) => resolve(userCreds))
        .catch((reason) => reject(reason));
    }
  });
}

export const sendPasswordReset = async (email: string) => {
  if (email) {
    try {
      await fire.auth().sendPasswordResetEmail(email);
    } catch (e) {
      const errorCode = e.code;
      return errorCode;
    }
  }
  return Promise.resolve();
};
