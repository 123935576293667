import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOrder } from '../../firestore/orders';
import { getTrainerName, getTrainerProfessions } from '../../firestore/trainers';
import { getTraining } from '../../firestore/trainings';
import { UserInfo } from '../../reducers/app';
import { RootReducer } from '../../store';
import { Order } from '../../types/orders';
import { FetchedTraining, TrainingsWithNameAndProfessionNames } from '../../types/trainings';
import OrderSepaConfirmation from '../0rder-Sepa-Confirmation/OrderSepaConfirmation';
import ErrorPagePayment from '../Error-Page-Payment/ErrorPagePayment';
import OrderCheksConfirmation from '../Order-Check-Confirmation/OrderCheckConfirmation';
import OrderCreditCardConfirmation from '../Order-CreditCard-Confirmation/OrderCreditCardConfirmation';
import './OrderConfirmation.css';

interface Props {
  trainingId: string;
}

export default function OrderConfirmation({ trainingId }: Props) {
  // USERINFOS
  const userInfo = useSelector<RootReducer, UserInfo | null>((state) => state.app.user);

  // CURRENT ORDER
  const [currentOrder, setCurrentOrder] = useState<Order | null>(null);

  // LOADING
  const [loaded, setLoaded] = useState<Boolean>(false);

  // CURRENT TRAINING
  const [currentTraining, setCurrentTraining] = useState<FetchedTraining | null>(null);

  // CURRENT TRAINING WITH TRAINER NAMES
  const [newTrainingWithName, setNewTrainingWithName] = useState<TrainingsWithNameAndProfessionNames | null>(null);

  // GET CURRENT ORDER STORE
  const getOrderFromStore = async () => {
    if (userInfo?.uId && trainingId) {
      const order = await getOrder(trainingId, userInfo.uId);
      setCurrentOrder(order);
    }
  };

  const getCurrentTraining = async () => {
    if (trainingId) {
      const fetchedTraining = await getTraining(trainingId);
      if (fetchedTraining) {
        setCurrentTraining(fetchedTraining);
      }
    }
  };

  useEffect(() => {
    getOrderFromStore();
    getCurrentTraining();
  }, [trainingId]);

  // GET NEW TRAINING ARRAY WITH TRAINERS NAME

  const newTrainingList = async () => {
    if (currentTraining) {
      setLoaded(false);
      const clonedTraining = JSON.parse(JSON.stringify(currentTraining)) as TrainingsWithNameAndProfessionNames;
      const trainingObjectWithName = await Promise.all(clonedTraining.trainers.map((i) => getTrainerName(i)));
      clonedTraining.trainerName = trainingObjectWithName;
      const trainingObjectWithProfession = await Promise.all(
        clonedTraining.trainersProfession.map((i) => getTrainerProfessions(i)),
      );
      clonedTraining.professionName = trainingObjectWithProfession;
      setNewTrainingWithName(clonedTraining);
      setLoaded(true);
    }
  };

  useEffect(() => {
    newTrainingList();
  }, [currentTraining]);

  const selectConfirmationComponent = () => {
    if (currentOrder) {
      if (currentOrder.paymentType === 'sepa') {
        return <OrderSepaConfirmation newTrainingWithName={newTrainingWithName} />;
      }
      if (currentOrder.paymentType === 'checks') {
        return <OrderCheksConfirmation newTrainingWithName={newTrainingWithName} />;
      }
      if (currentOrder.paymentType === 'creditCard') {
        return <OrderCreditCardConfirmation newTrainingWithName={newTrainingWithName} />;
      }
    }
    return <ErrorPagePayment />;
  };

  return (
    <>
      {loaded ? (
        <div className="main-container-order-confirmation">
          <div className="sub-container-order-confirmation">{selectConfirmationComponent()}</div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
