/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import './OrderPayment.css';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import OrderPaymentTabs from '../Order-Payment-Tabs/OrderPaymentTabs';
import CurrentOrderDisplayer from '../Current-Order-Displayer/CurrentOrderDisplayer';
import ScrollToTop from '../scroll-To-Top/ScrollToTop';
import stripeToken from '../../config/stripe';

const stripePromise = loadStripe(stripeToken);

interface Props {
  trainingId: string;
  handleNext: () => void;
}

export default function OrderPayment({ trainingId, handleNext }: Props) {
  return (
    <>
      <ScrollToTop />
      <Elements stripe={stripePromise}>
        <div className="main-container-order-payment">
          <div className="sub-container-order-payment">
            <CurrentOrderDisplayer trainingId={trainingId} />
            <div className="container-payment-element-order-payment">
              <OrderPaymentTabs trainingId={trainingId} handleNext={handleNext} />
            </div>
          </div>
        </div>
      </Elements>
    </>
  );
}
