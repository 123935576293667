import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import './AdminTrainers.css';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { TablePagination } from '@mui/material';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { deleteTrainerFirestore, getAllTrainers, getTrainerProfessions } from '../../firestore/trainers';
import { FetchedTrainer, TrainersWithProfessions } from '../../types/trainers';
import AdminDialog from '../Admin-Dialog/AdminDialog';

/* INTERFACE */

interface Data {
  name: string;
  profession: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface DialProps {
  title: string;
  description: string;
}

/* STYLE */

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.info.dark,
            backgroundColor: lighten(theme.palette.info.main, 0.85),
          }
        : {
            color: theme.palette.info.main,
            backgroundColor: theme.palette.info.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export default function AdminTrainers() {
  // SELECT
  const [selected, setSelected] = useState<Array<string>>([]);

  // DIALOG
  const [openDial, setOpenDial] = useState<boolean>(false);

  // SNACKBAR
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarError, setOpenSnackBarError] = useState(false);

  // PAGE & ROW PER PAGE
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // TRAINERS
  const [trainers, setTrainers] = useState<Array<FetchedTrainer>>([]);
  const [clonedTrainers, setClonedTrainers] = useState<Array<TrainersWithProfessions>>([]);

  // LOADING
  const [loading, setLoading] = useState<Boolean>(false);

  // STYLE
  const classes = useStyles();
  const classesToolBar = useToolbarStyles();

  // HEAD CELLS & DIAL DATA
  const headCells: HeadCell[] = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Nom & Prénom' },
    { id: 'profession', numeric: false, disablePadding: true, label: 'Domaine' },
  ];
  const dialogData: DialProps = {
    title: 'Suppresion des champs sélectionné',
    description:
      'Vous êtes sur le point de supprimer le ou les champs sélectionné êtes-vous sûr de vouloir continuer ?',
  };

  // GET ALL TRAINERS FROM STORE

  const setTrainersFromFirebase = async () => {
    setLoading(true);
    const trainersFromFirebase = await getAllTrainers();
    if (trainersFromFirebase) {
      setTrainers(trainersFromFirebase);
      setLoading(false);
    }
  };

  useEffect(() => {
    setTrainersFromFirebase();
  }, []);

  // GET NEW TRAINER LIST WITH PROFESSIONS

  const newTrainersList = async () => {
    const newArrayTrainers: Array<TrainersWithProfessions> = [];
    await Promise.all(
      trainers.map(async (trainer) => {
        const clonedTrainer = JSON.parse(JSON.stringify(trainer)) as TrainersWithProfessions;
        const idArray = await Promise.all(clonedTrainer.professionIds.map((i) => getTrainerProfessions(i)));
        clonedTrainer.professions = idArray;
        newArrayTrainers.push(clonedTrainer);
      }),
    );
    if (newArrayTrainers.length > 0) {
      setClonedTrainers(newArrayTrainers);
    }
  };

  useEffect(() => {
    newTrainersList();
  }, [trainers]);

  // HANDLE SELECTALLCLICK

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = trainers.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // HANDLE SELECT CLICK

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  // DIALOG

  const triggerDial = () => {
    setOpenDial(true);
  };

  // DELETE TRAINER & IMAGE FROM STORE / STORAGE

  const deleteTrainer = async () => {
    try {
      await Promise.all(selected.map((id) => deleteTrainerFirestore(id)));
      await setTrainersFromFirebase();
      setOpenDial(false);
      setOpenSnackBar(true);
    } catch {
      setOpenSnackBarError(true);
    }
  };

  // SNACKBAR

  const handleCloseSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleCloseSnackBarError = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBarError(false);
  };

  // HANDLE CHANGE PAGE

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // HANDLE CHANGE OF ROWS PER PAGE

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, trainers.length - page * rowsPerPage);

  return (
    <>
      {loading ? (
        <div className="loading-container-admin-trainers">
          <CircularProgress />
        </div>
      ) : (
        <div className="main-container-admin-trainers">
          {openDial ? (
            <AdminDialog
              dialData={dialogData}
              openDial={openDial}
              confirm={() => {
                /* delete trainer */
                deleteTrainer();
              }}
              setDial={(a: boolean) => setOpenDial(a)}
            />
          ) : null}
          <div className="sub-container-admin-trainers">
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <Toolbar
                  className={clsx(classes.root, {
                    [classesToolBar.highlight]: selected.length > 0,
                  })}
                >
                  {selected.length > 0 ? (
                    <Typography className={classesToolBar.title} color="inherit" variant="subtitle1" component="div">
                      {`${selected.length} sélection(s)`}
                    </Typography>
                  ) : (
                    <Typography className={classesToolBar.title} variant="h6" id="tableTitle" component="div">
                      Formateurs
                    </Typography>
                  )}
                  {selected.length > 0 ? (
                    <Tooltip title="Supprimer">
                      <IconButton onClick={triggerDial}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Liste de filtres">
                      <IconButton aria-label="filter list">
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Toolbar>
                <TableContainer>
                  <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            indeterminate={selected.length > 0 && selected.length < trainers.length}
                            checked={trainers.length > 0 && selected.length === trainers.length}
                            onChange={handleSelectAllClick}
                            inputProps={{ 'aria-label': 'select all trainers' }}
                            color="primary"
                          />
                        </TableCell>
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                          >
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clonedTrainers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((trainer, index) => {
                          const isItemSelected = isSelected(trainer.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, trainer.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={trainer.name}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                  color="primary"
                                />
                              </TableCell>
                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                <Link to={`/admin/trainerview/${trainer.id}`}>{trainer.name}</Link>
                              </TableCell>
                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                {trainer?.professions ? trainer.professions.join(' & ') : ''}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={trainers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
            <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
              <Alert onClose={handleCloseSnackBar} severity="success">
                Formateur(s) supprimé
              </Alert>
            </Snackbar>
            <Snackbar open={openSnackBarError} autoHideDuration={60000} onClose={handleCloseSnackBarError}>
              <Alert onClose={handleCloseSnackBarError} severity="error">
                Il y a une erreur
              </Alert>
            </Snackbar>
          </div>
        </div>
      )}
    </>
  );
}
