import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faBuilding, faCheck, faEuroSign, faLock, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import logoIffco from '../../images/logo-header.png';
import logoDataDock from '../../images/datadock.jpg';
import logoFifpl from '../../images/fifpl-logo.png';
import qualiopiBanner from '../../images/qualiopi-banner.jpg';
import FormationsDisplayer from '../../components/Trainings-Displayer/TrainingsDisplayer';
import pdfQualiopi from '../../pdf/pdf-qualiopi.pdf';
import './home.css';
import BannerCounter from '../../components/banner-counter/BannerCounter';

interface Content {
  title: string;
  icon: IconDefinition;
  logo: string;
  subtitle?: string;
  content: () => JSX.Element;
  titleButton: string;
  link: string;
}

export default function Home() {
  const cardsContent: Array<Content> = [
    {
      title: "l'iffco",
      icon: faBuilding,
      logo: logoIffco,
      subtitle: '',
      content: () => (
        <div className="content-cards-bottom-home">
          (Institut Français de Formation Continue en Ostéopathie) est un organisme créé en 2016 et basé à Montpellier.
          L’IFFCO a pour but de proposer aux différents acteurs de la santé des formations continues de qualité dans le
          domaine de l’ostéopathie.
        </div>
      ),
      titleButton: 'En savoir plus',
      link: 'formations',
    },
    {
      title: "l'iffco",
      icon: faEuroSign,
      logo: logoFifpl,
      subtitle: 'Bénéficiez d’une prise en charge par le FIFPL',
      content: () => (
        <div className="content-cards-bottom-home">
          Fonds Interprofessionnel de Formation des Professionnels Libéraux) pour financer votre formation. Rendez-vous
          sur le site du FIFPL pour en savoir plus sur les modalités de prise en charge.
        </div>
      ),
      titleButton: 'Consultez le site de la fifpl',
      link: 'fifpl',
    },
    {
      title: "l'iffco",
      icon: faCheck,
      logo: logoDataDock,
      subtitle: 'L’IFFCO est DATADOCKÉ !',
      content: () => (
        <div className="content-cards-bottom-home">
          Notre organisme à été rendu référençable au sein du Datadock depuis le 27 Septembre 2018. À ce titre, nous
          sommes référencés auprès des organismes financeurs des formations continues en Ostéopathie. Nos formations
          sont donc éligibles à une demande de prise en charge auprès de ces organismes.
        </div>
      ),
      titleButton: 'Consulter le site de datadock',
      link: 'datadock',
    },
  ];

  useEffect(() => {
    document.title = `IFFCO - Accueil`;
  }, []);

  return (
    <div className="main-container-home">
      <div className="sub-container-home">
        <div className="container-banner-top-home">
          <FontAwesomeIcon icon={faCalendarAlt} />
          <h2>Prochaines formations</h2>
        </div>
        <div className="subtitle-banner-top-home">
          <h4>Renseignez-vous dès maintenant sur les prochaines formations de l’IFFCO</h4>
        </div>
        <FormationsDisplayer />
        <div className="container-banner-middle-home">
          <div className="background-lock-icon-home">
            <FontAwesomeIcon icon={faLock} />
          </div>
          <div className="title-banner-middle-home">nouveau !</div>
          <div className="subtitle-banner-middle-home">Commander en ligne </div>
          <div className="content-banner-middle-home">
            <div>
              Réservez vos places, les capacités sont limitées !
              <br />
              Réservation de votre formation en ligne par paiement sécurisé
            </div>
          </div>
          <Link to="/formations">
            <div className="button-redirect-formations-home">
              <Button variant="contained" color="primary">
                Nos formations
              </Button>
            </div>
          </Link>
          <div className="main-container-cards-bottom-home">
            {cardsContent.map((card) => (
              <div className="subcontainer-cards-bottom-home" key={card.link}>
                <div className="title-cards-bottom-home">
                  <FontAwesomeIcon icon={card.icon} />
                  <h3>{card.title}</h3>
                </div>
                <div className="logo-cards-bottom-home">
                  <img src={card.logo} alt="logo-iffco" />
                </div>
                <div className="subtitle-cards-bottom-home">
                  <div>{card.subtitle}</div>
                </div>
                <div className="content-container-cards-bottom-home">
                  <div>{card.content()}</div>
                </div>
                <Link to={card.link}>
                  <div className="button-redirect-formations-home">
                    <Button variant="contained" color="primary">
                      <div>{card.titleButton}</div>
                    </Button>
                  </div>
                </Link>
              </div>
            ))}

            <div className="handicap-disclaimer">
              <div>
                « Si vous êtes en situation de handicap, n’hésitez pas à contacter notre référent handicap afin
                d’évaluer ensemble votre situation et vos besoins, auxquels nous nous efforcerons de répondre du mieux
                possible. »
              </div>
              <div>
                Référent handicap IFFCO : <span>Gaël Arvieux - Ostéopathe D.O</span>, Directeur général IFFCO
              </div>
            </div>
            <div className="container-qualiopi-banner-home">
              <div className="qualiopi-banner-home">
                <FontAwesomeIcon icon={faCheck} />
                <div>qualiopi</div>
              </div>
              <div className="image-banner-qualiopi-home">
                <img src={qualiopiBanner} alt="qualiopi-banner" />
              </div>
              <div className="bottom-container-content-qualiopi-home">
                <div>
                  <div className="bottom-title-qualiopi-home">
                    <div>L’IFFCO est CERTIFIÉ QUALIOPI!</div>
                  </div>
                  <div className="bottom-content-qualiopi-home">
                    <div>
                      <div>Notre organisme est certifié QUALIOPI depuis le 21 Juillet 2020.</div>
                      <br />
                      <div>
                        Cette certification concerne le Référentiel national sur la qualité des actions concourant au
                        développement des compétences dans la catégorie des actions de formation.
                      </div>
                      <br />
                      <div>
                        Ce référentiel national a été créé dans le but d’obtenir un repère fiable et unique pour
                        certifier la qualité du processus de délivrance des actions de formation concourant au
                        développement des compétences. C’est un processus rigoureux et normé auquel l’organisme doit
                        répondre afin d’obtenir la certification. Cette certification est obligatoire pour que les
                        stagiaires puissent accéder à un financement public ou mutualisé de leur formation.
                      </div>
                      <br />
                      <div>
                        Pour les stagiaires, cette certification permet notamment d’être assuré du professionnalisme de
                        notre organisme de formation et également de pouvoir prétendre à une prise en charge financière
                        de sa formation.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a href={pdfQualiopi} target="_blank" rel="noreferrer">
                <div className="button-redirect-formations-home bottom-qualiopi-home">
                  <Button variant="contained" color="primary">
                    consulter notre certificat QUALIOPI
                  </Button>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <BannerCounter />
    </div>
  );
}
