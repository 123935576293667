import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import './OrderSteps.css';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootReducer } from '../../store';
import { UserInfo } from '../../reducers/app';
import OrderLoginRegister from '../Order-Login-Register/OrderLoginRegister';
import OrderUserAddress from '../Order-User-Address/OrderUserAddress';
import OrderContract from '../Order-Contract/OrderContract';
import OrderPayment from '../Order-Payment/OrderPayment';
import { queriesContractsFromUid, queriesOrdersFromUid } from '../../firestore/orders';
import OrderConfirmation from '../Order-Confirmation/OrderConfirmation';
import PageNotFound from '../Page-Not-Found/PageNotFound';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

export default function OrderSteps() {
  const { trainingId } = useParams<{ trainingId: string }>();
  const userInfo = useSelector<RootReducer, UserInfo | null>((state) => state.app.user);

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  function getSteps() {
    return [
      'Connexion / Inscription',
      'Confirmez vos informations',
      'Signature éléctronique du contrat',
      'Paiement',
      "Confirmation d'inscription",
    ];
  }

  const renderStepsIfUserLoggedIn = async () => {
    if (trainingId && userInfo?.uId) {
      // Get order
      const order = await queriesOrdersFromUid(trainingId, userInfo.uId);
      // Get contract
      const contract = await queriesContractsFromUid(trainingId, userInfo.uId);

      if (order) {
        setActiveStep(4);
      } else if (contract) {
        setActiveStep(3);
      } else if (userInfo && userInfo.address) {
        setActiveStep(2);
      } else if (userInfo && !userInfo.address) {
        setActiveStep(1);
      } else {
        setActiveStep(0);
      }
    }
  };

  useEffect(() => {
    renderStepsIfUserLoggedIn();
  }, [userInfo, trainingId]);

  const steps = getSteps();

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return <OrderLoginRegister />;
      case 1:
        return (
          <OrderUserAddress
            handleNext={() => {
              setActiveStep(activeStep + 1);
            }}
          />
        );
      case 2:
        return (
          <OrderContract
            trainingId={trainingId}
            handleNext={() => {
              setActiveStep(activeStep + 1);
            }}
          />
        );
      case 3:
        return (
          <OrderPayment
            trainingId={trainingId || ''}
            handleNext={() => {
              setActiveStep(activeStep + 1);
            }}
          />
        );
      case 4:
        return <OrderConfirmation trainingId={trainingId || ''} />;
      default:
        return <PageNotFound />;
    }
  }

  return (
    <div className="main-container-order-steps">
      <div className="sub-container-order-steps">
        <div className={classes.root}>
          <div className="container-stepper-order-steps">
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div>
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
