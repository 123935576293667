/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useEffect } from 'react';
import './images.css';
import ImageViewer from 'react-simple-image-viewer';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import image1 from '../../images/iffco-1.jpg';
import image2 from '../../images/iffco-2.jpg';
import image3 from '../../images/iffco-3.jpg';
import image4 from '../../images/iffco-4.jpg';
import image5 from '../../images/iffco-5.jpg';
import image6 from '../../images/iffco-6.jpg';
import image7 from '../../images/iffco-7.jpg';
import image8 from '../../images/iffco-8.jpg';
import image9 from '../../images/iffco-9.jpg';
import image10 from '../../images/iffco-10.jpg';
import image11 from '../../images/iffco-11.jpg';
import image12 from '../../images/iffco-12.jpg';
import image13 from '../../images/iffco-13.jpg';
import image14 from '../../images/iffco-14.jpg';
import image15 from '../../images/iffco-15.jpg';
import image16 from '../../images/iffco-16.jpg';
import BreadCrumbs from '../../components/Bread-Crumbs/BreadCrumbs';

export default function Images() {
  const breadCrumbs = [
    {
      link: '/',
      name: 'Accueil',
    },
    {
      link: '/images',
      name: 'Photos',
    },
  ];
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    [
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
      image8,
      image9,
      image10,
      image11,
      image12,
      image13,
      image14,
      image15,
      image16,
    ],
  ];

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    document.title = `IFFCO - Images`;
  }, []);

  return (
    <div className="main-container-images-page">
      <BreadCrumbs breadCrumb={breadCrumbs} />
      <div className="container-banner-images-page">
        <FontAwesomeIcon icon={faCameraRetro} />
        <h2>Photos</h2>
      </div>
      <div className="subtitle-banner-images-page">
        <h4>Retrouvez ci-dessous quelques photos de nos installations</h4>
      </div>
      <div className="sub-container-images-page">
        {images.map((lines) => {
          return (
            <div className="images-container-images-page" key={lines[0]}>
              {lines.map((src, index) => (
                <img
                  src={src}
                  onClick={() => openImageViewer(index)}
                  width="400px"
                  height="400px"
                  key={src}
                  style={{ margin: '20px' }}
                  alt=""
                />
              ))}
            </div>
          );
        })}

        {isViewerOpen && (
          <ImageViewer
            src={images[0]}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.9)',
            }}
          />
        )}
      </div>
    </div>
  );
}
