import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { TrainingsWithNameAndProfessionNames } from '../../types/trainings';
import showDates from '../../utils/dates-utils';
import { diffDays } from '../Admin-Trainings/AdminTrainings';
import './OrderCheckConfirmation.css';

interface Props {
  newTrainingWithName: TrainingsWithNameAndProfessionNames | null;
}

export default function OrderCheckConfirmation({ newTrainingWithName }: Props) {
  return (
    <div className="main-container-check-confirmation">
      <div className="sub-container-check-confirmation">
        <div>
          <FontAwesomeIcon icon={faCheckCircle} />
        </div>
        <div className="main-content-check-confirmation">
          <div>
            <span>Confirmation de la pré-inscription à la formation</span> : {newTrainingWithName?.title}
          </div>
          <div>
            <span>Dispensé par le(s) formateur(s)</span> :
            {newTrainingWithName?.trainerName ? newTrainingWithName.trainerName.join(' & ') : ''}
          </div>
          <div>
            <span>D&apos;une durée de</span> :
            {newTrainingWithName
              ? ` ${
                  newTrainingWithName?.dates?.reduce((acc, item) => {
                    return diffDays(item.endDate.seconds, item.startDate.seconds) + acc;
                  }, 0) || 0 + 1
                } jour(s)`
              : ''}
          </div>
          <div>
            <span>Date(s) </span>
            {`: 
            ${
              newTrainingWithName
                ? newTrainingWithName.dates?.map((date) => {
                    return showDates(date.startDate.seconds, date.endDate.seconds);
                  })
                : ''
            }`}
          </div>
        </div>
        <div className="main-content-check-confirmation">
          Vous avez choisi d’effectuer votre règlement par chèque et nous vous en remercions. Chèque à mettre à l’ordre
          de l’IFFCO, à adresser par voie postale a :
        </div>
        <div className="main-content-check-confirmation address-check-confirmation">
          <div>L’IFFCO</div>
          <div>51 rue de la Méditerranée</div>
          <div>34000 Montpellier</div>
        </div>
        <div className="main-content-check-confirmation">
          Pour chaque formation commandée: Merci de régler en 2 chèques (1 chèque de 30% du montant, encaissé 10 jours
          après réception et un autre chèque de 70% du montant, encaissé le 1er jour de la formation.)
        </div>
        <div className="main-content-check-confirmation">
          En cas de règlement par chèques pour plusieurs formations commandées, merci de réaliser des chèques séparés
          pour chacune des formations.
        </div>
        <div className="main-content-check-confirmation link-check-confirmation">
          <div>Nous vous remercions pour votre paiement.</div>
          <div>
            Dès que votre règlement par chèque sera reçu, nous validerons votre commande par mail.
            <div>
              Vous pourrez ensuite accéder au suivi de celle-ci ainsi que consulter votre facture dans votre
              <Link target="_blank" to="/myspace/orders">
                espace client.
              </Link>
            </div>
          </div>
        </div>
        <div className="main-content-check-confirmation">
          <div>Veuillez recevoir nos sincères salutations,</div>
          <div>L’équipe Iffco</div>
        </div>
      </div>
    </div>
  );
}
