import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCity, faEdit, faEnvelope, faHome, faPhoneAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserInfo } from '../../reducers/app';
import { RootReducer } from '../../store';
import './UserAddress.css';

export default function UserAddress() {
  const userInfos = useSelector<RootReducer, UserInfo | null>((state) => state.app.user);

  useEffect(() => {
    document.title = `IFFCO - Mes adresses`;
  }, []);

  return (
    <div className="main-container-user-address">
      <div className="sub-container-user-address">
        <div className="title-user-address">Mon adresse de facturation</div>
        <div className="subtitle-user-address">
          L&apos;adresse de facturation suivante sera utilisée sur la page de paiement
        </div>
        <div className="container-address-user-address">
          <div className="title-container-address-user-address">
            adresse de facturation
            <div className="container-edit-button-user-address">
              <Link to="/myspace/address/edit-address">
                <div className="edit-button-user-address">
                  <FontAwesomeIcon icon={faEdit} />
                  Modifier
                </div>
              </Link>
            </div>
          </div>
          <div className="content-container-address-user-address">
            <FontAwesomeIcon icon={faUser} />
            {userInfos?.lastName}
            &nbsp;
            {userInfos?.firstName}
          </div>
          <div className="content-container-address-user-address">
            <FontAwesomeIcon icon={faHome} />
            {userInfos?.address}
          </div>
          {userInfos?.compAddress ? (
            <div className="content-container-address-admin-user-view">
              <FontAwesomeIcon icon={faBuilding} />
              {userInfos?.compAddress}
            </div>
          ) : (
            ''
          )}
          <div className="content-container-address-user-address">
            <FontAwesomeIcon icon={faCity} />
            {userInfos?.zipCode}
            &nbsp;
            {userInfos?.city}
          </div>
          <div className="content-container-address-user-address">
            <FontAwesomeIcon icon={faPhoneAlt} />
            {userInfos?.phone}
          </div>
          <div className="content-container-address-user-address">
            <FontAwesomeIcon icon={faEnvelope} />
            {userInfos?.email}
          </div>
        </div>
      </div>
    </div>
  );
}
