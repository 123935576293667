import { faBookMedical, faChalkboardTeacher, faMedal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './Banner.css';

export default function Banner() {
  return (
    <div className="main-container-banner">
      <div className="container-content-banner">
        <div className="content-banner">
          <div className="icon-content-banner">
            <FontAwesomeIcon icon={faMedal} />
          </div>
          <div className="text-container-banner">
            <div className="text-content-banner">développer vos connaissances et vos compétences</div>
          </div>
        </div>
      </div>
      <div className="container-content-banner">
        <div className="content-banner">
          <div className="icon-content-banner">
            <FontAwesomeIcon icon={faBookMedical} />
          </div>
          <div className="text-container-banner">
            <div className="text-content-banner">une gamme complète de formations de qualité</div>
          </div>
        </div>
      </div>
      <div className="container-content-banner">
        <div className="content-banner">
          <div className="icon-content-banner">
            <FontAwesomeIcon icon={faChalkboardTeacher} />
          </div>
          <div className="text-container-banner">
            <div className="text-content-banner">des formateurs experts et reconnus en ostéopathie</div>
          </div>
        </div>
      </div>
    </div>
  );
}
