/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useState, useEffect, SyntheticEvent } from 'react';
import { PhotoCamera } from '@material-ui/icons';
import Select, { OptionsType, OptionTypeBase } from 'react-select';
import makeAnimated from 'react-select/animated';
import './AdminEditTraining.css';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import parse from 'date-fns/parse';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Navigate, useParams } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import Alert from '@material-ui/lab/Alert';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getAllProfessions, getAllTrainers, getTrainerName, getTrainerProfessions } from '../../firestore/trainers';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import randomId from '../../utils/utils';
import {
  Dates,
  FetchedDates,
  FetchedTraining,
  ImageAndPdf,
  SelectValue,
  Training,
  TrainingFiles,
} from '../../types/trainings';
import {
  createFile,
  deleteFilesFromStore,
  getTraining,
  getTrainingFiles,
  updateTraining,
} from '../../firestore/trainings';
import { addFileStorage, deleteFileStorage, FileToStorage } from '../../storage/storage';

interface InputValues {
  error: boolean;
  value: string;
  label: string;
  errorMessage: string;
  field: string;
  helperText?: string;
}

export default function AdminEditTraining() {
  // PARAMS

  const { formationId } = useParams<{ formationId?: string }>();

  // IMAGE STATES
  const [imageToUpload, setImageToUpload] = useState<File>();
  const [currentImage, setCurrentImage] = useState<ImageAndPdf>();
  const [uploadPending, setUploadPending] = useState<Boolean>(false);

  // PDF STATES
  const [pdfToUpload, setPdfToUpload] = useState<File>();
  const [currentPdf, setCurrentPdf] = useState<ImageAndPdf>();

  // ERRORS
  const [error, setError] = useState<Boolean>(false);

  // VALIDATION MESSAGE
  const [validationMessage, setValidationMessage] = useState<Boolean>(false);

  // EDITOR WYSIWYG
  const [targetsState, setTargetsState] = useState(() => EditorState.createEmpty());
  const [contractState, setContractState] = useState(() => EditorState.createEmpty());
  const [emailState, setEmailState] = useState(() => EditorState.createEmpty());
  const [checkState, setCheckState] = useState(() => EditorState.createEmpty());

  // LOADING STATE

  // TEXT AREA STATE
  const [trainingDescription, setTrainingDescription] = useState<string>('');

  // SELECT TRAINER & PROFESSION
  const [selectTrainer, setSelectTrainer] = useState<Array<SelectValue>>([]);
  const [selectProfession, setSelectProfession] = useState<Array<SelectValue>>([]);
  const [selectedTrainers, setSelectedTrainers] = useState<Array<SelectValue>>([]);
  const [selectedProfessions, setSelectedProfessions] = useState<Array<SelectValue>>([]);

  // DATES
  const now = new Date();
  const [dates, setDates] = useState<Array<Dates>>([{ startDate: new Date(now), endDate: new Date(now), id: 0 }]);
  const [fetchedDates, setFetchedDates] = useState<Array<FetchedDates>>([]);

  // EDIT SECTION STATES

  const [trainingToChange, setTrainingToChange] = useState<FetchedTraining | null>(null);

  // FILES

  const [trainingFiles, setTrainingFiles] = useState<Array<TrainingFiles>>([]);
  const [newTrainingFiles, setNewTrainingFiles] = useState<Array<FileToStorage>>([]);
  const [uploadVariousFilePending, setUploadVariousFilePending] = useState<Boolean>(false);

  // FETCH TRAINING BY ID

  const fetchTraining = async () => {
    if (formationId) {
      const fetchTrainingStore = await getTraining(formationId);
      if (fetchTrainingStore) {
        setTrainingToChange(fetchTrainingStore);
        setCurrentImage(fetchTrainingStore.image);
        setCurrentPdf(fetchTrainingStore.pdf);
        const targetsFromHTML = convertFromHTML(fetchTrainingStore.targets);
        const targetsStateParsed = EditorState.createWithContent(
          ContentState.createFromBlockArray(targetsFromHTML.contentBlocks),
        );
        const contractFromHTML = convertFromHTML(fetchTrainingStore.contract);
        const contractStateParsed = EditorState.createWithContent(
          ContentState.createFromBlockArray(contractFromHTML.contentBlocks),
        );
        if (fetchTrainingStore.email) {
          const emailFromHTML = convertFromHTML(fetchTrainingStore.email);
          const emailStateParsed = EditorState.createWithContent(
            ContentState.createFromBlockArray(emailFromHTML.contentBlocks),
          );
          setEmailState(emailStateParsed);
        }
        if (fetchTrainingStore.dates) {
          const newDates = fetchTrainingStore.dates.map((date) => ({
            startDate: date.startDate.toDate(),
            endDate: date.endDate.toDate(),
            id: date.id,
          }));
          setDates(newDates);
        }
        if (fetchTrainingStore.checkPaymentTemplate) {
          const checkFromHTML = convertFromHTML(fetchTrainingStore.checkPaymentTemplate);
          const checkStateParsed = EditorState.createWithContent(
            ContentState.createFromBlockArray(checkFromHTML.contentBlocks),
          );
          setCheckState(checkStateParsed);
        }
        setTargetsState(targetsStateParsed);
        setContractState(contractStateParsed);
      }
    }
  };

  useEffect(() => {
    fetchTraining();
  }, []);

  // DEFAULT VALUE TRAINER SELECT

  const getSelectDefaultValueTrainer = async () => {
    if (trainingToChange) {
      const trainerFromFirestore = await Promise.all(
        trainingToChange.trainers.map((trainerId: string) => getTrainerName(trainerId)),
      );
      const newSelectTrainer: Array<SelectValue> = trainerFromFirestore.map((trainer, i) => ({
        value: trainingToChange.trainers[i],
        label: trainer,
      }));
      setSelectedTrainers(newSelectTrainer);
    }
  };

  // GET CURRENT PROFESSION

  const getSelectDefaultValueProfession = async () => {
    if (trainingToChange) {
      const trainerFromFirestore = await Promise.all(
        trainingToChange.trainersProfession.map((profession: string) => getTrainerProfessions(profession)),
      );
      const newSelectProfession: Array<SelectValue> = trainerFromFirestore.map((trainer, i) => ({
        value: trainingToChange.trainersProfession[i],
        label: trainer,
      }));
      setSelectedProfessions(newSelectProfession);
    }
  };

  useEffect(() => {
    getSelectDefaultValueTrainer();
    getSelectDefaultValueProfession();
    /*  formatStartDate();
    formatEndDate(); */
  }, [trainingToChange]);

  const [inputValues, setInputValues] = useState<Array<InputValues>>([
    {
      label: 'Titre de la formation',
      field: 'trainingtitle',
      error: false,
      errorMessage: 'Ce champs est obligatoire',
      value: trainingToChange ? trainingToChange?.title : '',
    },
    {
      label: 'Sous-titre',
      field: 'subtitle',
      error: false,
      errorMessage: 'Ce champs est obligatoire',
      value: '',
    },
    {
      label: 'Public',
      field: 'public',
      error: false,
      errorMessage: 'Ce champs est obligatoire',
      value: '',
    },
    {
      label: 'Ville',
      field: 'location',
      error: false,
      errorMessage: 'Ce champs est obligatoire',
      value: '',
    },
    {
      label: 'Prix',
      field: 'price',
      error: false,
      errorMessage: 'Ce champs est obligatoire',
      value: '',
    },
  ]);

  const animatedComponents = makeAnimated();

  // SELECT SETTINGS INFOS TRAINER / PROFESSIONS

  const getTrainers = async () => {
    const trainersFromFirebase = await getAllTrainers();
    const professionFromFirebase = await getAllProfessions();
    if (trainersFromFirebase) {
      const newSelectTrainer: Array<SelectValue> = trainersFromFirebase.map((trainer) => ({
        value: trainer.id,
        label: trainer.name,
      }));
      const newSelectProfession: Array<SelectValue> = professionFromFirebase.map((profession) => ({
        value: profession.id,
        label: profession.name,
      }));
      setSelectTrainer(newSelectTrainer);
      setSelectProfession(newSelectProfession);
    }
  };

  useEffect(() => {
    getTrainers();
  }, []);

  // GET TRAINING FILES

  const getTrainingFilesFromStore = async () => {
    if (formationId) {
      const files = await getTrainingFiles(formationId);
      if (files) {
        setTrainingFiles(files);
      }
    }
  };

  useEffect(() => {
    getTrainingFilesFromStore();
  }, [formationId]);

  // SELECT HANDLING TRAINER / PROFESSION

  const handlingSelectTrainer = (value: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
    if (value) {
      setSelectedTrainers(value.map((v: OptionTypeBase) => ({ value: v.value, label: v.label })));
    }
    return '';
  };

  const handlingSelectProfession = (value: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
    if (value) {
      setSelectedProfessions(value.map((v: OptionTypeBase) => ({ value: v.value, label: v.label })));
    }
    return '';
  };

  // DATE HANDLING

  const handleCloseDateSelect = () => {
    return '';
  };

  /*   const handleStartingDateChange = (date: Date | null) => {
    if (date) {
      handleCloseDateSelect();
      setStartDate(date);
    }
  };

  const handleEndingDateChange = (date: Date | null) => {
    if (date) {
      setEndDate(date);
    }
  };

  const handleStartingDate2Change = (date: Date | null) => {
    if (date) {
      handleCloseDateSelect();
      setStartDate2(date);
    }
  };

  const handleEndingDate2Change = (date: Date | null) => {
    if (date) {
      setEndDate2(date);
    }
  }; */

  const handleDateChange = (e: Date | null, date: number, type: string) => {
    const newDates = [...dates];
    if (type === 'startDate') {
      newDates.filter((d) => d.id === date)[0].startDate = e || new Date();
    } else {
      newDates.filter((d) => d.id === date)[0].endDate = e || new Date();
    }
    setDates(newDates);
  };

  // INPUTS HANDLER

  const inputValuesHandler = (e: { target: { name: string; value: string } }) => {
    const index = inputValues.findIndex((a) => a.field === e.target.name);
    inputValues[index].value = e.target.value;
    setInputValues(inputValues);
  };

  // IMAGE HANDLING

  const addImageStorage = async () => {
    const generatedId = randomId();
    const path = 'trainings-images';
    if (imageToUpload) {
      const newImageName = imageToUpload.name;
      setUploadPending(true);
      const uploadImage = await addFileStorage(generatedId, imageToUpload, path, newImageName);
      setUploadPending(false);
      return uploadImage;
    }
    return { url: '', imageId: '' };
  };

  const onChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      setImageToUpload(event.target.files[0]);
      setCurrentImage({
        url: URL.createObjectURL(event.target.files[0]),
        id: '',
        fileName: event.target.files[0].name,
      });
    }
  };

  // FILES PDF HANDLING

  const addPdfFilesStorage = async () => {
    const generatedId = randomId();
    const pathName = 'trainings-programs';
    if (pdfToUpload) {
      const newPdfName = pdfToUpload.name;
      setUploadPending(true);
      const uploadPdf = await addFileStorage(generatedId, pdfToUpload, pathName, newPdfName);
      setUploadPending(false);
      return uploadPdf;
    }
    return { url: '', pdfId: '' };
  };

  const onChangePdf = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      setPdfToUpload(event.target.files[0]);
      setCurrentPdf({
        url: URL.createObjectURL(event.target.files[0]),
        id: '',
        fileName: event.target.files[0].name,
      });
    }
  };

  // TEXT AREA HANDLING
  const handlingTextArea = (e: { target: { value: string } }) => {
    const description = e.target.value;
    setTrainingDescription(description);
  };

  // WYSIWYG TARGETS FUNCTIONS

  const convertWysiwygToHTML = (contentToConvert: EditorState) => {
    const currentContentAsHTML = convertToRaw(contentToConvert.getCurrentContent());
    const markup = draftToHtml(currentContentAsHTML);
    return markup;
  };

  const handleTargetsChange = (state: React.SetStateAction<EditorState>) => {
    setTargetsState(state);
    convertWysiwygToHTML(targetsState);
  };

  // WYSIWYG CONTRACT FUNCTIONS

  const handleContractChange = (state: React.SetStateAction<EditorState>) => {
    setContractState(state);
    convertWysiwygToHTML(contractState);
  };

  // WYSIWYG EMAIL FUNCTIONS

  const handleEmailChange = (state: React.SetStateAction<EditorState>) => {
    setEmailState(state);
    convertWysiwygToHTML(emailState);
  };

  const handleCheckChange = (state: React.SetStateAction<EditorState>) => {
    setCheckState(state);
    convertWysiwygToHTML(checkState);
  };

  const checkIfNewImage = async () => {
    const imagePath = 'trainings.images';
    if (imageToUpload) {
      if (trainingToChange) {
        // DELETE THE OLD IMAGE
        deleteFileStorage(imagePath, trainingToChange?.image?.id);
        setUploadPending(true);
        const image = await addImageStorage();
        setUploadPending(false);
        return image;
      }
    }
    return '';
  };

  const checkIfNewPdf = async () => {
    const pdfPath = 'trainings-programs';
    if (pdfToUpload) {
      if (trainingToChange) {
        // DELETE PDF STORAGE
        deleteFileStorage(pdfPath, trainingToChange.pdf.id);
        // Add pdf to the storage
        setUploadPending(true);
        const pdf = await addPdfFilesStorage();
        setUploadPending(false);
        return pdf;
      }
    }
    return '';
  };

  const addDate = () => {
    const newId = dates.reduce((acc, curr) => {
      return acc + curr.id;
    }, 1);
    const newDate = [{ startDate: new Date(now), endDate: new Date(now), id: newId }];
    const newDates = dates.concat(newDate);
    setDates(newDates);
  };

  const deleteDate = (id: number) => {
    const datesShadow = [...dates];
    const newDates = datesShadow.filter((date) => date.id !== id);
    setDates(newDates);
  };

  // HANDLE SUBMIT
  const handleSubmit = async () => {
    // GET CONVERTED TARGETS
    const targets = convertWysiwygToHTML(targetsState);

    // GET CONVERTED CONTRACT
    const contract = convertWysiwygToHTML(contractState);

    // GET CONVERTED EMAIL
    const email = convertWysiwygToHTML(emailState);

    // GET CONVERTED CHECKTEMPLATE
    const checkPaymentTemplate = convertWysiwygToHTML(checkState);

    // GET IMAGE
    const image: ImageAndPdf = (await checkIfNewImage()) as ImageAndPdf;

    // GET PDF
    const pdf: ImageAndPdf = (await checkIfNewPdf()) as ImageAndPdf;

    if (trainingToChange) {
      const training: Training = {
        title: inputValues[0].value || trainingToChange.title,
        subtitle: inputValues[1].value || trainingToChange.subtitle || '',
        location: inputValues[3].value || trainingToChange.location,
        price: inputValues[4].value || trainingToChange.price,
        peopleConcerned: inputValues[2].value || trainingToChange.peopleConcerned,
        description: trainingDescription || trainingToChange.description,
        targets,
        contract,
        email,
        checkPaymentTemplate,
        trainersProfession: selectedProfessions.map((p) => p.value),
        trainers: selectedTrainers.map((t) => t.value),
        image: image || currentImage,
        pdf: pdf || currentPdf,
        dates,
      };
      if (newTrainingFiles && formationId) {
        await Promise.all(newTrainingFiles.map((file) => createFile(file.fileName, file.url, formationId, file.id)));
        setValidationMessage(true);
      }
      if (
        training.title &&
        training.location &&
        training.price &&
        training.peopleConcerned &&
        training.description &&
        training.targets &&
        training.trainersProfession &&
        training.trainers &&
        training.image &&
        training.pdf &&
        training.email &&
        training.contract &&
        training.checkPaymentTemplate &&
        training.dates
      ) {
        await updateTraining(trainingToChange.trainingId, training);

        setValidationMessage(true);
      } else {
        setError(true);
      }
    }
  };

  const addFilesStorage = async (fileToUpload: File) => {
    const generatedId = randomId();
    if (fileToUpload) {
      setUploadVariousFilePending(true);
      const fileToStorage = await addFileStorage(generatedId, fileToUpload, '/trainings-files', fileToUpload.name);
      const newFiles = JSON.parse(JSON.stringify(trainingFiles));
      newFiles.push(fileToStorage);
      setTrainingFiles(newFiles);
      setNewTrainingFiles(newFiles);
      setUploadVariousFilePending(false);
    }
    return { url: '', fileName: '' };
  };

  const onChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      await addFilesStorage(event.target.files[0]);
    }
  };

  const deleteTheFile = async (storageId: string, fileId: string) => {
    setUploadPending(true);
    const filesShadowCopy = [...trainingFiles];
    await deleteFileStorage('trainings-files', storageId);
    await deleteFilesFromStore(fileId);
    const fileToDelete = filesShadowCopy.findIndex((file) => file.storageId === storageId);
    filesShadowCopy.splice(fileToDelete, 1);
    await setTrainingFiles(filesShadowCopy);
    setUploadPending(false);
  };

  const renderFiles = () => {
    return trainingFiles.map((file) => (
      <div key={file.id} className="content-file-table-edit-training">
        <a href={file.fileUrl} target="_blank" rel="noreferrer">
          {file.fileName}
        </a>
        <FontAwesomeIcon icon={faTimesCircle} onClick={() => deleteTheFile(file.storageId, file.id)} />
      </div>
    ));
  };

  useEffect(() => {
    renderFiles();
  }, [trainingFiles]);

  return (
    <div className="main-container-edit-training">
      <div className="sub-container-edit-training">
        {!trainingToChange ? (
          <div className="loading-container-admin-trainers">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="info-edit-training">
              <h4>Informations :</h4>
              <TextField
                className="textfield-login"
                name={inputValues[0].field}
                label={inputValues[0].label}
                defaultValue={trainingToChange.title}
                onChange={inputValuesHandler}
                variant="outlined"
              />
              <div>
                <TextField
                  className="textfield-login"
                  name={inputValues[1].field}
                  label={inputValues[1].label}
                  defaultValue={trainingToChange.subtitle}
                  onChange={inputValuesHandler}
                  variant="outlined"
                />
              </div>
              <div>
                <TextField
                  className="textfield-login"
                  name={inputValues[2].field}
                  label={inputValues[2].label}
                  defaultValue={trainingToChange.peopleConcerned}
                  onChange={inputValuesHandler}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="select-button-edit-training">
              <Select
                options={selectTrainer}
                value={selectedTrainers}
                components={animatedComponents}
                onChange={handlingSelectTrainer}
                placeholder="Formateurs"
                isMulti
              />
            </div>
            <div className="select-button-edit-training">
              <Select
                options={selectProfession}
                value={selectedProfessions}
                components={animatedComponents}
                onChange={handlingSelectProfession}
                placeholder="Professions"
                isMulti
              />
            </div>
            <div className="container-input-middle-edit-training">
              <div>
                <TextField
                  className="textfield-login"
                  name={inputValues[3].field}
                  label={inputValues[3].label}
                  defaultValue={trainingToChange.location}
                  onChange={inputValuesHandler}
                  variant="outlined"
                />
              </div>
              <div>
                <TextField
                  className="textfield-login"
                  name={inputValues[4].field}
                  label={inputValues[4].label}
                  defaultValue={trainingToChange.price}
                  onChange={inputValuesHandler}
                  variant="outlined"
                />
              </div>
            </div>
            {dates.map((date) => {
              return (
                <div key={date.id} className="container-date-picker-add-training">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="start-date-picker"
                      label="Date de début"
                      value={date.startDate}
                      onClose={handleCloseDateSelect}
                      onChange={(e) => {
                        handleDateChange(e, date.id, 'startDate');
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <div className="admin-add-training-delete-date-button">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="end-date-picker"
                        label="Date de fin"
                        value={date.endDate}
                        onChange={(e) => {
                          handleDateChange(e, date.id, 'endDate');
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <Tooltip title="Supprimer la date">
                      <IconButton onClick={(e) => deleteDate(date.id)} aria-label="add" size="medium">
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
            <div id="addButton">
              <Tooltip title="Ajouter un jeu de date">
                <IconButton onClick={addDate} aria-label="add" size="medium">
                  <Add />
                </IconButton>
              </Tooltip>
            </div>
            <div className="add-description-edit-training">
              <h4>Description formateur :</h4>
              <TextareaAutosize
                aria-label="minimum height"
                onChange={handlingTextArea}
                defaultValue={trainingToChange.description}
                rowsMin={7}
              />
            </div>
            <div className="add-targets-edit-training">
              <h4>Objectifs :</h4>
              <Editor
                editorState={targetsState as any}
                onEditorStateChange={handleTargetsChange as any}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
            <div className="add-targets-edit-training">
              <h4>Contrat :</h4>
              <Editor
                editorState={contractState as any}
                onEditorStateChange={handleContractChange as any}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
            <div className="add-targets-edit-training">
              <h4>E-mail :</h4>
              <Editor
                editorState={emailState as any}
                onEditorStateChange={handleEmailChange as any}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
            <div>
              <h4>Chèque :</h4>
              <Editor
                editorState={checkState as any}
                onEditorStateChange={handleCheckChange as any}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
            <div className="add-image-add-traning">
              <h4>Photo :</h4>
              {currentImage ? (
                <>
                  <label htmlFor="icon-button-file">
                    <input accept="image/*" id="icon-button-file" type="file" onChange={onChangeImage} />
                    <img src={currentImage.url} alt="trainers" />
                  </label>
                </>
              ) : (
                <div className="drag-drop-edit-training">
                  <label htmlFor="icon-button-image">
                    <input accept="image/*" id="icon-button-image" type="file" onChange={onChangeImage} />
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </div>
              )}
            </div>
            <div className="add-image-add-traning">
              <h4>Fichiers pdf :</h4>
              {currentPdf ? (
                <>
                  <div className="upload-pdf-container-edit-training">
                    <input
                      accept="application/pdf"
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={onChangePdf}
                    />
                    <label htmlFor="contained-button-file">
                      <div>{currentPdf.fileName}</div>
                    </label>
                    <a href={currentPdf.url} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faSearch} />
                    </a>
                  </div>
                </>
              ) : (
                <div className="drag-drop-edit-training">
                  <label htmlFor="icon-button-pdf">
                    <input accept="application/pdf" id="icon-button-pdf" type="file" onChange={onChangePdf} />
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <GetAppIcon />
                    </IconButton>
                  </label>
                </div>
              )}
            </div>
            <div className="add-files-add-training">
              <div>Ajouter des fichiers :</div>
              <div className="button-add-file-add-training">
                {uploadVariousFilePending ? (
                  <CircularProgress />
                ) : (
                  <>
                    <label htmlFor="add-file">
                      <input accept="application/pdf" id="add-file" type="file" onChange={onChangeFile} />
                      {uploadPending ? (
                        <CircularProgress />
                      ) : (
                        <Button variant="contained" color="primary" component="span">
                          Télécharger des fichiers
                        </Button>
                      )}
                    </label>
                  </>
                )}
              </div>
              {trainingFiles.length !== 0 ? (
                <div className="container-files-table-edit-training">{renderFiles()} </div>
              ) : null}
            </div>
            {error ? <Alert severity="error">Erreur</Alert> : null}
            {validationMessage ? <Alert severity="success">La formation à bien était modifié</Alert> : null}
            <div className="submit-button-container-edit-training">
              {uploadPending ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Modifier
                </Button>
              )}
            </div>
            {validationMessage ? <Navigate to={`/admin/trainingview/${trainingToChange.trainingId}`} /> : ''}
          </>
        )}
      </div>
    </div>
  );
}
