import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Login from '../../components/login/Login';
import SignIn from '../../components/sign-in/SignIn';
import { AppState } from '../../reducers/app';
import { RootReducer } from '../../store';
import ScrollToTop from '../../components/scroll-To-Top/ScrollToTop';
import './LandingPageUser.css';
import MenuMobileDisplayer from '../../components/Menu-Mobile-Displayer/MenuMobileDisplayer';
import TabsDisplayer from '../../components/tabs-displayer/TabsDisplayer';

export default function LandingPageUser() {
  const { user } = useSelector<RootReducer, AppState>((state) => state.app);

  const [tabName, setTabName] = useState([
    {
      name: 'Tableau de bord',
      pathName: '',
      highlights: ['', 'userboad'],
      isActive: false,
      id: '0',
    },
    {
      name: 'Mes formations',
      pathName: 'orders',
      highlights: ['orders', 'orderview'],
      isActive: false,
      id: '1',
    },
    {
      name: 'Mes Adresses',
      pathName: 'address',
      isActive: false,
      highlights: ['address'],
      id: '3',
    },
    {
      name: 'Détails du compte',
      pathName: 'accountdetails',
      highlights: ['accountdetails'],
      isActive: false,
      id: '4',
    },
  ]);
  const [mobileView, setMobileView] = useState(window.matchMedia('max-width: 800px)').matches);

  useEffect(() => {
    const media = window.matchMedia('(max-width: 800px)');
    if (media.matches !== mobileView) {
      setMobileView(media.matches);
    }
    document.title = `IFFCO - Détails du compte`;
    const listener = () => setMobileView(media.matches);
    media.addListener(listener);
  }, []);

  return (
    <div className="main-container-landing-page-user">
      {user ? (
        <div className="container-client-account-landing-page-user">
          <div className="main-container-client-account">
            <ScrollToTop />
            <div className="sub-container-client-account">
              {mobileView ? (
                <MenuMobileDisplayer tabName={tabName} />
              ) : (
                <TabsDisplayer tabName={tabName} setTabName={setTabName} />
              )}
              <Outlet />
            </div>
          </div>
        </div>
      ) : (
        <div className="container-register-login-landing-page-user">
          <Login />
          <SignIn />
        </div>
      )}
    </div>
  );
}
