/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo-header.png';
import './Header.css';

interface MenuItemDetail {
  pathName?: string;
  title: string;
}
interface MenuItem extends MenuItemDetail {
  children: Array<MenuItemDetail>;
}

export default function Header() {
  const menu: Array<MenuItem> = [
    {
      title: 'accueil',
      pathName: 'home',
      children: [],
    },
    {
      title: 'formations',
      children: [
        {
          title: 'calendrier des formations',
          pathName: 'formations',
        },
        {
          title: 'comment réserver ?',
          pathName: 'howtoreserve',
        },
        {
          title: 'prise en charge',
          pathName: 'support',
        },
      ],
    },
    {
      title: 'formateurs',
      pathName: 'trainers',
      children: [],
    },
    {
      title: "l'iffco",
      children: [
        {
          title: "présentation de l'iffco",
          pathName: 'presentation',
        },
        { title: 'photos', pathName: 'pictures' },
      ],
    },
    {
      title: 'Partenaires',
      pathName: 'partnersPage',
      children: [],
    },
    {
      title: 'faq',
      pathName: 'faq',
      children: [],
    },
    {
      title: 'contact',
      pathName: 'contact',
      children: [],
    },
    {
      title: 'espace client',
      children: [
        { title: 'mon compte', pathName: 'myspace' },
        { title: 'déconnexion', pathName: '' },
      ],
    },
  ];

  const mouseOver = (currentTarget: EventTarget & HTMLDivElement, type: boolean) => {
    const dropdownContent = currentTarget.querySelector<HTMLDivElement>('.dropdown-content');
    if (dropdownContent) {
      if (type) {
        dropdownContent.classList.add('show-dropdown-content');
      } else {
        dropdownContent.classList.remove('show-dropdown-content');
      }
    }
  };

  return (
    <div className="main-div-container-header">
      <div className="sub-div-main-container-header">
        <div className="main-logo-container-header">
          <div className="logo-subcontainer-header">
            <Link to="/">
              <img src={logo} alt="logo-iffco" />
            </Link>
          </div>
        </div>
        <div className="main-div-button-header">
          {menu.map((item) => (
            <div
              key={item.title}
              className="dropdown"
              onMouseEnter={({ currentTarget }) => mouseOver(currentTarget, true)}
              onMouseLeave={({ currentTarget }) => mouseOver(currentTarget, false)}
            >
              <div className="dropbtn">
                {item.pathName ? (
                  <Link className="dropbtn" to={`/${item.pathName}`}>
                    {item.title}
                  </Link>
                ) : (
                  <div>{item.title}</div>
                )}
                <div className="underline-header" />
              </div>
              <div className="dropdown-content">
                {item.children.map((child) => (
                  <Link key={child.pathName} to={`/${child.pathName}`}>
                    <div>{child.title}</div>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
