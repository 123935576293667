import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { afterPaymentOrderByCheckAndSepaProcess } from '../../firestore/orders';
import { getTraining } from '../../firestore/trainings';
import { UserInfo } from '../../reducers/app';
import { RootReducer } from '../../store';
import './OrderSepaPayment.css';

interface Props {
  trainingId: string;
  handleNext: () => void;
}

export default function OrderSepaPayment({ trainingId, handleNext }: Props) {
  // CURRENT USER INFOS
  const userInfo = useSelector<RootReducer, UserInfo | null>((state) => state.app.user);

  // LOADING
  const [loading, setLoading] = useState<boolean>(false);

  // CHECK BOX
  const [checked, setChecked] = useState<boolean>(false);
  const [checkBoxErrorMessage, setCheckBoxErrorMessage] = useState<string>('');

  // ERROR MESSAGE
  const [errorMessage, setErrorMessage] = useState<string>();
  // TRAINING INFO
  const [trainingPrice, setTrainingPrice] = useState<string>('');

  // CHECK BOX HANDLING
  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  // TRAINING INFOS

  const getTrainingPrice = async () => {
    const training = await getTraining(trainingId);
    if (training) {
      setTrainingPrice(training.price);
    }
    return '';
  };

  useEffect(() => {
    getTrainingPrice();
  }, [trainingId]);

  // HANDLE SUBMIT

  const handleSubmit = async () => {
    const paymentType = 'sepa';
    setLoading(true);
    if (checked) {
      if (userInfo?.uId && trainingId) {
        setCheckBoxErrorMessage('');
        const afterProcess = await afterPaymentOrderByCheckAndSepaProcess(
          trainingId,
          userInfo.uId,
          paymentType,
          trainingPrice,
        );
        if (afterProcess === 'order_already_exist') {
          setErrorMessage(
            'Votre inscription à déjà été prise en compte, veuillez vous rendre dans votre espace personel, ou rafraichir cette page',
          );
        }
        handleNext();
        setLoading(false);
      }
    } else {
      setCheckBoxErrorMessage('Veuillez lire et accepter les conditions générales pour poursuivre votre commande.');
      setLoading(false);
    }
  };

  return (
    <div className="main-container-order-sepa-payment">
      <div className="sub-container-order-sepa-payment">
        <div className="content-order-sepa-payment">
          Le paiement d’un montant de : <span>{trainingPrice} €</span> se fait directement depuis votre compte bancaire
          en ligne. Nous vous remercions de bien vouloir utiliser l’identifiant qui vous sera remit à la prochaine étape
          comme référence du paiement, ainsi que nos coordonnées bancaires qui figureront à la prochaine étapes et qui
          vous seront transmises par mail après validation de votre commande.
        </div>
        <div className="content-order-sepa-payment notice-span-sepa-payment">
          A noter que l&apos;inscription ne sera pas prise en compte tant que vous n’aurez pas accepté les conditions
          générales et cliqué sur le bouton de confirmation ci dessous
        </div>
        <div className="content-order-sepa-payment privacy-policy-sepa-payment">
          <div>
            Vos données personnelles seront utilisées pour le traitement de votre commande, vous accompagner au cours de
            votre visite du site web, et pour d’autres raisons décrites dans notre
            <Link target="_blank" to="/privacypolicy">
              politique de confidentialité
            </Link>
            .
          </div>
        </div>
        <div className="general-terms-container-sepa-element">
          <Checkbox
            checked={checked}
            onChange={handleCheckBox}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <div>
            <div className={checkBoxErrorMessage ? 'error-checkbox-sepa-element' : ''}>J’ai lu et j’accepte les </div>
            <div className={checkBoxErrorMessage ? 'error-checkbox-sepa-element' : ''}>
              <Link target="_blank" to="/salesterms">
                conditions générales {checkBoxErrorMessage ? '!' : ''}
              </Link>
            </div>
          </div>
        </div>
        {checkBoxErrorMessage ? (
          <div className="container-alert-sepa-element">
            <Alert severity="error">{checkBoxErrorMessage}</Alert>
          </div>
        ) : (
          ''
        )}
        {errorMessage ? (
          <div className="container-alert-sepa-element">
            <Alert severity="warning">{errorMessage}</Alert>
          </div>
        ) : (
          ''
        )}
        <div className="container-button-sepa-element">
          {loading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Confirmation d&apos;inscription
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
