/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { UserInfo } from '../../reducers/app';
import { getUserInfo, updateUserInfo } from '../../firestore/user';
import { RootReducer } from '../../store';
import './OrderUserAddress.css';
import { User } from '../../types/users';
import { InputValues } from '../Updating-User-Infos/UpdatingUserInfos';

interface ActionType {
  handleNext: () => void;
}

export default function OrderUserAddress({ handleNext }: ActionType) {
  const userInfos = useSelector<RootReducer, UserInfo | null>((state) => state.app.user);
  const [loading, setLoading] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [inputValues, setInputValues] = useState<Array<InputValues>>([
    {
      field: 'lastName',
      label: 'Nom*',
      value: userInfos?.firstName ? userInfos?.firstName : '',
      defaultValue: userInfos?.firstName,
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'firstName',
      label: 'Prénom*',
      value: userInfos?.lastName ? userInfos?.lastName : '',
      defaultValue: userInfos?.lastName,
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'profession',
      label: 'Profession*',
      value: '' /* userInfos?.lastName ? userInfos?.lastName : '' */,
      defaultValue: '',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'birthDate',
      label: 'Date de naissance',
      type: 'date',
      defaultValue: '2021-01-01',
      error: false,
      value: '',
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'birthPlace',
      label: 'Lieu de naissance*',
      value: '' /* userInfos?.lastName ? userInfos?.lastName : '' */,
      defaultValue: '',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'country',
      label: 'Pays',
      value: 'France',
      defaultValue: 'France',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'address',
      label: 'Adresse*',
      value: userInfos?.address ? userInfos.address : '',
      defaultValue: userInfos?.address,
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'compAddress',
      label: "Complément d'adresse",
      value: userInfos?.compAddress ? userInfos.compAddress : '',
      defaultValue: userInfos?.compAddress,
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: false,
    },
    {
      field: 'zipCode',
      label: 'Code Postal*',
      value: userInfos?.zipCode ? userInfos.zipCode : '',
      defaultValue: userInfos?.zipCode,
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'city',
      label: 'Ville*',
      value: userInfos?.city ? userInfos.city : '',
      defaultValue: userInfos?.city,
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'phone',
      label: 'Téléphone*',
      value: userInfos?.phone ? userInfos?.phone : '',
      defaultValue: userInfos?.phone,
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
  ]);

  const changeHandler = (e: { target: { name: string; value: string } }) => {
    const index = inputValues.findIndex((a) => a.field === e.target.name);
    inputValues[index].value = e.target.value;
    setInputValues(inputValues);
  };

  const submitValues = async () => {
    const newAllValues = inputValues.map((value) => {
      if (!value.value && value.required === true) {
        setErrorMessage('Veuillez renseigner tout les champs');
        value.error = true;
      } else {
        value.error = false;
      }
      return value;
    });

    setInputValues(newAllValues);
    if (newAllValues.some((v) => v.error)) {
      setErrorMessage('Veuillez renseigner tout les champs obligatoire');
    } else if (userInfos?.uId) {
      const userData: User = {
        lastName: inputValues[0].value || userInfos?.firstName,
        firstName: inputValues[1].value || userInfos?.lastName,
        profession: inputValues[2].value || userInfos?.profession,
        birthDate: inputValues[3].value || userInfos?.birthPlace,
        birthPlace: inputValues[4].value || userInfos?.birthPlace,
        country: inputValues[5].value || 'France',
        address: inputValues[6].value || userInfos?.address,
        compAddress: inputValues[7].value || userInfos?.compAddress || '',
        zipCode: inputValues[8].value || userInfos?.zipCode,
        city: inputValues[9].value || userInfos?.city,
        phone: inputValues[10].value || userInfos?.phone,
      };
      setLoading(true);
      await updateUserInfo(userInfos?.uId, userData);
      await getUserInfo(userInfos?.uId);
      handleNext();
      setLoading(false);
    }
  };

  return (
    <div className="main-container-order-address">
      {loading ? (
        <CircularProgress />
      ) : (
        <div className="sub-container-order-address">
          <div className="main-title-order-address">Informations d&apos;inscription</div>
          {inputValues.map((value) => (
            <div className="container-firstname-lastname-order-address" key={value.field}>
              <TextField
                defaultValue={value.defaultValue}
                error={value.error}
                key={value.field}
                name={value.field}
                label={value.label}
                helperText={value.error ? value.errorMessage : ''}
                fullWidth
                variant="outlined"
                type={value.type ? value.type : ''}
                onChange={changeHandler}
                disabled={value.disable}
              />
            </div>
          ))}
          <div className="container-mail-address-order-address">
            <TextField
              defaultValue={userInfos?.email}
              label="E-mail"
              helperText="Pour modifier votre adresse mail, veuillez vous rendre dans la rubrique détails du compte"
              fullWidth
              variant="outlined"
              onChange={changeHandler}
              disabled
            />
          </div>

          {errorMessage ? (
            <div className="container-alert-user-address">
              <Alert severity="error">{errorMessage}</Alert>
            </div>
          ) : (
            ''
          )}
          <div className="next-buttons-container-order-address">
            <Button variant="contained" color="primary" onClick={submitValues}>
              Suivant
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
