/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import './SignIn.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { fire } from '../../config/fire';
import { createUserToDb } from '../../firestore/user';
import { sendWelcomEmail } from '../../Email/email';

interface InputValues {
  error: boolean;
  value: string;
  label: string;
  errorMessage: string;
  field: string;
  type: string;
}

export default function SignIn() {
  /*  REGISTER HANDLING  */

  const [registerValues, setRegisterValues] = useState<Array<InputValues>>([
    {
      label: 'E-mail*',
      field: 'email',
      error: false,
      errorMessage: 'Ce champ est obligatoire',
      value: '',
      type: '',
    },
    {
      label: 'Mot de passe*',
      field: 'password',
      error: false,
      errorMessage: 'Ce champ est obligatoire',
      value: '',
      type: 'password',
    },
    {
      label: 'Confirmez votre mot de passe*',
      field: 'confirmPassword',
      error: false,
      errorMessage: 'Ce champ est obligatoire',
      value: '',
      type: 'password',
    },
  ]);

  let [errorRegister] = useState('');

  const inputRegisterHandler = (e: { target: { name: string; value: string } }) => {
    const index = registerValues.findIndex((a) => a.field === e.target.name);
    registerValues[index].value = e.target.value;
    setRegisterValues(registerValues);
  };

  const createUser = async () => {
    const email = registerValues[0].value;
    const password = registerValues[1].value;
    const confirmPassword = registerValues[2].value;
    if (password === confirmPassword && email) {
      try {
        const user = await fire.auth().createUserWithEmailAndPassword(email, password);
        if (user.user?.uid) {
          sendWelcomEmail(user.user?.uid);
        }
      } catch (error: any) {
        errorRegister = error.code;
        if (errorRegister === 'auth/invalid-email') {
          registerValues[0].error = true;
          registerValues[0].errorMessage = "L'adresse e-mail est mal formatée";
          setRegisterValues(registerValues);
        } else if (errorRegister === 'auth/weak-password') {
          registerValues[2].error = true;
          registerValues[2].errorMessage = 'Le mot de passe doit faire plus de six caractères';
          registerValues[1].error = true;
          registerValues[1].errorMessage = '';
          setRegisterValues(registerValues);
        }
      }
    } else {
      registerValues[1].error = true;
      registerValues[1].errorMessage = '';
      registerValues[2].error = true;
      registerValues[2].errorMessage = 'Les mots de passe ne correspondent pas';
    }
  };

  const createUserDataBase = () => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        createUserToDb(user.uid, user.email);
      }
    });
  };

  const handleRegister = async () => {
    const newRegisterValues = registerValues.map((value) => {
      if (!value.value) {
        value.error = true;
      } else {
        value.error = false;
      }
      return value;
    });
    setRegisterValues(newRegisterValues);
    if (newRegisterValues.some((v) => v.error)) {
      console.warn('error');
    } else {
      await createUser();
      createUserDataBase();
    }
  };

  return (
    <div className="main-container-signin">
      <div className="container-signin">
        <h3>formulaire d&apos;inscription</h3>
        {registerValues.map((registerValue) => (
          <div className="inputs-signin" key={registerValue.label}>
            <TextField
              label={registerValue.label}
              error={registerValue.error}
              name={registerValue.field}
              helperText={registerValue.error ? registerValue.errorMessage : ''}
              onChange={inputRegisterHandler}
              variant="outlined"
              className="textfield-signin"
              type={registerValue.type}
            />
          </div>
        ))}
        <div className="container-button-signin">
          <Button variant="contained" color="primary" onClick={handleRegister}>
            s&apos;inscrire
          </Button>
        </div>
      </div>
    </div>
  );
}
