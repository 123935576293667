/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MenuItemDetail {
  pathName?: string;
  title: string;
}

interface MenuItem extends MenuItemDetail {
  children: Array<MenuItemDetail>;
}

export interface UserInfo {
  email?: string;
  birthDate: string;
  uId?: string;
  firstName?: string;
  admin?: boolean;
  lastName?: string;
  birthPlace?: string;
  phone?: string;
  address?: string;
  compAddress?: string;
  city?: string;
  profession?: string;
  zipCode?: string;
  user?: object;
}

export interface AppState {
  currentPage: string;
  drawerState: boolean;
  user: UserInfo | null;
  isAdmin: boolean;
  menu: Array<MenuItem>;
}

const appState: AppState = {
  currentPage: '',
  drawerState: false,
  user: null,
  isAdmin: false,
  menu: [
    {
      title: 'accueil',
      pathName: '',
      children: [],
    },
    {
      title: 'formations',
      children: [
        {
          title: 'calendrier des formations',
          pathName: 'formations',
        },
        {
          title: 'comment réserver ?',
          pathName: 'howtoreserve',
        },
        {
          title: 'prise en charge',
          pathName: 'support',
        },
      ],
    },
    {
      title: 'formateurs',
      pathName: 'trainers',
      children: [],
    },
    {
      title: "l'iffco",
      children: [
        {
          title: "présentation de l'iffco",
          pathName: 'presentation',
        },
        { title: 'photos', pathName: 'pictures' },
      ],
    },
    {
      title: 'Partenaires',
      pathName: 'partnersPage',
      children: [],
    },
    {
      title: 'faq',
      pathName: 'faq',
      children: [],
    },
    {
      title: 'contact',
      pathName: 'contact',
      children: [],
    },
    {
      title: 'espace client',
      children: [
        { title: 'mon compte', pathName: 'myspace' },
        { title: 'déconnexion', pathName: '' },
      ],
    },
  ],
};

export const app = createSlice({
  name: 'app',
  initialState: appState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<AppState['currentPage']>) => {
      state.currentPage = action.payload;
    },
    setDrawerState: (state, action: PayloadAction<AppState['drawerState']>) => {
      state.drawerState = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<AppState['user']>) => {
      state.user = action.payload;
    },
    setIsAdmin: (state, action: PayloadAction<AppState['isAdmin']>) => {
      state.isAdmin = action.payload;
    },
  },
});

export const { setCurrentPage, setDrawerState, setUserInfo, setIsAdmin } = app.actions;
