import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import React from 'react';
import { Link } from 'react-router-dom';
import './BannerContact.css';

export default function BannerContact() {
  return (
    <div className="main-container-banner-contact">
      <div className="svg-icon-banner-contact">
        <FontAwesomeIcon icon={faComment} />
      </div>
      <div className="main-content-banner-contact">
        <div>
          <h3>Nous sommes à votre écoute</h3>
          <span role="img" aria-label="smiley">
            🙂
          </span>
        </div>
        <h4>
          Nous vous invitons également à nous contacter si vous n’arrivez pas à obtenir les informations que vous
          recherchez.
        </h4>
        <div>
          <Link to="/contact">
            <Button variant="contained" color="primary">
              Contactez-nous
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
