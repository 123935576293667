/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-multi-assign */
/* eslint-disable react/no-danger */
import { PhotoCamera } from '@material-ui/icons';
import React, { ChangeEvent, useState, useEffect } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import makeAnimated from 'react-select/animated';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './AdminAddTrainer.css';
import CreatableSelect from 'react-select/creatable';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { OptionTypeBase, OptionsType } from 'react-select';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Navigate } from 'react-router-dom';
import { fire } from '../../config/fire';
import randomId from '../../utils/utils';
import { addNewProfession, addTrainer, getAllProfessions } from '../../firestore/trainers';
import { Profession, Trainer } from '../../types/trainers';
import { ImageAndPdf, SelectValue } from '../../types/trainings';
import { addFileStorage } from '../../storage/storage';

interface InputValues {
  error: boolean;
  value: string;
  label: string;
  errorMessage: string;
  field: string;
  helperText?: string;
}

export default function AdminAddTrainer() {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [uploadPending, setUploadPending] = useState<Boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState<Boolean>(false);
  const [validationMessage, setValidationMessage] = useState<Boolean>(false);
  const [selectedProfessions, setSelectedProfessions] = useState<Array<SelectValue>>([]);
  const [newImage, setNewImage] = useState<string>('');
  const [selectProfessionFromStore, setSelectProfessionFromStore] = useState<Array<SelectValue>>([]);
  const [imageToUpload, setImageToUpload] = useState<File>();
  const [redirect, setRedirect] = useState<Boolean>(false);
  const [inputValues, setInputValues] = useState<Array<InputValues>>([
    {
      label: 'Nom & Prénom',
      field: 'name',
      error: false,
      errorMessage: 'Ce champs est obligatoire',
      value: '',
      helperText: 'Format: Prénom NOM',
    },
  ]);

  // INPUTS HANDLER

  const inputValuesHandler = (e: { target: { name: string; value: string } }) => {
    const index = inputValues.findIndex((a) => a.field === e.target.name);
    inputValues[index].value = e.target.value;
    setInputValues(inputValues);
  };

  // SELECT PROFESSION HANDLING

  const animatedComponents = makeAnimated();

  const fetchAllProfessions = async () => {
    const allProfessions = await getAllProfessions();
    // selectedProfessions
    if (allProfessions) {
      const newListSelectProfession: Array<SelectValue> = allProfessions.map((profession) => ({
        value: profession.id,
        label: profession.name,
      }));
      setSelectProfessionFromStore(newListSelectProfession);
    }
  };

  useEffect(() => {
    fetchAllProfessions();
  }, []);

  const handlingSelect = (value: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
    if (value) {
      const newSelectedProfession: Array<SelectValue> = value.map((v: OptionTypeBase) => ({
        value: v.value,
        label: v.label,
      }));
      setSelectedProfessions(newSelectedProfession);
    }
  };

  // CHECK IF PROFESSION IS NEW

  const checkIfProfessionIsNew = () => {
    const existingIds = selectProfessionFromStore.map((p) => p.value);
    return selectedProfessions.filter((p) => existingIds.indexOf(p.value) === -1);
  };

  // CREATE SUBMITED OBJECT

  const getProfessionsWithFirebaseData = async () => {
    const allProfessions = await getAllProfessions();
    const noExistingProfessions = selectedProfessions.filter((p) => {
      return allProfessions.findIndex((ap) => ap.id === p.value) === -1;
    });
    const existingProfessions = selectedProfessions
      .filter((p) => {
        return allProfessions.findIndex((ap) => ap.id === p.value) !== -1;
      })
      .map((p) => ({ name: p.label, id: p.value } as Profession));

    const noExistingProfessionsWithData = noExistingProfessions.map((p) => {
      const a = allProfessions!.find((ap) => ap.name === p.value);
      return a as Profession;
    });

    return [...existingProfessions, ...noExistingProfessionsWithData];
  };

  // IMAGE HANDLING
  const addImageStorage = async () => {
    const generatedId = randomId();
    const path = 'trainers-images';
    if (imageToUpload) {
      const imageName = imageToUpload.name;
      setUploadPending(true);
      const uploadImage = await addFileStorage(generatedId, imageToUpload, path, imageName);
      setUploadPending(false);
      return uploadImage;
    }
    return { url: '', id: '', fileName: '' };
  };

  const onChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      setImageToUpload(event.target.files[0]);
      setNewImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  // DESCRIPTION / EDITOR

  const convertContentToHTML = () => {
    const currentContentAsHTML = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(currentContentAsHTML);
    return markup;
  };

  const handleEditorChange = (state: React.SetStateAction<EditorState>) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const submitAddTrainer = async () => {
    if (inputValues[0].value && imageToUpload) {
      // add new professions
      const newProfessions = checkIfProfessionIsNew();
      await Promise.all(newProfessions.map((v) => addNewProfession(v.label)));

      // add image to the store
      const image: ImageAndPdf = await addImageStorage();

      // get professions
      const professions = await getProfessionsWithFirebaseData();

      // get converted description
      const convertedDescription = convertContentToHTML();

      if (image) {
        const trainer: Trainer = {
          professionIds: professions.map((p) => p.id),
          image,
          description: convertedDescription,
          name: inputValues[0].value,
        };
        await addTrainer(trainer);
        setValidationMessage(true);
      }
      setError(false);
      setRedirect(true);
    } else {
      setError(true);
    }
  };

  // SNACKBAR

  const handleCloseSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  return (
    <div className="main-container-add-trainer">
      <div className="sub-container-add-trainer">
        <div className="info-add-trainer">
          <h4>Informations :</h4>
          {inputValues.map((inputValue) => (
            <div key={inputValue.label} className="inputs-add-trainer">
              <TextField
                className="textfield-login"
                label={inputValue.label}
                error={inputValue.error}
                name={inputValue.field}
                helperText={inputValue.helperText}
                onChange={inputValuesHandler}
                variant="outlined"
              />
            </div>
          ))}
        </div>
        <div className="select-profession-add-trainer">
          <CreatableSelect
            placeholder="Professions"
            isMulti
            options={selectProfessionFromStore}
            components={animatedComponents}
            onChange={handlingSelect}
          />
        </div>
        <div className="add-image-add-trainer">
          <h4>Photo :</h4>
          {newImage ? (
            <>
              <label htmlFor="icon-button-file">
                <input accept="image/*" id="icon-button-file" type="file" onChange={onChangeImage} />
                <img src={newImage} alt="trainers" />
              </label>
            </>
          ) : (
            <div className="drag-drop-add-trainer">
              <label htmlFor="icon-button-file">
                <input accept="image/*" id="icon-button-file" type="file" onChange={onChangeImage} />
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <PhotoCamera />
                </IconButton>
              </label>
            </div>
          )}
        </div>
        <div className="add-description-add-trainer">
          <h4>description :</h4>
          <Editor
            editorState={editorState as any}
            onEditorStateChange={handleEditorChange as any}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
        </div>
        {error ? <Alert severity="error">Veuillez entrer tout les champs avant de continuer</Alert> : null}
        {validationMessage ? <Alert severity="success">Le formateur à bien était ajouté</Alert> : null}
        <div className="submit-button-container-add-trainer">
          {uploadPending ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" color="primary" onClick={submitAddTrainer}>
              Soumettre
            </Button>
          )}
        </div>
        {redirect ? <Navigate to="/admin/trainers" /> : null}
      </div>
      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity="success">
          La profession à bien était ajoutée
        </Alert>
      </Snackbar>
    </div>
  );
}
