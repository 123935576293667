/* eslint-disable @typescript-eslint/no-unused-vars */
import { faChevronDown, faUserMd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import userImage from '../../images/user-image.png';
import './trainers.css';
import { FetchedTrainer } from '../../types/trainers';
import { getAllTrainers } from '../../firestore/trainers';
import BreadCrumbs from '../../components/Bread-Crumbs/BreadCrumbs';

interface Content {
  image: string;
  name: string;
  qualification: string;
  description: string;
  open: boolean;
}

export default function Trainers() {
  const [allTrainers, setAllTrainers] = useState<Array<FetchedTrainer>>([]);

  const breadCrumbs = [
    {
      link: '/',
      name: 'Accueil',
    },
    {
      link: '/trainers',
      name: 'Formateurs',
    },
  ];

  const setTrainersFromFirebase = async () => {
    const trainersFromFirebase = await getAllTrainers();
    if (trainersFromFirebase) {
      setAllTrainers(trainersFromFirebase);
    }
  };

  useEffect(() => {
    setTrainersFromFirebase();
    document.title = `IFFCO - Formateurs`;
  }, []);

  return (
    <div className="main-container-trainers">
      <BreadCrumbs breadCrumb={breadCrumbs} />
      <div className="container-banner-trainers">
        <FontAwesomeIcon icon={faUserMd} />
        <h2>Formateurs</h2>
      </div>
      <div className="subtitle-banner-trainers">
        <h4>Retrouvez ci-dessous tout vos formateurs</h4>
      </div>
      <div className="sub-container-trainer">
        {allTrainers.map((trainer) => (
          <div className="container-card-trainer" key={trainer.name}>
            <Link to={`/trainerview/${trainer.id}`}>
              {trainer.image.url ? <img src={trainer.image.url} alt="formateur" /> : <img src={userImage} alt="user" />}
            </Link>
            <div className="container-content-card-trainer">
              <div>
                <Link to={`/trainerview/${trainer.id}`}>
                  <h3>{trainer.name}</h3>
                </Link>
              </div>
            </div>
            <Link to={`/trainerview/${trainer.id}`}>
              <div className="redirect-button-span-card-trainer">En savoir plus</div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
