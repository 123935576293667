import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { fire } from '../../config/fire';
import { getUserCredentials } from '../../firebase/firebase';
import SetUserAccountDetails from '../set-user-account-details/SetUserAccountDetails';
import './UserDetailsGetCredentials.css';

interface ReAuthInput {
  error: boolean;
  value: string;
  label: string;
  errorMessage: string;
  field: string;
}

export default function Test() {
  const user = fire.auth().currentUser;
  const [userIsReAuth, setUserIsReAuth] = useState(false);
  const [reAuthInput, setReAuthInput] = useState<ReAuthInput>({
    label: 'Mot de passe',
    field: 'currentPassword',
    error: false,
    errorMessage: '',
    value: '',
  });

  const inputReAuthHandler = (e: { target: { name: string; value: string } }) => {
    reAuthInput.value = e.target.value;
    setReAuthInput(reAuthInput);
  };

  const handleReAuth = async () => {
    if (reAuthInput.value) {
      try {
        await getUserCredentials(user?.email, reAuthInput.value);
        setUserIsReAuth(true);
      } catch (e) {
        if (e.code === 'auth/wrong-password') {
          const authClone = { ...reAuthInput };
          authClone.error = true;
          authClone.errorMessage = 'Mauvais mot de passe';
          setReAuthInput(authClone);
        } else if (e.code === 'auth/too many request') {
          const authClone = { ...reAuthInput };
          authClone.error = true;
          authClone.errorMessage = 'Trop de tentatives veuillez réessayer plus tard';
          setReAuthInput(authClone);
        }
      }
    } else {
      const authClone = { ...reAuthInput };
      authClone.error = true;
      authClone.errorMessage = 'Veuillez renseigner ce champ';
      setReAuthInput(authClone);
    }
  };

  return (
    <>
      {userIsReAuth ? (
        <SetUserAccountDetails />
      ) : (
        <div className="main-container-user-credential">
          <div className="sub-container-user-credential">
            <h3>Par sécurité veuillez rentrer de nouveau votre mot de passe</h3>
            <div className="content-user-credential">
              <TextField
                id={reAuthInput.field}
                label={reAuthInput.label}
                error={reAuthInput.error}
                helperText={reAuthInput.error ? reAuthInput.errorMessage : ''}
                onChange={inputReAuthHandler}
                type="password"
                variant="outlined"
              />
            </div>
            <div className="content-user-credential">
              <Button onClick={handleReAuth} variant="contained" color="primary">
                Accéder à la section
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
