/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useState, useEffect } from 'react';
import Select, { OptionsType, OptionTypeBase } from 'react-select';
import makeAnimated from 'react-select/animated';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import './AdminAddTraining.css';
import DateFnsUtils from '@date-io/date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { EditorState, convertToRaw } from 'draft-js';
import { Navigate } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
import { getAllProfessions, getAllTrainers } from '../../firestore/trainers';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import randomId from '../../utils/utils';
import { addFileStorage, deleteFileStorage, FileToStorage } from '../../storage/storage';
import { Dates, ImageAndPdf, SelectValue, Training } from '../../types/trainings';
import { addTraining, createFile } from '../../firestore/trainings';

interface InputValues {
  error: boolean;
  value: string;
  label: string;
  errorMessage: string;
  field: string;
  helperText?: string;
}

export default function AdminAddTraining() {
  // IMAGE STATES
  const [imageToUpload, setImageToUpload] = useState<File>();
  const [newImage, setNewImage] = useState<string>('');
  const [uploadPending, setUploadPending] = useState<Boolean>(false);

  // PDF STATES
  const [pdfToUpload, setPdfToUpload] = useState<File>();
  const [newPdf, setNewPdf] = useState<string>('');

  // FILES STATE
  const [uploadVariousFilePending, setUploadVariousFilePending] = useState<Boolean>(false);
  const [files, setFiles] = useState<Array<FileToStorage>>([]);

  // ERRORS
  const [error, setError] = useState<Boolean>(false);

  // VALIDATION MESSAGE
  const [validationMessage, setValidationMessage] = useState<Boolean>(false);

  // EDITOR WYSIWYG
  const [targetsState, setTargetsState] = useState(() => EditorState.createEmpty());
  const [contractState, setContractState] = useState(() => EditorState.createEmpty());
  const [emailState, setEmailState] = useState(() => EditorState.createEmpty());
  const [checkState, setCheckState] = useState(() => EditorState.createEmpty());

  // LOADING STATE
  const [loading, setLoading] = useState<Boolean>(false);
  const [redirect, setRedirect] = useState<Boolean>(false);

  // TEXT AREA STATE
  const [trainingDescription, setTrainingDescription] = useState<string>('');

  // SELECT TRAINER & PROFESSION
  const [selectTrainer, setSelectTrainer] = useState<Array<SelectValue>>([]);
  const [selectProfession, setSelectProfession] = useState<Array<SelectValue>>([]);
  const [selectedTrainers, setSelectedTrainers] = useState<Array<string>>([]);
  const [selectedProfessions, setSelectedProfessions] = useState<Array<string>>([]);

  // DATES
  const now = new Date();
  const [dates, setDates] = useState<Array<Dates>>([{ startDate: new Date(now), endDate: new Date(now), id: 0 }]);

  const [inputValues, setInputValues] = useState<Array<InputValues>>([
    {
      label: 'Titre de la formation',
      field: 'trainingtitle',
      error: false,
      errorMessage: 'Ce champs est obligatoire',
      value: '',
    },
    {
      label: 'Sous-titre',
      field: 'subtitle',
      error: false,
      errorMessage: 'Ce champs est obligatoire',
      value: '',
    },
    {
      label: 'Ville',
      field: 'location',
      error: false,
      errorMessage: 'Ce champs est obligatoire',
      value: '',
    },
    {
      label: 'Prix',
      field: 'price',
      error: false,
      errorMessage: 'Ce champs est obligatoire',
      value: '',
    },
    {
      label: 'Public',
      field: 'public',
      error: false,
      errorMessage: 'Ce champs est obligatoire',
      value: '',
    },
  ]);

  const animatedComponents = makeAnimated();

  // SELECT SETTINGS INFOS TRAINER / PROFESSIONS

  const getTrainers = async () => {
    setLoading(true);
    const trainersFromFirebase = await getAllTrainers();
    const professionFromFirebase = await getAllProfessions();
    if (trainersFromFirebase) {
      const newSelectTrainer: Array<SelectValue> = [];
      const newSelectProfession: Array<SelectValue> = [];
      trainersFromFirebase.forEach((trainer) =>
        newSelectTrainer.push({
          value: trainer.id,
          label: trainer.name,
        }),
      );
      professionFromFirebase.forEach((profession) =>
        newSelectProfession.push({
          value: profession.id,
          label: profession.name,
        }),
      );
      setSelectTrainer(newSelectTrainer);
      setSelectProfession(newSelectProfession);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTrainers();
  }, []);

  // SELECT HANDLING TRAINER / PROFESSION

  const handlingSelectTrainer = (value: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
    if (value) {
      const trainerIds = value.map((v: OptionTypeBase) => v.value);
      setSelectedTrainers(trainerIds);
    }
    return '';
  };

  const handlingSelectProfession = (value: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
    if (value) {
      const professionIds = value.map((v: OptionTypeBase) => v.value);
      setSelectedProfessions(professionIds);
    }
    return '';
  };

  // DATE HANDLING

  const handleCloseDateSelect = () => {
    return '';
  };

  const handleDateChange = (e: Date | null, date: number, type: string) => {
    const newDates = [...dates];
    if (type === 'startDate') {
      newDates.filter((d) => d.id === date)[0].startDate = e || new Date();
    } else {
      newDates.filter((d) => d.id === date)[0].endDate = e || new Date();
    }
    setDates(newDates);
  };

  // INPUTS HANDLER

  const inputValuesHandler = (e: { target: { name: string; value: string } }) => {
    const index = inputValues.findIndex((a) => a.field === e.target.name);
    inputValues[index].value = e.target.value;
    setInputValues(inputValues);
  };

  // IMAGE HANDLING

  const addImageStorage = async () => {
    const generatedId = randomId();
    const path = 'trainings-images';
    if (imageToUpload) {
      const imageName = imageToUpload.name;
      setUploadPending(true);
      const uploadImage = await addFileStorage(generatedId, imageToUpload, path, imageName);
      setUploadPending(false);
      return uploadImage;
    }
    return { url: '', id: '', fileName: '' };
  };

  const onChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      setImageToUpload(event.target.files[0]);
      setNewImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  // FILES PDF HANDLING

  const addPdfFilesStorage = async () => {
    const generatedId = randomId();
    const pathName = 'trainings-programs';
    if (pdfToUpload) {
      const pdfName = pdfToUpload.name;
      setUploadPending(true);
      const uploadPdf = await addFileStorage(generatedId, pdfToUpload, pathName, pdfName);
      setUploadPending(false);
      return uploadPdf;
    }
    return { url: '', id: '', fileName: '' };
  };

  const onChangePdf = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      setPdfToUpload(event.target.files[0]);
      setNewPdf(URL.createObjectURL(event.target.files[0]));
    }
  };

  // FILES HANDLING
  const addFilesStorage = async (fileToUpload: File) => {
    const generatedId = randomId();
    if (fileToUpload) {
      setUploadVariousFilePending(true);
      const fileToStorage = await addFileStorage(generatedId, fileToUpload, '/trainings-files', fileToUpload.name);
      const newFiles = JSON.parse(JSON.stringify(files));
      newFiles.push(fileToStorage);
      setFiles(newFiles);
      setUploadVariousFilePending(false);
    }
    return { url: '', fileName: '' };
  };

  const onChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      const file = await addFilesStorage(event.target.files[0]);
    }
  };

  // TEXT AREA HANDLING
  const handlingTextArea = (e: { target: { value: string } }) => {
    const description = e.target.value;
    setTrainingDescription(description);
  };

  // WYSIWYG TARGETS FUNCTIONS

  const convertWysiwygToHTML = (contentToConvert: EditorState) => {
    const currentContentAsHTML = convertToRaw(contentToConvert.getCurrentContent());
    const markup = draftToHtml(currentContentAsHTML);
    return markup;
  };

  const handleTargetsChange = (state: React.SetStateAction<EditorState>) => {
    setTargetsState(state);
    convertWysiwygToHTML(targetsState);
  };

  // WYSIWYG CONTRACT FUNCTIONS

  const handleContractChange = (state: React.SetStateAction<EditorState>) => {
    setContractState(state);
    convertWysiwygToHTML(contractState);
  };

  // WYSIWYG EMAIL FUNCTIONS

  const handleEmailChange = (state: React.SetStateAction<EditorState>) => {
    setEmailState(state);
    convertWysiwygToHTML(emailState);
  };

  const handleCheckChange = (state: React.SetStateAction<EditorState>) => {
    setCheckState(state);
    convertWysiwygToHTML(checkState);
  };

  // HANDLE SUBMIT
  const handleSubmit = async () => {
    if (
      inputValues[0].value &&
      inputValues[2].value &&
      inputValues[3].value &&
      imageToUpload &&
      pdfToUpload &&
      selectedTrainers &&
      selectedProfessions &&
      dates &&
      trainingDescription
    ) {
      // Add Image to the storage
      setUploadPending(true);
      const image: ImageAndPdf = await addImageStorage();

      // Add Pdf file to the storage
      const pdf: ImageAndPdf = await addPdfFilesStorage();
      setUploadPending(false);

      const targets = convertWysiwygToHTML(targetsState);

      // GET CONVERTED CONTRACT
      const contract = convertWysiwygToHTML(contractState);

      // GET CONVERTED EMAIL
      const email = convertWysiwygToHTML(emailState);

      // GET CONVERTED CHECKTEMPLATE
      const checkPaymentTemplate = convertWysiwygToHTML(checkState);

      // Creating submited object

      if (image && pdf) {
        const training: Training = {
          title: inputValues[0].value,
          subtitle: inputValues[1].value,
          location: inputValues[2].value,
          price: inputValues[3].value,
          peopleConcerned: inputValues[4].value,
          description: trainingDescription,
          targets,
          contract,
          email,
          checkPaymentTemplate,
          trainersProfession: selectedProfessions,
          trainers: selectedTrainers,
          image,
          pdf,
          dates,
        };
        const newTraining = await addTraining(training);
        if (newTraining) {
          await Promise.all(files.map((file) => createFile(file.fileName, file.url, newTraining.id, file.id)));
        }
        setValidationMessage(true);
      }
      setError(false);
      setRedirect(true);
    } else {
      setError(true);
    }
  };

  const addDate = () => {
    const newId = dates.reduce((acc, curr) => {
      return acc + curr.id;
    }, 1);
    const newDate = [{ startDate: new Date(now), endDate: new Date(now), id: newId }];
    const newDates = dates.concat(newDate);
    setDates(newDates);
  };

  const deleteDate = (id: number) => {
    const datesShadow = [...dates];
    const newDates = datesShadow.filter((date) => date.id !== id);
    setDates(newDates);
  };

  const deleteTheFile = async (id: string) => {
    const filesShadowCopy = [...files];
    await deleteFileStorage('trainings-files', id);
    const fileToDelete = filesShadowCopy.findIndex((file) => file.id === id);
    filesShadowCopy.splice(fileToDelete, 1);
    setFiles(filesShadowCopy);
  };

  const renderFiles = () => {
    return files.map((file) => (
      <div key={file.id} className="content-file-table-add-training">
        {file.fileName}
        <FontAwesomeIcon icon={faTimesCircle} onClick={() => deleteTheFile(file.id)} />
      </div>
    ));
  };

  useEffect(() => {
    renderFiles();
  }, [files]);

  return (
    <div className="main-container-add-training">
      <div className="sub-container-add-training">
        {loading ? (
          <div className="loading-container-admin-trainers">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="info-add-training">
              <h4>Informations :</h4>
              <TextField
                className="textfield-login"
                label={inputValues[0].label}
                error={inputValues[0].error}
                name={inputValues[0].field}
                helperText={inputValues[0].helperText}
                onChange={inputValuesHandler}
                variant="outlined"
              />
              <div>
                <TextField
                  className="textfield-login"
                  label={inputValues[1].label}
                  error={inputValues[1].error}
                  name={inputValues[1].field}
                  helperText={inputValues[1].helperText}
                  onChange={inputValuesHandler}
                  variant="outlined"
                />
              </div>
              <div>
                <TextField
                  className="textfield-login"
                  label={inputValues[4].label}
                  error={inputValues[4].error}
                  name={inputValues[4].field}
                  helperText={inputValues[4].helperText}
                  onChange={inputValuesHandler}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="select-button-add-training">
              <Select
                options={selectTrainer}
                components={animatedComponents}
                onChange={handlingSelectTrainer}
                placeholder="Formateurs"
                isMulti
              />
            </div>
            <div className="select-button-add-training">
              <Select
                options={selectProfession}
                components={animatedComponents}
                onChange={handlingSelectProfession}
                placeholder="Professions"
                isMulti
              />
            </div>
            <div className="container-input-middle-add-training">
              <div>
                <TextField
                  className="textfield-login"
                  label={inputValues[2].label}
                  error={inputValues[2].error}
                  name={inputValues[2].field}
                  helperText={inputValues[2].helperText}
                  onChange={inputValuesHandler}
                  variant="outlined"
                />
              </div>
              <div>
                <TextField
                  className="textfield-login"
                  label={inputValues[3].label}
                  error={inputValues[3].error}
                  name={inputValues[3].field}
                  helperText={inputValues[3].helperText}
                  onChange={inputValuesHandler}
                  variant="outlined"
                />
              </div>
            </div>
            {dates.map((date) => {
              return (
                <div key={date.id} className="container-date-picker-add-training">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="start-date-picker"
                      label="Date de début"
                      value={date.startDate}
                      onClose={handleCloseDateSelect}
                      onChange={(e) => {
                        handleDateChange(e, date.id, 'startDate');
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <div className="admin-add-training-delete-date-button">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="end-date-picker"
                        label="Date de fin"
                        value={date.endDate}
                        onChange={(e) => {
                          handleDateChange(e, date.id, 'endDate');
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <Tooltip title="Supprimer la date">
                      <IconButton onClick={(e) => deleteDate(date.id)} aria-label="add" size="medium">
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
            <div id="addButton">
              <Tooltip title="Ajouter un jeu de date">
                <IconButton onClick={addDate} aria-label="add" size="medium">
                  <Add />
                </IconButton>
              </Tooltip>
            </div>
            <div className="add-description-add-training">
              <h4>Description formateur :</h4>
              <TextareaAutosize aria-label="minimum height" onChange={handlingTextArea} rowsMin={7} />
            </div>
            <div className="add-targets-edit-training">
              <h4>Objectifs :</h4>
              <Editor
                editorState={targetsState as any}
                onEditorStateChange={handleTargetsChange as any}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
            <div className="add-targets-edit-training">
              <h4>Contrat :</h4>
              <Editor
                editorState={contractState as any}
                onEditorStateChange={handleContractChange as any}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
            <div className="add-targets-edit-training">
              <h4>E-mail :</h4>
              <Editor
                editorState={emailState as any}
                onEditorStateChange={handleEmailChange as any}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
            <div>
              <h4>Chèque :</h4>
              <Editor
                editorState={checkState as any}
                onEditorStateChange={handleCheckChange as any}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
            <div className="add-image-add-traning">
              <h4>Photo :</h4>
              {newImage ? (
                <>
                  <label htmlFor="icon-button-file">
                    <input accept="image/*" id="icon-button-file" type="file" onChange={onChangeImage} />
                    <img src={newImage} alt="trainers" />
                  </label>
                </>
              ) : (
                <div>
                  <div className="button-add-file-add-training">
                    <label htmlFor="icon-button-image">
                      <input accept="image/*" id="icon-button-image" type="file" onChange={onChangeImage} />
                      <Button variant="contained" color="primary" component="span">
                        Télécharger la photo
                      </Button>
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="add-image-add-traning">
              <h4>Programme pdf :</h4>
              {newPdf ? (
                <>
                  <label htmlFor="icon-button-file">
                    <input accept="image/*" id="icon-button-file" type="file" onChange={onChangePdf} />
                    <div className="pdf-name-add-training">{pdfToUpload ? pdfToUpload.name : null}</div>
                  </label>
                </>
              ) : (
                <div>
                  <div className="button-add-file-add-training">
                    <label htmlFor="icon-button-pdf">
                      <input accept="application/pdf" id="icon-button-pdf" type="file" onChange={onChangePdf} />
                      <Button variant="contained" color="primary" component="span">
                        Télécharger le programme
                      </Button>
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="add-files-add-training">
              <div>Ajouter des fichiers :</div>
              <div className="button-add-file-add-training">
                {uploadVariousFilePending ? (
                  <CircularProgress />
                ) : (
                  <>
                    <label htmlFor="add-file">
                      <input accept="application/pdf" id="add-file" type="file" onChange={onChangeFile} />
                      <Button variant="contained" color="primary" component="span">
                        Télécharger des fichiers
                      </Button>
                    </label>
                  </>
                )}
              </div>
              {files.length !== 0 ? <div className="container-files-table-add-training">{renderFiles()} </div> : null}
            </div>
            {error ? <Alert severity="error">Veuillez renseigner tout les champs avant de continuer</Alert> : null}
            {validationMessage ? <Alert severity="success">La formation a bien était ajouté</Alert> : null}
            <div className="submit-button-container-add-training">
              {uploadPending ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Soumettre
                </Button>
              )}
            </div>
            {redirect ? <Navigate to="/admin/trainings" /> : null}
          </>
        )}
      </div>
    </div>
  );
}
