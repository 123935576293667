import React, { useEffect, useState } from 'react';
import './AdminUserView.css';
import { useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { faUser, faHome, faCity, faPhoneAlt, faEnvelope, faKey, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { User } from '../../types/users';
import { getSingleUser } from '../../firestore/user';

export default function AdminUserView() {
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState<Boolean>(false);

  // GET ALL TRAINERS

  const fetchUser = async () => {
    if (userId) {
      setLoading(true);
      const fetchUserStore = await getSingleUser(userId);
      if (fetchUserStore) {
        setUser(fetchUserStore);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div className="main-container-admin-user-view">
      {loading ? (
        <div className="progress-admin-user-view">
          <CircularProgress />
        </div>
      ) : (
        <div className="sub-container-admin-user-view">
          <div className="container-address-admin-user-view">
            <div className="content-container-address-admin-user-view">
              <FontAwesomeIcon icon={faUser} />
              {user?.lastName}
              &nbsp;
              {user?.firstName}
            </div>
            <div className="content-container-address-admin-user-view">
              <FontAwesomeIcon icon={faHome} />
              {user?.address}
            </div>
            {user?.compAddress ? (
              <div className="content-container-address-admin-user-view">
                <FontAwesomeIcon icon={faBuilding} />
                {user?.compAddress}
              </div>
            ) : (
              ''
            )}
            <div className="content-container-address-admin-user-view">
              <FontAwesomeIcon icon={faCity} />
              {user?.zipCode}
              &nbsp;
              {user?.city}
            </div>
            <div className="content-container-address-admin-user-view">
              <FontAwesomeIcon icon={faPhoneAlt} />
              {user?.phone}
            </div>
            <div className="content-container-address-admin-user-view">
              <FontAwesomeIcon icon={faEnvelope} />
              {user?.email}
            </div>
            <div className="content-container-address-admin-user-view">
              <FontAwesomeIcon icon={faKey} />
              {user?.uId}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
