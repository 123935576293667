/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useEffect, useState } from 'react';
import makeAnimated from 'react-select/animated';
import Select, { OptionsType, OptionTypeBase } from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { getAllUsers } from '../../firestore/user';
import { FetchedTraining, SelectValue } from '../../types/trainings';
import { User } from '../../types/users';
import './AdminAddOrder.css';
import { getAllTrainings } from '../../firestore/trainings';
import { getFormatedDate } from '../Admin-Trainings/AdminTrainings';
import { addContractStorage } from '../../storage/storage';
import { Contract } from '../../types/contract';
import { addOrder, checkIfOrderAlreadyExist } from '../../firestore/orders';
import { SubmitedValuesOrder } from '../../types/orders';
import { addContractStore } from '../../firestore/contracts';

export default function AdminAddOrder() {
  // USER
  const [allUsers, setAllUsers] = React.useState<Array<User>>([]);
  const [allEmailUser, setAllEmailUser] = React.useState<Array<SelectValue>>([]);
  const [selectedEmail, setSelectedEmail] = React.useState<OptionTypeBase>();

  // TRAINING
  const [allTraining, setAllTraining] = React.useState<Array<FetchedTraining>>([]);
  const [transformedTrainings, setTransformedTrainings] = React.useState<Array<SelectValue>>([]);
  const [selectedTraining, setSelectedTraining] = React.useState<OptionTypeBase>();

  // PAYMENT STATE
  const [paymentState] = useState<Array<SelectValue>>([
    {
      value: 'confirmed',
      label: 'Confirmé',
    },
    {
      value: 'canceled',
      label: 'Annulé',
    },
    {
      value: 'pending',
      label: 'En attente',
    },
  ]);

  const [selectedPaymentState, setSelectedPaymentState] = React.useState<OptionTypeBase>();

  // PAYMENT METHOD
  const [paymentMethod] = useState<Array<SelectValue>>([
    {
      value: 'creditCard',
      label: 'Carte de crédit',
    },
    {
      value: 'sepa',
      label: 'Virement bancaire',
    },
    {
      value: 'checks',
      label: 'chèque',
    },
  ]);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<OptionTypeBase>();

  // ERRROR MESSAGE
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // VALIDATION MESSAGE
  const [validationMessage, setValidationMessage] = useState<string | null>(null);

  // DATE
  const now = new Date();
  const [startDate, setStartDate] = React.useState<Date | null>(new Date(now));

  // PDF STATES
  const [pdfToUpload, setPdfToUpload] = useState<File>();
  const [newPdf, setNewPdf] = useState<string>('');

  // GET ALL USERS
  const getUsers = async () => {
    const users = (await getAllUsers()) as User[];
    setAllUsers(users);
  };

  // GET ALL TRAININGS
  const getTrainings = async () => {
    const training = (await getAllTrainings()) as FetchedTraining[];
    setAllTraining(training);
  };

  // LOADING
  const [uploadPending, setUploadPending] = useState<Boolean>(false);

  // TRANSFORME USERS INTO AN ARRAY WITH LABEL AND VALUE FOR THE SELECT COMPONENT

  useEffect(() => {
    const userEmail: SelectValue[] = [];
    allUsers.forEach((user) => {
      if (user.email && user.uId) {
        userEmail.push({
          value: user.uId,
          label: `${user.email} ${user.lastName ? '/' : ''} ${user.lastName ? user.lastName : ''} ${
            user.firstName ? user.firstName : ''
          }`,
        });
      }
    });
    setAllEmailUser(userEmail);
  }, [allUsers]);

  // TRANSFORME TRAININGS INTO AN ARRAY WITH LABEL AND VALUE FOR THE SELECT COMPONENT
  useEffect(() => {
    const trainingValue: SelectValue[] = [];
    allTraining.forEach((training) => {
      if (training.title && training.trainingId && training.dates) {
        trainingValue.push({
          value: training.trainingId,
          label: `${training.title} - Du ${getFormatedDate(training.dates[0].startDate.seconds)} au ${getFormatedDate(
            training.dates[0].endDate.seconds,
          )}`,
        });
      }
    });
    setTransformedTrainings(trainingValue);
  }, [allTraining]);

  useEffect(() => {
    getUsers();
    getTrainings();
  }, []);

  // SELECT HANDLING EMAIL / USER

  const handlingSelectEmail = (value: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
    if (value) {
      const email: OptionTypeBase = value;
      setSelectedEmail(email);
    }
    return '';
  };

  // SELECT HANDLING TRAINING

  const handlingSelectTraining = (value: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
    if (value) {
      const training: OptionTypeBase = value;
      setSelectedTraining(training);
    }
    return '';
  };

  // SELECT HANDLING PAYMENT STATE
  const handlingSelectPaymentState = (value: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
    if (value) {
      const state: OptionTypeBase = value;
      setSelectedPaymentState(state);
    }
    return '';
  };

  // SELECT HANDLING PAYMENT STATE
  const handlingSelectPaymentMethod = (value: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
    if (value) {
      const state: OptionTypeBase = value;
      setSelectedPaymentMethod(state);
    }
    return '';
  };

  // DATE HANDLING
  const handleStartingDateChange = (date: Date | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  // PDF CONTRACT HANDLING
  const onChangePdf = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      setPdfToUpload(event.target.files[0]);
      setNewPdf(URL.createObjectURL(event.target.files[0]));
    }
  };

  const addPdfStorage = async () => {
    if (selectedTraining?.value && selectedEmail?.value && pdfToUpload) {
      const userName = selectedEmail.value as string;
      const trainingName = selectedTraining.label as string;
      const addContract = await addContractStorage(pdfToUpload, userName, trainingName);
      return addContract;
    }
    return '';
  };

  const addContractToTheStore = async (addContract: string) => {
    if (selectedTraining?.value && selectedEmail?.value && addContract) {
      const contract: Contract = {
        asDownload: false,
        userId: selectedEmail.value || '',
        trainingId: selectedTraining.value || '',
        contractUrl: addContract || '',
        asSigned: true,
      };
      const contractId = await addContractStore(contract);
      return contractId;
    }
    return '';
  };

  const addingOrderToTheStore = async (contractId: string) => {
    if (
      selectedTraining?.value &&
      selectedEmail?.value &&
      contractId &&
      selectedPaymentMethod?.value &&
      selectedPaymentState?.value &&
      startDate
    ) {
      const submitedValueOrder: SubmitedValuesOrder = {
        userId: selectedEmail.value,
        trainingId: selectedTraining.value,
        timeStamp: startDate,
        contractId,
        paymentState: selectedPaymentState.label,
        paymentType: selectedPaymentMethod.value,
      };
      // ADDING THE ORDER TO THE STORE
      await addOrder(submitedValueOrder);
    }
  };

  const handleSubmit = async () => {
    if (
      selectedEmail?.value &&
      selectedTraining?.value &&
      selectedPaymentMethod?.value &&
      selectedPaymentState?.value
    ) {
      const checkIfOrderExist = await checkIfOrderAlreadyExist(selectedEmail.value, selectedTraining.value);
      if (checkIfOrderExist === 'ok') {
        setErrorMessage(null);
        // UPLOAD PDF STORAGE
        const addContract = await addPdfStorage();
        // ADD CONTRACT TO THE STORE
        const contractId = await addContractToTheStore(addContract);
        // ADD ORDER TO THE STORE
        await addingOrderToTheStore(contractId);
        setValidationMessage('La commande à bien été ajouté');
      } else {
        setValidationMessage(null);
        setErrorMessage('La commande existe déjà');
      }
    } else {
      setValidationMessage(null);
      setErrorMessage('Veuillez renseigner tout les champs avant de continuer');
    }
  };

  return (
    <div className="main-container-admin-add-order">
      <div className="sub-container-admin-add-order">
        <div className="main-title-admin-add-order">Ajouter une commande</div>
        <div className="container-content-admin-add-order">
          <Select options={allEmailUser} onChange={handlingSelectEmail} placeholder="E-mail" />
        </div>
        <div className="container-content-admin-add-order">
          {transformedTrainings ? (
            <Select options={transformedTrainings} onChange={handlingSelectTraining} placeholder="Formation" />
          ) : (
            ''
          )}
        </div>
        <div className="container-content-admin-add-order">
          <Select options={paymentMethod} onChange={handlingSelectPaymentMethod} placeholder="Mode de paiement" />
        </div>
        <div className="container-content-admin-add-order">
          <Select options={paymentState} onChange={handlingSelectPaymentState} placeholder="Etat du paiement" />
        </div>
        <div className="container-content-admin-add-order">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              fullWidth
              format="dd/MM/yyyy"
              margin="normal"
              id="start-date-picker"
              label="Date de la commande"
              value={startDate}
              onChange={handleStartingDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="container-content-admin-add-order">
          <div className="container-pdf-input-add-order">
            <input accept="pdf/*" id="contained-button-file" multiple type="file" onChange={onChangePdf} />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                Télécharger le contract
              </Button>
            </label>
          </div>
        </div>
        {pdfToUpload ? (
          <div className="container-content-admin-add-order pdf-title-add-order">
            <div className="pdf-title-add-order">{pdfToUpload.name}</div>
          </div>
        ) : (
          ''
        )}
        <div className="container-content-admin-add-order">
          {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
          {validationMessage ? <Alert severity="success">{validationMessage}</Alert> : null}
        </div>
        <div className="container-content-admin-add-order">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Ajouter la commande
          </Button>
        </div>
      </div>
    </div>
  );
}
