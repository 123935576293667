import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { afterPaymentOrderByCheckAndSepaProcess } from '../../firestore/orders';
import { getTraining } from '../../firestore/trainings';
import { UserInfo } from '../../reducers/app';
import { RootReducer } from '../../store';
import './OrderCheckPayment.css';

interface Props {
  trainingId: string;
  handleNext: () => void;
}

export default function OrderCheckPayment({ trainingId, handleNext }: Props) {
  // CURRENT USER INFOS
  const userInfo = useSelector<RootReducer, UserInfo | null>((state) => state.app.user);

  // CHECK BOX
  const [checked, setChecked] = useState<boolean>(false);
  const [checkBoxErrorMessage, setCheckBoxErrorMessage] = useState<string>('');

  // ERROR MESSAGE
  const [errorMessage, setErrorMessage] = useState<string>();
  // TRAINING INFO
  const [trainingPrice, setTrainingPrice] = useState<string>('');

  // CHECK BOX HANDLING
  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const getTrainingPrice = async () => {
    const training = await getTraining(trainingId);
    if (training) {
      setTrainingPrice(training.price);
    }
    return '';
  };

  useEffect(() => {
    getTrainingPrice();
  }, [trainingId]);

  const handleSubmit = async () => {
    const paymentType = 'checks';
    if (checked) {
      if (userInfo?.uId && trainingId) {
        setCheckBoxErrorMessage('');
        const afterProcess = await afterPaymentOrderByCheckAndSepaProcess(
          trainingId,
          userInfo.uId,
          paymentType,
          trainingPrice,
        );
        if (afterProcess === 'order_already_exist') {
          setErrorMessage(
            'Votre inscription à déjà été prise en compte, veuillez vous rendre dans votre espace personel, ou rafraichir cette page',
          );
        }
        handleNext();
      }
    } else {
      setCheckBoxErrorMessage('Veuillez lire et accepter les conditions générales pour poursuivre votre commande.');
    }
  };

  return (
    <div className="main-container-order-check-element">
      <div className="sub-container-order-check-element">
        <div className="content-order-check-element">
          Si vous choisissez d’effectuer votre règlement d&apos;un montant de <span>{trainingPrice} €</span> par
          chèques, ils seront à mettre à l’ordre de l’IFFCO et à adresser par voie postale au :
        </div>
        <div className="content-order-check-element">
          <div>
            <li>IFFCO</li>
            <li>51 rue de la Méditerranée</li>
            <li> 34000 Montpellier</li>
          </div>
        </div>
        <div className="content-order-check-element">
          Pour chaque formation commandée merci de régler en 2 chèques (1 chèque de 30% du montant, encaissé 10 jours
          après réception et un autre chèque de 70% du montant, encaissé le 1er jour de la formation.) En cas de
          règlement par chèques pour plusieurs formations commandées, merci de réaliser des chèques séparés pour chacune
          des formations.
        </div>
        <div className="content-order-check-element">
          A noter que l’inscription ne sera pas prise en compte tant que vous n’aurez pas accepté les conditions
          générales et cliqué sur le bouton de confirmation ci dessous
        </div>
        <div className="content-order-check-element privacy-policy-check-element">
          Vos données personnelles seront utilisées pour le traitement de votre commande, vous accompagner au cours de
          votre visite du site web, et pour d’autres raisons décrites dans notre
          <Link target="_blank" to="/privacypolicy">
            politique de confidentialité
          </Link>
          .
        </div>
        <div className="general-terms-container-check-element">
          <Checkbox
            checked={checked}
            onChange={handleCheckBox}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <div>
            <div className={checkBoxErrorMessage ? 'error-checkbox-check-element' : ''}>J’ai lu et j’accepte les </div>
            <div className={checkBoxErrorMessage ? 'error-checkbox-check-element' : ''}>
              <Link target="_blank" to="/salesterms">
                conditions générales {checkBoxErrorMessage ? '!' : ''}
              </Link>
            </div>
          </div>
        </div>
        {checkBoxErrorMessage ? (
          <div className="container-alert-check-element">
            <Alert severity="error">{checkBoxErrorMessage}</Alert>
          </div>
        ) : (
          ''
        )}
        {errorMessage ? (
          <div className="container-alert-sepa-element">
            <Alert severity="warning">{errorMessage}</Alert>
          </div>
        ) : (
          ''
        )}
        <div className="container-button-check-element">
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Confirmation d&apos;inscription
          </Button>
        </div>
      </div>
    </div>
  );
}
