import { faAward, faChartLine, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './BannerCounter.css';

export default function BannerCounter() {
  return (
    <div className="main-container-banner-counter">
      <div className="counter-card-banner first-counter-card-banner">
        <FontAwesomeIcon icon={faAward} />
        <div className="counter-counter-banner">97%</div>
        <div className="subtitle-counter-banner">stagiaires satisfaits</div>
      </div>
      <div className="counter-card-banner second-counter-card-banner">
        <FontAwesomeIcon icon={faUserTie} />
        <div className="counter-counter-banner">25+</div>
        <div className="subtitle-counter-banner">formateurs experimentés</div>
      </div>
      <div className="counter-card-banner third-counter-card-banner">
        <FontAwesomeIcon icon={faChartLine} />
        <div className="counter-counter-banner">2600+</div>
        <div className="subtitle-counter-banner">stagiaires formés (depuis 2017)</div>
      </div>
    </div>
  );
}
