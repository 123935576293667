import { faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './privacyPolicy.css';

export default function PrivacyPolicy() {
  return (
    <div className="main-container-privacypolicy">
      <div className="container-banner-privacypolicy">
        <FontAwesomeIcon icon={faVideoSlash} />
        <h2>Politique de confidentialitée</h2>
      </div>
      <div className="subtitle-banner-privacypolicy">
        <h4>Retrouvez ci-dessous notre politique de confidentialitée</h4>
      </div>
      <div className="sub-container-privacypolicy">
        <div className="content-container-privacypolicy">
          <div className="title-content-privacypolicy">
            <h2>responsable</h2>
          </div>
          <div className="content-privacypolicy">
            <div>
              <div>
                Le responsable, au sens du règlement général sur la protection des données (RGPD) et des lois nationales
                sur la protection des données des États membres ainsi que des autres dispositions sur la protection des
                données, est :
              </div>
              <br />
              <div>
                IFFCO
                <br />
                51 Rue de la Méditerranée
                <br />
                34000 Montpellier
                <br />
                Tél : 04 90 57 80 83
                <br />
                Contact : contact@iffco.fr
                <br />
                Site : www.iffco.fr
              </div>
            </div>
          </div>
          <div className="title-content-privacypolicy">
            <h2>Type de données collectées</h2>
          </div>
          <div className="content-privacypolicy">
            <div>
              <div>Lorsque vous nous envoyer un message depuis le formulaire de contact :</div>
              <br />
              <div>Vous nous communiquez : votre nom, votre adresse email, votre téléphone et un message.</div>
              <br />
              <div>Lorsque vous réservez une formation :</div>
              <br />
              <div>
                Vous nous communiquez : votre nom, votre prénom, votre téléphone, votre adresse email, votre adresse
                afin de pouvoir vous établir une facture. Concernant les contrats, votre signature électronique vous est
                demandée afin de valider la réservation de votre formation.
              </div>
            </div>
          </div>
          <div className="title-content-privacypolicy">
            <h2>Utilisation de vos données </h2>
          </div>
          <div className="content-privacypolicy">
            <div>
              <div>
                Conformément à la nouvelle loi Règlement général sur la protection des données (RGPD) applicable au 25
                mai 2018, nous ne collectons et n’utilisons les données à caractère personnel de nos utilisateurs que
                dans la mesure où cela est nécessaire pour fournir un site web fonctionnel ainsi que nos contenus et
                services.
              </div>
              <br />
              <div>
                La collecte et l’utilisation des données à caractère personnel de nos utilisateurs n’ont lieu
                régulièrement qu’avec le consentement de l’utilisateur.
              </div>
              <br />
              <div>Nous utilisons vos données pour vous fournir les services suivants :</div>
              <br />
              <div>– Répondre à votre message envoyé depuis notre formulaire de contact, rubrique « Contact »</div>
              <br />
              <div>
                – Traiter votre demande de réservation de formation en vous faisant parvenir un contrat ainsi qu’une
                facture au format pdf, adressés à l’email que vous aurez renseigné lors de l’ouverture de votre compte
                client.
              </div>
            </div>
          </div>
          <div className="title-content-privacypolicy">
            <h2>Destinataire des données</h2>
          </div>
          <div className="content-privacypolicy">
            <div>
              <div>
                Les données que nous collectons sont accessibles par notre entreprise, ainsi qu’à nos prestataires de
                services, agissant en qualité de sous-traitants, qui concourent techniquement à la maintenance de notre
                site web (prestataire d’hébergement, concepteur du site web).
              </div>
            </div>
          </div>
          <div className="title-content-privacypolicy">
            <h2>Durée de conservation de vos données</h2>
          </div>
          <div className="content-privacypolicy">
            <div>
              <div>
                Les données client (nom, prénom, coordonnées électroniques, historique) sont conservées 3 ans à compter
                de la fin de la relation commerciale.
              </div>
              <br />
              <div>
                Notre site internet utilise un fichier local de session (également appelé « cookie », non conservé une
                fois que votre fenêtre de navigateur est fermée) pour faciliter la navigation et assurer le bon
                fonctionnement de la réservation de formation en ligne par paiement sécurisé.
              </div>
              <br />
              <div>
                Vous pouvez désactiver ou restreindre la transmission de cookies en modifiant les paramètres de votre
                navigateur internet. Cela peut aussi se faire automatiquement. Si les cookies sont désactivés pour notre
                site web, les fonctions de notre site web ne seront peut-être plus accessibles dans leur intégralité.
              </div>
              <br />
              <div>
                Ce site utilise également Google Analytics, un service d’outil de suivi, fourni par Google Inc. («
                Google ») uniquement dans le but d’analyser le cheminement des utilisateurs. Google Analytics utilise
                des cookies.
              </div>
            </div>
          </div>
          <div className="title-content-privacypolicy">
            <h2>Droit à la portabilité</h2>
          </div>
          <div className="content-privacypolicy">
            <div>
              <div>
                Il s’agit de votre droit de recevoir vos données dans un format lisible et de les transmettre à une
                tierce entité sans autorisation préalable.
              </div>
            </div>
          </div>
          <div className="title-content-privacypolicy">
            <h2>Droits d’accès, de rectification, d’interrogation et d’opposition</h2>
          </div>
          <div className="content-privacypolicy">
            <div>
              <div>
                L’exercice du droit d’accès et de rectification, d’interrogation et d’opposition prévu par la loi peut
                être exercé à l’adresse mentionnée ci-dessous :
              </div>
              <br />
              <div>
                IFFCO
                <br />
                51 Rue de la Méditerranée
                <br />
                34000 Montpellier
                <br />
                Tél : 04 90 57 80 83
                <br />
                Contact : contact@iffco.fr
                <br />
                Site : www.iffco.fr
              </div>
            </div>
          </div>
          <div className="title-content-privacypolicy">
            <h2>Sécurité des données</h2>
          </div>
          <div className="content-privacypolicy">
            <div>
              <div className="last-child-content-privacypolicy">
                IFFCO adopte des mesures de sécurité suffisantes, adéquates et pertinentes, afin de préserver la
                sécurité des Données Personnelles notamment, d’empêcher que celles-ci soient divulguées à des tiers,
                elles concernent également la mise en place d’un certificat SSL ainsi qu’une plateforme de paiement
                sécurisé STRIPE, afin de garantir la sécurité des paiements effectués sur le site www.iffco.fr
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
