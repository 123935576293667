import React from 'react';
import './AdditionalFooter.css';
import { Link } from 'react-router-dom';

export default function AdditionalFooter() {
  return (
    <div className="main-container-additional-footer">
      <div className="sub-container-additional-footer">
        <div className="bottom-links-container-additional-footer">
          <Link to="/legalpage">
            <div className="link-additional-footer">mentions légales</div>
          </Link>
          <Link to="/privacypolicy">
            <div className="link-additional-footer">politique de confidentialité</div>
          </Link>
          <Link to="/salesterms">
            <div className="link-additional-footer">conditions générales de ventes</div>
          </Link>
          <Link to="/contact">
            <div className="link-additional-footer">contact</div>
          </Link>
        </div>
        <div className="title-container-additional-footer">
          <div className="title-additional-footer">© copyright iffco - tous droits réservés</div>
        </div>
      </div>
    </div>
  );
}
