/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import './contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { cloneDeep } from 'lodash';
import BreadCrumbs from '../../components/Bread-Crumbs/BreadCrumbs';
import { userContactEmail } from '../../Email/email';

interface InputValues {
  field: string;
  error: boolean;
  value: string;
  label: string;
  type: string;
  errorMessage: string;
}

export default function Trainers() {
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleCloseSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };
  const [textAreaValue, setTextAreaValue] = useState<string>('');
  const breadCrumbs = [
    {
      link: '/',
      name: 'Accueil',
    },
    {
      link: '/contact',
      name: 'Contact',
    },
  ];
  const [allValues, setAllValues] = useState<Array<InputValues>>([
    {
      field: 'fName',
      label: 'nom, prénom',
      error: false,
      errorMessage: 'veuillez renseigner ce champ',
      value: '',
      type: 'textfield',
    },
    {
      field: 'email',
      label: 'e-mail',
      error: false,
      errorMessage: 'veuillez renseigner ce champ',
      value: '',
      type: 'textfield',
    },
    {
      field: 'phone',
      label: 'téléphone',
      error: false,
      errorMessage: 'veuillez renseigner ce champ',
      value: '',
      type: 'textfield',
    },
    {
      field: 'object',
      label: 'objet',
      error: false,
      errorMessage: 'veuillez renseigner ce champ',
      value: '',
      type: 'textfield',
    },
  ]);

  const changeHandler = (e: { target: { name: string; value: string } }) => {
    const index = allValues.findIndex((a) => a.field === e.target.name);
    const values = cloneDeep(allValues);
    if (values[index]) {
      values[index].value = e.target.value;
      setAllValues(values);
    }
  };

  const submitValue = async () => {
    const newAllValues = allValues.map((value) => {
      if (!value.value) {
        value.error = true;
      } else {
        value.error = false;
      }
      return value;
    });
    setAllValues(newAllValues);
    const atLeastOneError = allValues.some((a) => a.error);
    if (!atLeastOneError) {
      const mail: Object = {
        name: allValues[0].value,
        mail: allValues[1].value,
        phone: allValues[2].value,
        object: allValues[3].value,
        content: textAreaValue,
      };
      await userContactEmail(mail);
      const clonedAllValues = cloneDeep(allValues).map((v) => {
        v.value = '';
        return v;
      });
      setAllValues(clonedAllValues);
      setTextAreaValue('');
      setOpenSnackBar(true);
    }
  };

  const onChangeTextArea = (e: { target: { value: string } }) => {
    const result = e.target.value;
    setTextAreaValue(result);
  };

  useEffect(() => {
    document.title = `IFFCO - Contact`;
  }, []);

  return (
    <div className="main-container-contact">
      <BreadCrumbs breadCrumb={breadCrumbs} />
      <div className="container-banner-contact">
        <FontAwesomeIcon icon={faFileSignature} />
        <h2>Formulaire de contact</h2>
      </div>
      <div className="subtitle-banner-contact">
        <h4>Une question à nous poser ?</h4>
      </div>
      <div className="main-content-container-contact">
        <div className="sub-content-container-contact">
          <div className="contact-form-container-contact">
            <div className="container-title-contact">
              <div className="title-content-contact">formulaire de contact</div>
              <h3>Nous vous invitons à nous laisser un message, nous vous répondrons dans les plus brefs délais.</h3>
            </div>
            <div className="fields-main-container-contact">
              {allValues.map((value) =>
                value.type === 'textfield' ? (
                  <div className="textfield-contact" key={value.field}>
                    <TextField
                      error={value.error}
                      id={value.field}
                      label={value.label}
                      name={value.field}
                      variant="outlined"
                      helperText={value.error ? value.errorMessage : ''}
                      onChange={changeHandler}
                      value={value.value}
                    />
                  </div>
                ) : null,
              )}
              <div className="textarea-contact">
                <TextareaAutosize
                  aria-label="maximum height"
                  onChange={onChangeTextArea}
                  placeholder="votre message"
                  value={textAreaValue}
                />
              </div>
              <div className="button-contact-page">
                <Button variant="contained" color="primary" onClick={submitValue}>
                  Envoyer
                </Button>
              </div>
            </div>
          </div>
          <div className="phone-address-container-contact">
            <div className="title-content-contact"> téléphone :</div>
            <div>Tél: 04 90 57 80 83</div>
            <div>Prix d'un appel local</div>
            <div className="title-content-contact address-title-contact"> adresse :</div>
            <div>IFFCO</div>
            <div>51, Rue de la Méditerranée</div>
            <div>34000 Montpellier</div>
            <div className="social-network-icons-container-contact">
              <div className="facebook-icon-contact">
                <a href="https://www.facebook.com/iffcoosteopathie/" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faFacebookSquare} />
                </a>
              </div>
              <div className="linkedin-icon-contact">
                <a href="https://www.linkedin.com/in/iffcoosteo/?originalSubdomain=fr" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity="success">
          Le message a bien été envoyé
        </Alert>
      </Snackbar>
    </div>
  );
}
