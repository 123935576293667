/* eslint-disable import/prefer-default-export */
import { fire } from '../config/fire';
import { Contract } from '../types/contract';

const db = fire.firestore();

export const addContractStore = async (contract: Contract) => {
  if (contract) {
    try {
      const res = await db.collection('contracts').add({
        asDownload: contract.asDownload,
        userId: contract.userId,
        signedHour: contract.signedHour ? contract.signedHour : '',
        signedDate: contract.signedDate ? contract.signedDate : '',
        trainingId: contract.trainingId,
        contractUrl: contract.contractUrl,
        asSigned: contract.asSigned,
        userIp: contract.userIp ? contract.userIp : '',
      });
      await db.collection('contracts').doc(res.id).update({
        contractId: res.id,
      });
      return res.id;
    } catch (e) {
      console.warn(e);
    }
  }
  return '';
};

export const getContract = async (contractId: string) => {
  if (contractId) {
    try {
      const contractRef = db.collection('contracts').doc(contractId);
      const doc = await contractRef.get();
      if (doc) {
        const contract = doc.data();
        return contract;
      }
    } catch (e) {
      console.warn(e);
    }
  }
  return Promise.resolve();
};
