/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import firebase from 'firebase/app';
import { fire } from '../config/fire';
import { deleteFileStorage } from '../storage/storage';
import { FetchedProfession, FetchedTrainer, Profession, Trainer } from '../types/trainers';

const db = fire.firestore();

export const getAllTrainers = async () => {
  try {
    const trainersRef = db.collection('trainers');
    const snapshot = (await trainersRef.get()) as firebase.firestore.QuerySnapshot<Trainer>;
    const trainers: Array<FetchedTrainer> = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      trainers.push({ ...data, id: doc.id });
    });
    return trainers;
  } catch (e) {
    console.warn(e);
  }
  return Promise.resolve();
};

export const getTrainerProfessions = async (id: string) => {
  if (id) {
    try {
      const trainerRef = db.collection('professions').doc(id);
      const doc = (await trainerRef.get()) as firebase.firestore.DocumentSnapshot<FetchedProfession>;
      if (doc) {
        const dataTraining = doc.data();
        if (dataTraining?.name) {
          return dataTraining.name;
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }
  return '';
};

export const getTrainerName = async (id: string) => {
  if (id) {
    try {
      const trainerRef = db.collection('trainers').doc(id);
      const doc = (await trainerRef.get()) as firebase.firestore.DocumentSnapshot<FetchedProfession>;
      if (doc) {
        const dataTraining = doc.data();
        if (dataTraining?.name) {
          return dataTraining.name;
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }
  return '';
};

export const getTrainer = async (id: string) => {
  if (id) {
    try {
      const trainerRef = db.collection('trainers').doc(id);
      const doc = (await trainerRef.get()) as firebase.firestore.DocumentSnapshot<FetchedTrainer>;
      if (doc) {
        const dataTraining = doc.data();
        return dataTraining;
      }
    } catch (e) {
      console.warn(e);
    }
  }
  return Promise.resolve();
};

export const addTrainer = async (trainer: Trainer) => {
  if (trainer) {
    try {
      const res = await db.collection('trainers').add({
        name: trainer.name,
        description: trainer.description,
        professionIds: trainer.professionIds,
        image: trainer.image,
      });
      await db.collection('trainers').doc(res.id).update({
        id: res.id,
      });
    } catch (e) {
      console.warn(e);
    }
  }
};

export const updateTrainer = async (id: string, trainer: Trainer) => {
  if (trainer) {
    try {
      await db.collection('trainers').doc(id).update({
        name: trainer.name,
        description: trainer.description,
        professionIds: trainer.professionIds,
        image: trainer.image,
      });
    } catch (e) {
      console.warn(e);
    }
  }
};

export const deleteTrainerFirestore = async (id: string) => {
  const trainer = await getTrainer(id);
  const imagePath = 'trainers-images';
  if (trainer) {
    await deleteFileStorage(imagePath, trainer.image.id);
  }
  if (id) {
    try {
      db.collection('trainers').doc(id).delete();
    } catch (e) {
      console.warn(e);
    }
  }
  return Promise.resolve();
};

export const getAllProfessions = async () => {
  try {
    const professionRef = db.collection('professions');
    const snapshot = (await professionRef.get()) as firebase.firestore.QuerySnapshot<Profession>;
    const professions: Array<Profession> = [];
    snapshot.forEach((doc) => {
      professions.push({ ...doc.data(), id: doc.id });
    });
    return professions;
  } catch (e) {
    console.warn(e);
  }
  return [];
};

export const addNewProfession = async (name: string) => {
  try {
    await db.collection('professions').add({
      name,
    });
  } catch (e) {
    console.warn(e);
  }
};
