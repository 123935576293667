import React from 'react';
import './PartnersPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import allianzLogo from '../../images/allianzLogoHd.png';
import unimLogo from '../../images/unimLogoHd.svg';
import ktTapeLogo from '../../images/ktTapeLogo.svg';
import medSmartLogo from '../../images/medSmartLogo.png';
import bemerLogo from '../../images/bemerLogo.png';
import francoFilsLogo from '../../images/francoFilsLogo.png';
import egymLogo from '../../images/egymLogoHd.jpg';
import milouLogo from '../../images/milouLogo.png';
import doctolibLogo from '../../images/doctolibLogo.jpg';
import soloMedicalLogo from '../../images/solomedicalLogo.webp';
import eduprintLogo from '../../images/eduprintLogo.webp';
import kinventLogo from '../../images/kinventLogo.png';
import kinetecLogo from '../../images/kinetecLogo.jpg';
import chairEtOsLogo from '../../images/chairEtOslogo.webp';
import BreadCrumbs from '../Bread-Crumbs/BreadCrumbs';

export default function PartnersPage() {
  const breadCrumbs = [
    {
      link: '/',
      name: 'Accueil',
    },
    {
      link: '/partnersPage',
      name: 'Partenaires',
    },
  ];

  return (
    <div className="main-container-trainers main-container-partners">
      <BreadCrumbs breadCrumb={breadCrumbs} />
      <div className="container-banner-trainers">
        <FontAwesomeIcon icon={faUser} />
        <h2>Partenaires</h2>
      </div>
      <div className="subtitle-banner-trainers">
        <h4>Retrouvez ci-dessous tout nos partenaires</h4>
      </div>
      <div className="partners-logo-container">
        <div className="left-container">
          <a href="https://www.allianz.fr/" rel="noreferrer" target="_blank">
            <img src={allianzLogo} alt="alliance-banner" />
          </a>
          <a href="https://www.unim.asso.fr/" rel="noreferrer" target="_blank">
            <img src={unimLogo} alt="unim-banner" />
          </a>
          <a href="https://kttape.shop/fr-fr" rel="noreferrer" target="_blank">
            <img className="kt-tape-logo" src={ktTapeLogo} alt="kt-tape-banner" />
          </a>
          <a className="medsmart-logo" href="https://medsmart.fr/" rel="noreferrer" target="_blank">
            <img src={medSmartLogo} alt="medsmart-banner" />
          </a>
          <a className="bemer-logo" href="https://bemergroup.com/" rel="noreferrer" target="_blank">
            <img src={bemerLogo} alt="bemmer-banner" />
          </a>
          <a className="franco-fils-logo" href="https://www.francofils.com/" rel="noreferrer" target="_blank">
            <img src={francoFilsLogo} alt="franco-fils-banner" />
          </a>

          <a href="https://www.eduprint3d.fr/" rel="noreferrer" target="_blank">
            <img src={eduprintLogo} alt="eduprint-logo-banner" />
          </a>
        </div>

        <div className="right-container">
          <a href="https://www.milou.care/" rel="noreferrer" target="_blank">
            <img src={milouLogo} alt="milou-banner" />
          </a>
          <a href="https://www.doctolib.com/" rel="noreferrer" target="_blank">
            <img src={doctolibLogo} alt="doctolib-banner" />
          </a>
          <a href="https://www.kinetec.fr/" rel="noreferrer" target="_blank">
            <img src={kinetecLogo} alt="kinetec-banner" />
          </a>
          <a href="https://www.solomedical-ra.com/" rel="noreferrer" target="_blank">
            <img src={soloMedicalLogo} alt="solo-medical-banner" />
          </a>

          <a href="https://physio.kinvent.com/" rel="noreferrer" target="_blank">
            <img src={kinventLogo} alt="kinvent-logo-banner" />
          </a>
          <a href="https://www.en-chair-et-en-os.fr//" rel="noreferrer" target="_blank">
            <img className="chair-et-os-logo" src={chairEtOsLogo} alt="chair-et-os-logo-banner" />
          </a>
          <a href="https://www.egym.com/" rel="noreferrer" target="_blank">
            <img src={egymLogo} alt="egym-banner" />
          </a>
        </div>
      </div>
    </div>
  );
}
