/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { logOut } from '../../firebase/firebase';
import './TabsDisplayer.css';

interface TabProp {
  name: string;
  pathName: string;
  isActive: boolean;
  highlights: Array<string>;
  id: string;
}

interface Props {
  tabName: Array<TabProp>;
  setTabName: Function;
}

export default function TabsDisplayer({ tabName, setTabName }: Props) {
  const { pathname } = useLocation();

  const setAllToFalse = (tabActive: Array<TabProp>, index: number) => {
    return tabName.map((c, i) => {
      if (i !== index) {
        c.isActive = false;
      }
      return c;
    });
  };

  useEffect(() => {
    const tabActive = [...tabName];
    let currentTab: string | null = null;
    const regex = /\/myspace\/([a-z]+)/;
    const resultRegex = regex.exec(pathname);
    if (resultRegex) {
      [, currentTab] = resultRegex;
    }
    const index = tabActive.findIndex((c) => c.highlights.includes(currentTab || ''));
    if (index !== -1) {
      tabActive[index].isActive = true;
      setTabName(setAllToFalse(tabActive, index));
    }
  }, [pathname]);

  const handleIsActiveOnTabs = (id: string) => {
    const tabActive = [...tabName];
    const index = tabActive.findIndex((c) => c.id === id);
    if (index !== -1) {
      tabActive[index].isActive = !tabActive[index].isActive;
      setTabName(setAllToFalse(tabActive, index));
    }
  };
  return (
    <div className="container-tabs-tabs-displayer">
      {tabName.map((tab) => (
        <Link key={tab.name} to={`/myspace/${tab.pathName}`}>
          <div
            key={tab.id}
            id={tab.id}
            role="button"
            className={
              tab.isActive ? 'title-tabs-tabs-displayer background-tabs-displayer' : 'title-tabs-tabs-displayer'
            }
            onClick={() => handleIsActiveOnTabs(tab.id)}
          >
            {tab.name}
          </div>
        </Link>
      ))}
      <div role="button" className="title-tabs-tabs-displayer" onClick={logOut}>
        Deconnexion
      </div>
    </div>
  );
}
