import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCity, faEnvelope, faHome, faPhoneAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import './UserAddressDisplayer.css';
import { Order } from '../../types/orders';
import { getSingleUser } from '../../firestore/user';
import { User } from '../../types/users';

interface Props {
  order: Order;
}

export default function UserAddressDisplayer({ order }: Props) {
  const [user, setUser] = useState<User>();

  const getUserObject = async () => {
    if (order.userId) {
      const getUser = (await getSingleUser(order.userId)) as User;
      setUser(getUser);
    }
  };

  useEffect(() => {
    getUserObject();
  }, [order]);

  return (
    <>
      {user ? (
        <div className="sub-container-user-address-displayer">
          <div className="container-address-user-address-displayer">
            <div className="title-container-address-user-address-displayer">adresse de facturation</div>
            <div className="content-container-address-user-address-displayer">
              <FontAwesomeIcon icon={faUser} />
              {user?.lastName}
              &nbsp;
              {user?.firstName}
            </div>
            <div className="content-container-address-user-address-displayer">
              <FontAwesomeIcon icon={faHome} />
              {user?.address}
            </div>
            <div className="content-container-address-user-address-displayer">
              <FontAwesomeIcon icon={faCity} />
              {user?.zipCode}
              &nbsp;
              {user?.city}
            </div>
            <div className="content-container-address-user-address-displayer">
              <FontAwesomeIcon icon={faPhoneAlt} />
              {user?.phone}
            </div>
            <div className="content-container-address-user-address-displayer">
              <FontAwesomeIcon icon={faEnvelope} />
              {user?.email}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
