import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCustomerOrders } from '../../firestore/orders';
import { getTraining } from '../../firestore/trainings';
import { UserInfo } from '../../reducers/app';
import { RootReducer } from '../../store';
import { Order } from '../../types/orders';
import { FetchedTraining } from '../../types/trainings';
import { getFormatedDate } from '../Admin-Trainings/AdminTrainings';
import UserNoOrders from '../User-No-Orders/UserNoOrders';
import './UserOrders.css';

export interface Orders {
  orderNumber: string;
  date: string;
  state: string;
  total: number;
}

export interface TrainingAndOrder {
  training: FetchedTraining;
  order: Order;
}

export default function UserOrders() {
  // USERINFOS
  const userInfo = useSelector<RootReducer, UserInfo | null>((state) => state.app.user);

  // USER ORDERS
  const [allUserOrders, setAllUserOrders] = useState<Array<Order>>([]);

  // USER ORDERED TRAININGS
  const [userTrainingsAndOrders, setUserTrainingsAndOrders] = useState<Array<TrainingAndOrder>>();

  // LOADING
  const [loading, setLoading] = useState<boolean>(true);

  const fetchUserOrders = async () => {
    if (userInfo) {
      setLoading(true);
      const customerOrders = await getCustomerOrders(userInfo?.uId ? userInfo.uId : '');
      if (customerOrders) {
        setAllUserOrders(customerOrders);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    document.title = `IFFCO - Mes formations`;
  }, []);

  useEffect(() => {
    fetchUserOrders();
  }, [userInfo]);

  const fetchTraining = async () => {
    if (allUserOrders) {
      setLoading(true);
      const trainings = await Promise.all(
        allUserOrders.map(async (order) => {
          const training = (await getTraining(order.trainingId)) as unknown as FetchedTraining;
          return { training, order };
        }),
      );
      setUserTrainingsAndOrders(trainings);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTraining();
  }, [allUserOrders]);

  const columnName = [
    {
      name: 'Formation',
    },
    {
      name: 'Date',
    },
    {
      name: 'Paiement',
    },
    {
      name: 'Total',
    },
    {
      name: 'Action',
    },
  ];

  return (
    <>
      {loading ? (
        <div className="container-circularprogress-user-orders">
          <CircularProgress />
        </div>
      ) : (
        <div className="main-container-user-orders">
          {allUserOrders[0] ? (
            <div className="sub-container-user-orders">
              <table>
                <thead>
                  <tr>
                    {columnName.map((column) => (
                      <th scope="col" key={column.name}>
                        {column.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {userTrainingsAndOrders?.map((training) => (
                    <tr key={training.training.title}>
                      <td data-label="Formation" className="order-link-title-user-orders">
                        <Link to={`/myspace/orderview/${training.order.orderId}`}>{training.training.title}</Link>
                      </td>
                      <td data-label="Date">{getFormatedDate(training.order.timeStamp.seconds)}</td>
                      <td data-label="Etat">{training.order.paymentState}</td>
                      <td data-label="Total">{training.training.price} €</td>
                      <td data-label="Action">
                        <Link to={`/myspace/orderview/${training.order.orderId}`}>
                          <div className="button-table-user-orders">
                            <Button variant="contained" color="primary">
                              Voir
                            </Button>
                          </div>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <UserNoOrders />
          )}
        </div>
      )}
    </>
  );
}
