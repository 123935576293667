/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import { useSelector } from 'react-redux';
import { UserInfo } from '../../reducers/app';
import { logOut } from '../../firebase/firebase';
import { RootReducer } from '../../store';
import './UserBoard.css';

export default function UserBoard() {
  const userInfo = useSelector<RootReducer, UserInfo | null>((state) => state.app.user);
  const userName = `${userInfo?.firstName} ${userInfo?.lastName}`;

  return (
    <div className="main-container-user-board">
      <div className="sub-container-user-board">
        <div className="sub-container-content-user-board">
          <div className="container-hello-user-board">
            <div>Bonjour</div>
            <div className="user-email-user-board">
              &nbsp;
              {userInfo?.lastName && userInfo?.firstName ? userName : userInfo?.email}
              &nbsp;
            </div>
          </div>
          <div className="container-hello-user-board">
            <div>(&nbsp;Ce n&lsquo;est pas vous ? </div>
            <div className="logout-button-user-board" onClick={logOut} role="button">
              &nbsp;Déconnexion&nbsp;
            </div>
            <div>)</div>
          </div>
        </div>
        <div className="content-user-board">
          À partir du tableau de bord de votre compte, vous pouvez visualiser vos commandes récentes, gérer vos adresses
          de livraison et de facturation ainsi que changer votre mot de passe et les détails de votre compte.
        </div>
      </div>
    </div>
  );
}
