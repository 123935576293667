import { faUserMd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import './TrainerView.css';
import { useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTrainer, getTrainerProfessions } from '../../firestore/trainers';
import { Trainer, TrainersWithProfessions } from '../../types/trainers';
import userImage from '../../images/user-image.png';

export default function TrainerView() {
  const { trainerId } = useParams<{ trainerId?: string }>();
  const [trainer, setTrainer] = useState<Trainer>();
  const [newTrainerWithProfessions, setNewTrainerWithProfessions] = useState<TrainersWithProfessions>();
  const [loading, setLoading] = useState<Boolean>(false);

  // GET ALL TRAINERS

  const fetchTrainer = async () => {
    if (trainerId) {
      setLoading(true);
      const fetchTrainerStore = await getTrainer(trainerId);
      if (fetchTrainerStore) {
        setTrainer(fetchTrainerStore);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchTrainer();
  }, []);

  // GET NEW TRAINER LIST WITH PROFESSIONS

  const newTrainerList = async () => {
    const clonedTrainer = JSON.parse(JSON.stringify(trainer)) as TrainersWithProfessions;
    const trainerObjectWithProfession = await Promise.all(
      clonedTrainer.professionIds.map((i) => getTrainerProfessions(i)),
    );
    clonedTrainer.professions = trainerObjectWithProfession;
    setNewTrainerWithProfessions(clonedTrainer);
  };

  useEffect(() => {
    newTrainerList();
    document.title = `IFFCO - ${trainer?.name}`;
  }, [trainer]);

  return (
    <div className="main-container-trainer-view">
      <div className="sub-container-trainer-view">
        {loading ? (
          <div className="loading-container-trainer-view">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="container-trainer-view">
              <FontAwesomeIcon icon={faUserMd} />
              <h2>Formateur</h2>
            </div>
            <div className="subtitle-trainer-view">
              <h4>Retrouvez ci-dessous votre formateur préféré</h4>
            </div>
            <div className="container-content-trainer-view">
              <div>
                {newTrainerWithProfessions?.image.url ? (
                  <img src={newTrainerWithProfessions?.image.url} alt="formateur" />
                ) : (
                  <img src={userImage} alt="default-user" />
                )}
              </div>
              <div>
                <h3>{newTrainerWithProfessions?.name}</h3>
                <h4>
                  {newTrainerWithProfessions?.professions ? newTrainerWithProfessions.professions.join(' & ') : ''}
                </h4>
                <div dangerouslySetInnerHTML={{ __html: newTrainerWithProfessions?.description || '' }} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
