/* eslint-disable @typescript-eslint/no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './BreadCrumbs.css';

export interface BreadCrumb {
  link: string;
  name: string;
}

interface Props {
  breadCrumb: Array<BreadCrumb>;
}

export default function BreadCrumbs({ breadCrumb }: Props) {
  const [crumb] = useState<Array<BreadCrumb>>(breadCrumb);
  return (
    <div className="breadcrumbs-container">
      <Breadcrumbs aria-label="breadcrumb">
        {crumb.map((bread) => (
          <Link to={bread.link} color="inherit" key={bread.name}>
            <div>
              <div>{bread.name}</div>
            </div>
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
}
