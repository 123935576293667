/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';
import './OrderPaymentTabs.css';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EmailIcon from '@material-ui/icons/Email';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import OrderCardPayment from '../Order-Card-Payment/OrderCardPayment';
import OrderSepaPayment from '../Order-Sepa-Payment/OrderSepaPayment';
import OrderCheckPayment from '../Order-Check-Payment/OrderCheckPayment';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface Props {
  trainingId: string;
  handleNext: () => void;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function OrderPaymentTabs({ trainingId, handleNext }: Props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={`${classes.root} main-container-order-payment-tabs`}>
      <div className="title-order-payment-tabs">Votre mode de réglement :</div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Carte Bancaire" icon={<PaymentIcon />} />
          <Tab label="Virement Bancaire" icon={<AccountBalanceIcon />} {...a11yProps(1)} />
          <Tab label="Chèque" icon={<EmailIcon />} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <OrderCardPayment trainingId={trainingId} handleNext={handleNext} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrderSepaPayment trainingId={trainingId} handleNext={handleNext} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OrderCheckPayment trainingId={trainingId} handleNext={handleNext} />
      </TabPanel>
    </div>
  );
}
