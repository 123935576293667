import React, { useEffect } from 'react';
import './faq.css';
import { faBook, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BannerContact from '../../components/Banner-Contact/BannerContact';
import BreadCrumbs from '../../components/Bread-Crumbs/BreadCrumbs';
import AccordionDisplayer from '../../components/Accordion/Accordion';

export default function Faq() {
  const breadCrumbs = [
    {
      link: '/',
      name: 'Accueil',
    },
    {
      link: '/faq',
      name: 'Faq',
    },
  ];
  const content = [
    {
      title: 'Comment réserver une formation ?',
      mainContent:
        "Pour réserver votre prochaine formation, il vous suffit de vous rendre sur notre page « calendrier des formations », choisir la formation qui vous intéresse, puis cliquez sur « réserver » et enfin ajouter au panier. Vous avez alors la possibilité de remplir notre contrat de formation en ligne et de régler directement votre formation via notre plateforme de paiement 100% sécurisée. A noter que la création d'un compte client est obligatoire pour s'inscrire à une formation car votre compte vous permettra d'accèder au terme de la formation à certains documents qui pourront vous être utile",
    },
    {
      title: 'Quelles prestations sont comprises dans le tarif ?',
      mainContent:
        'Le tarif de la formation comprend bien évidemment le contenu pédagogique proposé par le formateur, le matériel pédagogique individuel (classeur de formation avec powerpoint du formateur et prise de notes, stylo), accès au matériel technique (table de pratique adapté à l’ostéopathie, housse de protection et serviettes individuelles, coussins, poupons de pratique…). Nous vous proposons également un petit-déjeuner de bienvenue ainsi que l’accès à différentes collations tout au long de la journée.',
    },
    {
      title: 'Je souhaite annuler ma commande, comment dois-je procéder ?',
      mainContent:
        'En cas de désir d’annulation de votre part, les modalités d’annulation sont décrites dans les conditions générales de vente. Nous sommes bien entendu à votre écoute et vous pouvez nous contacter directement via notre mail ou par téléphone.',
    },
    {
      title: 'Comment savoir si ma formation est prise en charge ?',
      mainContent:
        'Aucun organisme de formation ne peut être accrédité pour que ses formations sont prises en charge par les organismes financeurs. Cependant, ces organismes financeurs (le FIFPL en ce qui concerne les ostéopathes) éditent chaque année des critères de prise en charge (thèmes prioritaires ou secondaires, montant alloué pour chaque professionnel…). Nous nous efforçons donc que les formations que nous vous proposons collent au plus près à ces différents critères. Nos formations sont donc éligibles à ces prises en charge. Il revient à la charge du professionnel de constituer une demande de prise en charge préalable avant le début de la formation avec les éléments que nous vous fournissons ainsi que vos données personnelles, afin d’obtenir un accord de prise en charge préalable.',
    },
  ];

  useEffect(() => {
    document.title = `IFFCO - FAQ`;
  }, []);

  return (
    <div className="main-container-faq">
      <BreadCrumbs breadCrumb={breadCrumbs} />
      <div className="container-banner-faq">
        <FontAwesomeIcon icon={faBook} />
        <h2>Faq</h2>
      </div>
      <div className="subtitle-banner-faq">
        <h4>Retrouvez ci-dessous les questions les plus fréquement posées</h4>
      </div>
      <div className="container-top-info-faq">
        <FontAwesomeIcon icon={faInfoCircle} />
        <div>Cliquez sur une question pour visualiser la réponse.</div>
      </div>
      <div className="accordion-container-faq">
        <AccordionDisplayer content={content} />
      </div>
      <BannerContact />
    </div>
  );
}
