import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './UserNoOrders.css';

export default function UserNoOrders() {
  return (
    <div className="sub-container-user-downloads">
      <div className="content-container-user-downloads">
        <div className="sub-container-content-user-downloads">
          <FontAwesomeIcon icon={faCartPlus} />
          <div>Vos prochaines réservation apparaitront ici</div>
        </div>
      </div>
    </div>
  );
}
