import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { getContract } from '../../firestore/contracts';
import { getSingleOrder, setOrderIsNewToFalse, updateOrder } from '../../firestore/orders';
import { getTraining, getTrainingFiles } from '../../firestore/trainings';
import { AppState } from '../../reducers/app';
import { RootReducer } from '../../store';
import { Contract } from '../../types/contract';
import { Order } from '../../types/orders';
import { FetchedTraining, TrainingFiles } from '../../types/trainings';
import { getFormatedDate } from '../Admin-Trainings/AdminTrainings';
import UserAddressDisplayer from '../User-Address-Displayer/UserAddressDisplayer';
import './OrderDisplayer.css';
import { firebaseConfig, functions } from '../../config/fire';
import getOrderPosition from '../Utils/invoice-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export default function OrderDisplayer() {
  const classes = useStyles();
  const { isAdmin } = useSelector<RootReducer, AppState>((state) => state.app);
  const [paymentState, setPaymentState] = useState<string>('');
  const { orderId } = useParams<{ orderId?: string }>();
  const [order, setOrder] = useState<Order | null>(null);
  const [orderPosition, setOrderPosition] = useState<string>();
  const [training, setTraining] = useState<FetchedTraining | null>(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [contract, setContract] = useState<Contract | null>(null);
  const [trainingFiles, setTrainingFiles] = useState<Array<TrainingFiles>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const columnName = [
    {
      name: 'Numéro de commande :',
    },
    {
      name: 'Produit :',
    },
    {
      name: 'Date de la commande :',
    },
    {
      name: 'Moyen de paiement :',
    },
    {
      name: 'Etat du paiement :',
    },
    {
      name: 'Programme :',
    },
    {
      name: 'Contrat :',
    },
    {
      name: 'Total :',
    },
  ];

  const handleChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    if (orderId) {
      setPaymentState(event.target.value as string);
      const state = event.target.value as string;
      await updateOrder(orderId, state);
    }
  };

  const getUserOrder = async () => {
    if (orderId) {
      const singleOrder = (await getSingleOrder(orderId)) as Order;
      if (singleOrder) {
        const fetchOrderPosition = (await getOrderPosition(singleOrder.orderId)).toString();
        setOrderPosition(fetchOrderPosition);
        setOrder(singleOrder);
        setPaymentState(singleOrder.paymentState);
      }
    }
  };

  useEffect(() => {
    getUserOrder();
  }, [orderId]);

  const getSingleTraining = async () => {
    if (order) {
      const fetchedTraining = (await getTraining(order.trainingId)) as FetchedTraining;
      setTraining(fetchedTraining);
    }
    return '';
  };

  const handleCloseSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const getContractFromStore = async () => {
    if (order?.contractId) {
      const fetchedContract = (await getContract(order.contractId)) as Contract;
      setContract(fetchedContract);
    }
  };

  useEffect(() => {
    getSingleTraining();
    getContractFromStore();
  }, [order]);

  const translatePaymentMethod = (name: string) => {
    if (name === 'sepa') {
      return 'Virement Bancaire';
    }
    if (name === 'checks') {
      return 'Chèque';
    }
    if (name === 'creditCard') {
      return 'Carte bancaire';
    }
    return 'Moyen de paiment';
  };

  // SET THE ORDER STATE ISNEW TO FALSE
  const setCurrentOrderIsNewToFalse = async () => {
    if (isAdmin && orderId) {
      await setOrderIsNewToFalse(orderId);
    } else {
      return '';
    }
    return '';
  };

  useEffect(() => {
    setCurrentOrderIsNewToFalse();
  }, []);

  const getTrainingFilesFromStore = async () => {
    if (order?.trainingId) {
      const files = await getTrainingFiles(order.trainingId);
      if (files) {
        setTrainingFiles(files);
      }
    }
  };

  const showFiles = () => {
    if (paymentState === 'Confirmé' && trainingFiles.length > 0) {
      return (
        <>
          <div className="details-title-order-displayer">Fichiers à télécharger :</div>
          <div className="document-table-order-displayer">
            {trainingFiles.map((file, i) => (
              <div key={i}>
                <a href={file.fileUrl} target="_blank" rel="noreferrer">
                  {file.fileName}
                </a>
              </div>
            ))}
          </div>
        </>
      );
    }
    return null;
  };

  const downloadAnchor = (url: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = url.substring(url.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadInvoice = async () => {
    setLoading(true);
    if (order?.invoiceUrl) {
      downloadAnchor(order.invoiceUrl);
    } else {
      const generateInvoice = functions.httpsCallable('generateInvoice');
      const generateInvoiceResponse = await generateInvoice({
        orderId,
        storageBucket: firebaseConfig.storageBucket,
        paymentDate: getFormatedDate(order?.timeStamp.seconds),
      });
      await getUserOrder();
      if (generateInvoiceResponse.data.invoiceUrl) {
        downloadAnchor(generateInvoiceResponse.data.invoiceUrl);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getTrainingFilesFromStore();
  }, [training]);

  return (
    <>
      {order ? (
        <div className="main-container-order-displayer" id="toto">
          <div className="container-order-displayer">
            <div className="title-order-displayer">
              <IconButton aria-label="delete" onClick={() => window.history.go(-1)}>
                <ArrowBackIcon />
              </IconButton>
              <Button variant="contained" color="primary" onClick={() => downloadInvoice()} disabled={loading}>
                <FontAwesomeIcon icon={faArrowDown} />
                Télécharger la facture
              </Button>
            </div>
            <div className="details-title-order-displayer">Détails de la réservation</div>

            <div className="container-first-table-order-displayer">
              <div className="sub-container-second-table-order-displayer">
                {columnName.map((column) => (
                  <div className="content-second-table-order-displayer" key={column.name}>
                    {column.name}
                  </div>
                ))}
              </div>
              <div className="sub-container-second-table-order-displayer">
                <div className="content-second-table-order-displayer">{orderPosition}</div>
                <div className="content-second-table-order-displayer">{training?.title}</div>
                <div className="content-second-table-order-displayer">{getFormatedDate(order?.timeStamp.seconds)}</div>
                <div className="content-second-table-order-displayer">{translatePaymentMethod(order.paymentType)}</div>
                <div className="content-second-table-order-displayer">
                  {isAdmin ? (
                    <FormControl className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={paymentState}
                        onChange={handleChange}
                        label="Age"
                      >
                        <MenuItem value="En attente">En attente</MenuItem>
                        <MenuItem value="Confirmé">Confirmé</MenuItem>
                        <MenuItem value="Annulé">Annulé</MenuItem>
                        <MenuItem value="Avoir">Avoir</MenuItem>
                        <MenuItem value="Remboursé">Remboursé</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <>{order.paymentState}</>
                  )}
                </div>
                <div className="content-second-table-order-displayer link-order-displayer">
                  <a href={training?.pdf?.url} target="_blank" rel="noreferrer">
                    Télécharger le programme
                  </a>
                </div>
                <div className="content-second-table-order-displayer link-order-displayer">
                  <a href={contract?.contractUrl} target="_blank" rel="noreferrer">
                    Télécharger votre contrat
                  </a>
                </div>
                <div className="content-second-table-order-displayer">
                  {training?.price}
                  <span>€</span>
                </div>
              </div>
            </div>
            <div>{showFiles()}</div>
            <div className="container-address-displayer-order-displayer">
              <UserAddressDisplayer order={order} />
            </div>
          </div>
        </div>
      ) : (
        <div className="container-circular-progress-order-displayer">
          <CircularProgress />
        </div>
      )}
      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity="error">
          Une erreur est survenue contacter le service client
        </Alert>
      </Snackbar>
    </>
  );
}
