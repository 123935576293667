import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface Content {
  title: string;
  mainContent: string;
}

interface Props {
  content: Array<Content>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'normal',
    },
  }),
);

export default function AccordionDisplayer({ content }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {content.map((info) => (
        <Accordion key={info.title}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className={classes.heading}>{info.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{info.mainContent}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
