import React, { useEffect } from 'react';
import './presentation.css';
import { faHospitalAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button/Button';
import thomasImage from '../../images/thomas.jpg';
import mathieuImage from '../../images/mathieu.jpg';
import gaelImage from '../../images/gael.jpg';
import squeletteImage from '../../images/iffco-squelette.jpg';
import Banner from '../../components/banner/Banner';
import BreadCrumbs from '../../components/Bread-Crumbs/BreadCrumbs';
import AccordionDisplayer from '../../components/Accordion/Accordion';

export default function Presentation() {
  const breadCrumbs = [
    {
      link: '/',
      name: 'Accueil',
    },
    {
      link: '/presentation',
      name: "L'institut",
    },
  ];
  const content = [
    {
      title: `Pourquoi choisir l'iffco ?`,
      mainContent:
        'L’IFFCO vous propose de nombreuses formations spécifiques à tous les domaines d’intervention dans le milieu de l’Ostéopathie. De la pédiatrie, à la périnat mais aussi dans le sport et bien d’autres domaines.',
    },
    {
      title: 'Une gamme complète de formations',
      mainContent:
        'A l’IFFCO, toutes les formations sont dispensées par des formateurs reconnus dans le milieu de l’ostéopathie ou de la médecine. Des enseignements riches tant au niveau théorique que pratique.',
    },
    {
      title: `Des formateurs qualifiés et reconnus`,
      mainContent:
        'Nos formations se déroulent dans un cadre convivial, dans lequel vous pourrez mettre en application les enseignements suivis au cours de votre formation.',
    },
    {
      title: 'Une mise en pratique immédiate',
      mainContent:
        'En tant qu’ostéopathe, vous avez la possibilité de bénéficier d’une prise en charge de votre formation. Pour cela, nous vous invitons à visiter le site du FIFPL, l’organisme attitré pour les prises en charge.',
    },
  ];

  useEffect(() => {
    document.title = `IFFCO - Présentation`;
  }, []);

  return (
    <div className="main-container-presentation">
      <BreadCrumbs breadCrumb={breadCrumbs} />
      <div className="container-banner-presentation">
        <FontAwesomeIcon icon={faHospitalAlt} />
        <h2>Qu&apos;est-ce que l&apos;Iffco ?</h2>
      </div>
      <div className="subtitle-banner-presentation">
        <h4>Retrouvez ci-dessous tout les renseignements dont vous avez besoin pour en savoir plus sur l&apos;Iffco</h4>
      </div>
      <div className="sub-container-presentation">
        <div className="content-container-presentation">
          <div className="title-content-presentation">
            <h2>a propos de l&apos;iffco</h2>
            <h3>
              Un institut proposant des formations spécifiques à l&apos; ostéopathie dans divers domaines
              d&apos;application
            </h3>
          </div>
          <div className="first-content-presentation">
            <div>
              <div>
                L’IFFCO (Institut Français de Formation Continue en Ostéopathie) est un organisme de formation proposant
                des formations continues post-graduées s’adressant aux professionnels ostéopathes disposant d’un diplôme
              </div>
              <br />
              <div>
                Fondé en 2016, l’IFFCO est né du désir de 3 amis, tous 3 ostéopathes, collègues de promotion et
                passionnés par l’Ostéopathie.
              </div>
              <br />
              <div>
                Ayant pour volonté d’aller plus loin dans la découverte de nouvelles techniques, de nouveaux modes de
                traitements, et toujours pour améliorer notre pratique dans un but d’efficacité et de sécurité pour nos
                patients, nous avons participé à de nombreuses formations en France et à l’étranger. Désireux de pouvoir
                trouver des formations de qualité avec des intervenants reconnus, expérimentés et plus proches de chez
                nous (c’est-à-dire dans le Sud), nous avons longuement réfléchi à la meilleure façon de pouvoir
                satisfaire nos attentes. C’est donc après mûre réflexion qu’est naturellement venue l’idée de nous
                lancer dans cette nouvelle aventure de l’IFFCO.
              </div>
              <br />
              <div>
                L’IFFCO est donc un organisme de formation continue en ostéopathie, pensé PAR des ostéopathes POUR des
                ostéopathes.
              </div>
              <br />
              <div>
                Notre but est de proposer des formations de qualité, tant sur le plan du contenu (avec des formateurs
                reconnus, des thèmes d’actualités, au contact des derniers éléments de recherche), que sur le plan du
                contenant (en proposant un cadre propice à l’apprentissage, un matériel et un équipement de qualité,
                mais aussi de la confraternité et de la convivialité! ).
              </div>
              <br />
              <div>
                Nous nous efforçons de vous proposer des formations telles que nous aimerions les trouver nous-mêmes en
                tant que praticiens. Mais nul n’étant infaillible, nous sommes bien entendus ouvert à toutes suggestions
                ou améliorations pour vous satisfaire du mieux possible.
              </div>
              <br />
              <div>Mathieu, Thomas et Gaël</div>
            </div>
          </div>
          <div className="images-container-presentation">
            <img src={mathieuImage} alt="mathieu" />
            <img src={thomasImage} alt="thomas" />
            <img src={gaelImage} alt="gael" />
          </div>
          <div className="button-presentation-page">
            <a href="http://www.fifpl.fr/" target="_blank" rel="noreferrer">
              <Button variant="contained" color="primary">
                Nos formations
              </Button>
            </a>
          </div>
        </div>
      </div>
      <Banner />
      <div className="accordion-container-presentation-page">
        <div className="accordion-sub-presentation-page">
          <AccordionDisplayer content={content} />
        </div>
      </div>
      <div className="image-container-bottom-page">
        <img src={squeletteImage} alt="squelette" />
      </div>
    </div>
  );
}
