/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './TrainingsDisplayer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBriefcaseMedical,
  faCalendarAlt,
  faEuroSign,
  faMapMarkerAlt,
  faStopwatch,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { getAllTrainings } from '../../firestore/trainings';
import { FetchedDates, FetchedTraining, TrainingsWithNameAndProfessionNames } from '../../types/trainings';
import { getTrainerName, getTrainerProfessions } from '../../firestore/trainers';
import { diffDays } from '../Admin-Trainings/AdminTrainings';
import showDates from '../../utils/dates-utils';

export default function TrainingsDisplayer() {
  const [trainings, setTrainings] = useState<Array<FetchedTraining>>([]);
  const [loading, setLoading] = useState(false);
  const [trainingsWithTrainersName, setTrainingsWithTrainerName] = useState<Array<TrainingsWithNameAndProfessionNames>>(
    [],
  );

  const setTrainingsFromFirebase = async () => {
    setLoading(true);
    const trainingsFromFirebase = await getAllTrainings();
    if (trainingsFromFirebase) {
      setTrainings(trainingsFromFirebase);
      setLoading(false);
    }
  };

  useEffect(() => {
    setTrainingsFromFirebase();
  }, []);

  // GET NEW TRAINING ARRAY WITH TRAINERS NAME

  const newTrainersList = async () => {
    const newArrayTrainings: Array<TrainingsWithNameAndProfessionNames> = await Promise.all(
      trainings.map(async (training) => {
        const clonedTraining = JSON.parse(JSON.stringify(training)) as TrainingsWithNameAndProfessionNames;
        const idArray = await Promise.all(clonedTraining.trainers.map((i) => getTrainerName(i)));
        clonedTraining.trainerName = idArray;
        const trainingObjectWithProfession = await Promise.all(
          clonedTraining.trainersProfession.map((i) => getTrainerProfessions(i)),
        );
        clonedTraining.professionName = trainingObjectWithProfession;
        return clonedTraining;
      }),
    );
    if (newArrayTrainings.length > 0) {
      setTrainingsWithTrainerName(newArrayTrainings);
    }
  };

  useEffect(() => {
    newTrainersList();
  }, [trainings]);

  const showTraining = (value: TrainingsWithNameAndProfessionNames) => {
    if (value.statut === 'active') {
      return (
        <div className="sub-container-trainings-displayer" key={value.title}>
          <img src={value.image?.url} alt="trainings-displayer" />
          <div className="container-titles-trainings-displayer">
            <Link to={`/trainingview/${value.trainingId}`}>
              <h3>{value.title}</h3>
            </Link>
            {value.subtitle ? <h4>{`«${value.subtitle}»`}</h4> : null}
          </div>
          <div className="container-trainings-displayer">
            <div className="content-trainings-displayer">
              <div className="colored-content-trainings-displayer">
                <FontAwesomeIcon icon={faUser} />
                <div>
                  <Link to={`/trainerview/${value.trainers[0]}`}>
                    <Tooltip title={value?.trainerName ? value.trainerName.join(' & ') : ''}>
                      <div className="text-overflow-trainings-diplayer">
                        {value?.trainerName?.map((trainer, i) => {
                          return <div key={i}>{trainer}</div>;
                        })}
                      </div>
                    </Tooltip>
                  </Link>
                </div>
              </div>
              <div>
                <FontAwesomeIcon icon={faBriefcaseMedical} />
                <div>
                  <Tooltip title={value?.professionName ? value.professionName.join(' & ') : ''}>
                    <div className="text-overflow-trainings-diplayer">
                      {value?.professionName?.map((profession, i) => {
                        return <div key={i}>{profession}</div>;
                      })}
                    </div>
                  </Tooltip>
                </div>
              </div>
              {value?.dates?.map((date) => {
                return (
                  <div key={date.id}>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <div>{value ? showDates(date.startDate.seconds, date.endDate.seconds) : ''}</div>
                  </div>
                );
              })}
              <div>
                <FontAwesomeIcon icon={faStopwatch} />
                {value.dates && value.dates.length > 1
                  ? ` ${
                      value.dates.reduce((acc, item) => {
                        return diffDays(item.endDate.seconds, item.startDate.seconds) + acc;
                      }, 0) + 2 // +2 because we count the first day and the last day
                    } jour(s)`
                  : ` ${
                      value.dates &&
                      (value.dates || []).reduce((acc, item) => {
                        return diffDays(item.endDate.seconds, item.startDate.seconds) + acc;
                      }, 0) + 1 // +1 because we count the first day
                    } jour(s)`}
              </div>
              <div>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <div>{value.location}</div>
              </div>
              <div>
                <FontAwesomeIcon icon={faEuroSign} />
                <div>{value.price}</div>
              </div>
            </div>
            <div>
              <Link to={`/trainingview/${value.trainingId}`}>
                <div className="button-trainings-displayer">En savoir plus</div>
              </Link>
            </div>
          </div>
        </div>
      );
    }
    return '';
  };

  return (
    <>
      {loading ? (
        <div className="loading-container-trainings-displayer">
          <CircularProgress />
        </div>
      ) : (
        <div className="main-container-trainings-displayer">
          {trainingsWithTrainersName
            .sort((a, b) => {
              const recentDateA = a.dates?.sort((c, d) => {
                if (c.startDate.seconds > d.startDate.seconds) {
                  return 1;
                }
                if (c.startDate.seconds < d.startDate.seconds) {
                  return -1;
                }
                return 0;
              });
              const recentDateB = b.dates?.sort((c, d) => {
                if (c.startDate.seconds > d.startDate.seconds) {
                  return 1;
                }
                if (c.startDate.seconds < d.startDate.seconds) {
                  return -1;
                }
                return 0;
              });
              if (recentDateA && recentDateB) {
                if (recentDateA[0].startDate.seconds > recentDateB[0].startDate.seconds) {
                  return 1;
                }
                if (recentDateA[0].startDate.seconds < recentDateB[0].startDate.seconds) {
                  return -1;
                }
              }
              return 0;
            })
            .map((value) => showTraining(value))}
          <div className="bottom-text-trainings-displayer">
            <div>
              ATTENTION: Le nombre de places par formation étant volontairement limité, seules les inscriptions dont
              l’IFFCO aura reçu le ou les règlements seront considérées comme validées et terminées. En conséquence, un
              processus de commande réalisé mais sans réception par l’IFFCO du règlement ou du virement bancaire, ne
              peut donner droit à une « réservation » d’une place pour une formation. Les places sont donc attribuées,
              dans le cas des règlements par chèque(s) ou par virement bancaire, dans l’ordre d’arrivée au siège de
              l’IFFCO, jusqu’à l’atteinte du nombre maximal de places par formation. (Le paiement par Carte bancaire
              étant instantané, celui-ci valide automatiquement, dans le cas de l’acceptation du paiement par
              l’établissement bancaire, l’inscription.)
            </div>
          </div>
        </div>
      )}
    </>
  );
}
