/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useState } from 'react';
import './AdminMenu.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
  faCartPlus,
  faChevronDown,
  faClinicMedical,
  faPlus,
  faUser,
  faUserMd,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Badge, createStyles, Theme, withStyles } from '@material-ui/core';
import { getNewOrder } from '../../firestore/orders';

interface MenuProps {
  name: string;
  pathName: string;
  isActive: boolean;
  highlights: Array<string>;
  id: string;
  badge?: boolean;
  icon: IconDefinition;
}

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -16,
      top: 11,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }),
)(Badge);

export default function AdminMenu() {
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [clickedElement, setClickedElement] = useState<String | null>('Tableau de bord');
  const [numberOfNewOrder, setNumberOfNewOrder] = useState<Number>(0);
  const [tabName, setTabName] = useState<Array<MenuProps>>([
    {
      name: 'Resumé',
      pathName: '',
      isActive: false,
      highlights: ['resume'],
      id: '0',
      icon: faChartBar,
    },
    {
      name: 'Commandes',
      pathName: 'orders',
      isActive: false,
      highlights: ['orders'],
      id: '1',
      icon: faCartPlus,
      badge: true,
    },
    {
      name: 'Ajouter Commande',
      pathName: 'addorder',
      isActive: false,
      highlights: ['users'],
      id: '2',
      icon: faPlus,
    },
    {
      name: 'Formations',
      pathName: 'trainings',
      isActive: false,
      highlights: ['trainings'],
      id: '3',
      icon: faClinicMedical,
    },
    {
      name: 'Ajouter formation',
      pathName: 'addtraining',
      isActive: false,
      highlights: ['addtraining'],
      id: '4',
      icon: faPlus,
    },
    {
      name: 'Formateurs',
      pathName: 'trainers',
      isActive: false,
      highlights: ['trainers'],
      id: '5',
      icon: faUserMd,
    },
    {
      name: 'Ajouter formateur',
      pathName: 'addtrainer',
      isActive: false,
      highlights: ['addtrainer'],
      id: '6',
      icon: faPlus,
    },
    {
      name: 'Utilisateurs',
      pathName: 'users',
      isActive: false,
      highlights: ['users'],
      id: '7',
      icon: faUser,
    },
    {
      name: 'Ajouter utilisateur',
      pathName: 'adduser',
      isActive: false,
      highlights: ['users'],
      id: '8',
      icon: faPlus,
    },
  ]);

  const checkIfNewOrderOnStore = async () => {
    const newOrder = await getNewOrder();
    setNumberOfNewOrder(newOrder);
  };

  useEffect(() => {
    checkIfNewOrderOnStore();
  }, [pathname]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (name: string) => {
    setClickedElement(name);
    setAnchorEl(null);
  };

  const setAllToFalse = (tabActive: Array<MenuProps>, index: number) => {
    return tabName.map((c, i) => {
      if (i !== index) {
        c.isActive = false;
      }
      return c;
    });
  };

  useEffect(() => {
    const tabActive = [...tabName];
    let currentTab: string | null = null;
    const regex = /\/admin\/([a-z]+)/;
    const resultRegex = regex.exec(pathname);
    if (resultRegex) {
      [, currentTab] = resultRegex;
    }
    const index = tabActive.findIndex((c) => c.highlights.includes(currentTab || ''));
    if (index !== -1) {
      setClickedElement(tabActive[index].name);
      tabActive[index].isActive = true;
      setTabName(setAllToFalse(tabActive, index));
    }
  }, [pathname]);

  const handleIsActiveOnTabs = (id: string) => {
    const tabActive = [...tabName];
    const index = tabActive.findIndex((c) => c.id === id);
    if (index !== -1) {
      tabActive[index].isActive = !tabActive[index].isActive;
      setTabName(setAllToFalse(tabActive, index));
    }
  };

  return (
    <div className="main-container-admin-menu">
      <AppBar position="static">
        <Toolbar>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <FontAwesomeIcon icon={faChevronDown} />
            <div className="title-menu-admin">{clickedElement}</div>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {tabName.map((tab) => (
              <div key={tab.id}>
                <Link key={tab.name} to={`/admin/${tab.pathName}`}>
                  <div onClick={() => handleIsActiveOnTabs(tab.id)} role="button">
                    <MenuItem onClick={() => handleClose(tab.name)}>
                      <ListItemIcon>
                        <FontAwesomeIcon icon={tab.icon} />
                      </ListItemIcon>
                      {tab.badge ? (
                        <StyledBadge badgeContent={numberOfNewOrder.toString()} color="error">
                          Commande
                        </StyledBadge>
                      ) : (
                        tab.name
                      )}
                    </MenuItem>
                  </div>
                </Link>
              </div>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}
