/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Trainers from './pages/trainers/trainers';
import HowToReserve from './pages/How-To-Reserve/howToReserve';
import Header from './components/Header/Header';
import Home from './pages/home/home';
import Formations from './pages/Trainings/Trainings';
import Support from './pages/support/support';
import Presentation from './pages/presentation/presentation';
import Faq from './pages/faq/faq';
import Contact from './pages/contact/contact';
import Footer from './components/footer/Footer';
import AdditionalFooter from './components/additional-footer/AdditionalFooter';
import TopBar from './components/topbar/TopBar';
import Images from './pages/images/images';
import DrawerHeader from './components/drawer/Drawer';
import { setDrawerState } from './reducers/app';
import { store } from './store';
import LegalPage from './pages/Legal-Page/legalPage';
import PrivacyPolicy from './pages/Privacy-policy/privacyPolicy';
import SalesTerms from './pages/Sales-Terms/salesTerms';
import ScrollToTop from './components/scroll-To-Top/ScrollToTop';
import LandingPageUser from './pages/landing-page-user/LandingPageUser';
import { fire } from './config/fire';
import { getUserInfo } from './firestore/user';
import FormationView from './components/Training-view/TrainingView';
import TrainerView from './components/Trainer-View/TrainerView';
import OrderSteps from './components/Order-Steps/OrderSteps';
import ForgetPassword from './components/Forget-Password/ForgetPassword';
import PartnersPage from './components/Partners-Page/PartnersPage';
import Admin from './components/Admin/Admin';
import PageNotFound from './components/Page-Not-Found/PageNotFound';
import UserBoard from './components/User-Board/UserBoard';
import OrderDisplayer from './components/order-displayer/OrderDisplayer';
import UpdatingUserInfos from './components/Updating-User-Infos/UpdatingUserInfos';
import UserAddress from './components/User-Address/UserAddress';
import UserOrders from './components/User-Orders/UserOrders';
import UserDetails from './components/User-Get-Credentials/UserDetailsGetCredentials';
import AdminUserView from './components/Admin-User-View/AdminUserView';
import AdminTrainingView from './components/Admin-Training-view/AdminTrainingView';
import AdminEditTraining from './components/Admin-Edit-Training/AdminEditTraining';
import AdminTrainerView from './components/Admin-Trainer-View/AdminTrainerView';
import AdminEditTrainer from './components/Admin-Edit-Trainer/AdminEditTrainer';
import AdminAddOrder from './components/Admin-Add-Order/AdminAddOrder';
import AdminAddUser from './components/Admin-Add-User/AdminAddUser';
import AdminAddTrainer from './components/Admin-Add-Trainer/AdminAddTrainer';
import AdminAddTraining from './components/Admin-Add-Training/AdminAddTraining';
import AdminOrders from './components/Admin-Orders/AdminOrders';
import AdminResume from './components/Admin-Resume/AdminResume';
import AdminTrainers from './components/Admin-Trainers/AdminTrainers';
import AdminTrainings from './components/Admin-Trainings/AdminTrainings';
import AdminUsers from './components/Admin-Users/AdminUsers';

function App() {
  const routeChange = () => {
    store.dispatch(setDrawerState(false));
  };

  /*   ADDING USER TO REDUX */
  fire.auth().onAuthStateChanged((user) => {
    if (user?.uid) {
      getUserInfo(user.uid);
    }
  });

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <div className="topbar">
          <TopBar />
        </div>
        <div className="header">
          <Header />
        </div>
        <div className="drawer">
          <DrawerHeader />
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/formations" element={<Formations />} />
          <Route path="/trainingview/:formationId" element={<FormationView />} />
          <Route path="/ordersteps/:trainingId" element={<OrderSteps />} />
          <Route path="/howtoreserve" element={<HowToReserve />} />
          <Route path="/support" element={<Support />} />
          <Route path="/trainers" element={<Trainers />} />
          <Route path="/trainerview/:trainerId" element={<TrainerView />} />
          <Route path="/presentation" element={<Presentation />} />
          <Route path="/pictures" element={<Images />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/myspace" element={<LandingPageUser />}>
            <Route path="" element={<UserBoard />} />
            <Route path="orders" element={<UserOrders />} />
            <Route path="address" element={<UserAddress />} />
            <Route path="accountdetails" element={<UserDetails />} />
            <Route path="orderview/:orderId" element={<OrderDisplayer />} />
            <Route path="address/edit-address" element={<UpdatingUserInfos />} />
          </Route>
          <Route path="/legalpage" element={<LegalPage />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/salesterms" element={<SalesTerms />} />
          <Route path="/forgotpass" element={<ForgetPassword />} />
          <Route path="/partnersPage" element={<PartnersPage />} />
          <Route path="/admin" element={<Admin />}>
            <Route path="" element={<AdminResume />} />
            <Route path="/admin/orders" element={<AdminOrders />} />
            <Route path="/admin/addorder" element={<AdminAddOrder />} />
            <Route path="/admin/orderview/:orderId" element={<OrderDisplayer />} />
            <Route path="/admin/trainings" element={<AdminTrainings />} />
            <Route path="/admin/addtraining" element={<AdminAddTraining />} />
            <Route path="/admin/trainingview/:formationId" element={<AdminTrainingView />} />
            <Route path="/admin/edittraining/:formationId" element={<AdminEditTraining />} />
            <Route path="/admin/trainers" element={<AdminTrainers />} />
            <Route path="/admin/addtrainer" element={<AdminAddTrainer />} />
            <Route path="/admin/trainerview/:trainerId" element={<AdminTrainerView />} />
            <Route path="/admin/edittrainer/:trainerId" element={<AdminEditTrainer />} />
            <Route path="/admin/users" element={<AdminUsers />} />
            <Route path="/admin/adduser" element={<AdminAddUser />} />
            <Route path="/admin/userview/:userId" element={<AdminUserView />} />
          </Route>
          <Route element={<PageNotFound />} />
        </Routes>
        <Footer />
        <AdditionalFooter />
      </Router>
    </div>
  );
}

export default App;
