import firebase from 'firebase/app';
import { fire } from '../config/fire';
import { deleteFileStorage } from '../storage/storage';
import { FetchedTraining, Training, TrainingFiles } from '../types/trainings';

const db = fire.firestore();

export const createFile = async (fileName: string, fileUrl: string, trainingId: string, storageId: string) => {
  if (fileName && fileUrl) {
    try {
      const file = await db.collection('files').add({
        fileName,
        fileUrl,
        trainingId,
        storageId,
      });
      if (file.id) {
        await db.collection('files').doc(file.id).update({
          id: file.id,
        });
      }
      return file.id;
    } catch (e) {
      console.warn(e);
    }
  }
  return '';
};

export const getTrainingFiles = async (trainingId: string) => {
  if (trainingId) {
    try {
      const file = await db.collection('files').where('trainingId', '==', trainingId).get();
      const allFiles: Array<TrainingFiles> = [];
      file.forEach((doc) => {
        allFiles.push(doc.data() as TrainingFiles);
      });
      return allFiles;
    } catch (e) {
      console.warn(e);
    }
  }
  return null;
};

export const fetchNewFileFromStore = async (fileId: string) => {
  if (fileId) {
    try {
      await db.collection('files').doc(fileId).get();
    } catch (e) {
      console.warn(e);
    }
  }
};

export const addTraining = async (training: Training) => {
  if (training) {
    try {
      const newTraining = await db.collection('trainings').add({
        title: training.title,
        description: training.description,
        subtitle: training.subtitle,
        dates: training.dates,
        trainers: training.trainers,
        email: training.email,
        checkPaymentTemplate: training.checkPaymentTemplate,
        trainersProfession: training.trainersProfession,
        targets: training.targets,
        location: training.location,
        contract: training.contract,
        peopleConcerned: training.peopleConcerned,
        price: training.price,
        image: training.image,
        pdf: training.pdf,
        statut: 'inactive',
      });
      await db.collection('trainings').doc(newTraining.id).update({
        trainingId: newTraining.id,
      });
      return newTraining;
    } catch (e) {
      console.warn(e);
    }
  }
  return undefined;
};

export const duplicateTrainingStore = async (training: FetchedTraining) => {
  if (training) {
    try {
      const newTraining = await db.collection('trainings').add({
        title: `${training.title} copie`,
        description: training.description,
        dates: training.dates,
        trainers: training.trainers,
        trainersProfession: training.trainersProfession,
        targets: training.targets,
        location: training.location,
        contract: training.contract,
        peopleConcerned: training.peopleConcerned,
        price: training.price,
        image: training.image,
        pdf: training.pdf,
        statut: 'inactive',
      });
      await db.collection('trainings').doc(newTraining.id).update({
        trainingId: newTraining.id,
      });
      return newTraining;
    } catch (e) {
      console.warn(e);
    }
  }
  return undefined;
};

export const updateTrainingState = async (trainingId: string, trainingState: string) => {
  if (trainingId && trainingState) {
    try {
      // CREATE THE REF AND UPLOADING THE STATE OF THE PAYMENT
      const orderRef = db.collection('trainings').doc(trainingId);
      await orderRef.update({
        statut: trainingState,
      });
      return 'updated';
    } catch (e) {
      console.warn(e);
    }
  }
  return Promise.resolve();
};

export const updateTraining = async (id: string, training: Training) => {
  if (training) {
    try {
      const updatedTraining = await db.collection('trainings').doc(id).update({
        title: training.title,
        checkPaymentTemplate: training.checkPaymentTemplate,
        description: training.description,
        contract: training.contract,
        subtitle: training.subtitle,
        trainers: training.trainers,
        trainersProfession: training.trainersProfession,
        email: training.email,
        targets: training.targets,
        location: training.location,
        peopleConcerned: training.peopleConcerned,
        price: training.price,
        image: training.image,
        pdf: training.pdf,
        dates: training.dates,
      });
      return updatedTraining as unknown as FetchedTraining;
    } catch (e) {
      console.warn(e);
    }
  }
  return undefined;
};

export const getAllTrainings = async () => {
  try {
    const trainingsRef = db.collection('trainings');
    const snapshot = (await trainingsRef.get()) as firebase.firestore.QuerySnapshot<FetchedTraining>;
    const trainings: Array<FetchedTraining> = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      trainings.push(data);
    });
    return trainings;
  } catch (e) {
    console.warn(e);
  }
  return Promise.resolve();
};

export const getTraining = async (id: string | undefined) => {
  if (id) {
    try {
      const trainingRef = db.collection('trainings').doc(id);
      const doc = (await trainingRef.get()) as firebase.firestore.DocumentSnapshot<FetchedTraining>;
      if (doc) {
        const dataTraining = doc.data();
        return dataTraining;
      }
    } catch (e) {
      console.warn(e);
    }
  }
  return Promise.resolve();
};

export const deleteFilesFromStore = async (id: string) => {
  if (id) {
    try {
      await db.collection('files').doc(id).delete();
    } catch (e) {
      console.warn(e);
    }
  }
};

export const deleteTrainingFirestore = async (id: string) => {
  const training = await getTraining(id);
  const pdfPath = 'trainings-programs';
  const imagePath = 'trainings-images';
  if (training) {
    await deleteFileStorage(imagePath, training.image.id);
    await deleteFileStorage(pdfPath, training.pdf.id);
  }
  if (id) {
    try {
      await db.collection('trainings').doc(id).delete();
    } catch (e) {
      console.warn(e);
    }
  }
  return Promise.resolve();
};
