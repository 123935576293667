import React, { useEffect, useState } from 'react';
import './AdminOrders.css';
import { Link } from 'react-router-dom';
import { DataGrid, GridColDef, GridCellParams } from '@material-ui/data-grid';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Chip from '@material-ui/core/Chip';
import { getSingleUser } from '../../firestore/user';
import { User } from '../../types/users';
import { getAllOrders } from '../../firestore/orders';
import { Order } from '../../types/orders';
import { getTraining } from '../../firestore/trainings';
import { FetchedDates, FetchedTraining } from '../../types/trainings';
import { getFormatedDate } from '../Admin-Trainings/AdminTrainings';
import getOrderPosition from '../Utils/invoice-utils';

/* INTERFACE */

export interface TrainingOrderAndUser {
  id?: string;
  training: FetchedTraining;
  trainingTitle?: string;
  order: Order;
  user: User;
  orderIdReCalculated?: string;
}

export default function AdminOrders() {
  // ORDERS
  const [allOrders, setAllOrders] = useState<Array<Order>>([]);

  // ORDERS WITH TRAINING AND USERS INFOS
  const [orderWithTrainingAndUser, setOrderWithTrainingAndUser] = useState<Array<TrainingOrderAndUser>>();

  const [allOrdersAndUsersAndTrainings, setAllOrdersAndUsersAndTrainings] = useState<Array<TrainingOrderAndUser>>([]);

  const translatePaymentMethod = (name: string) => {
    if (name === 'sepa') {
      return 'Virement Bancaire';
    }
    if (name === 'checks') {
      return 'Chèque';
    }
    if (name === 'creditCard') {
      return 'Carte bancaire';
    }
    return 'Moyen de paiment';
  };

  const convertPaymentState = (state: string) => {
    if (state === 'Confirmé') {
      return <Chip className="confirmed-chip-admin-order" variant="outlined" label={state} />;
    }
    if (state === 'En attente') {
      return <Chip className="pending-chip-admin-order" variant="outlined" label={state} />;
    }
    if (state === 'Annulé') {
      return <Chip className="canceled-chip-admin-order" variant="outlined" label={state} />;
    }
    return '';
  };

  const displayTrainingDates = (dates?: Array<FetchedDates>) => {
    if (dates) {
      return dates
        .reduce((acc, date) => {
          const yearStart = date.startDate.toDate().getFullYear();
          const yearEnd = date.endDate.toDate().getFullYear();
          if (acc.indexOf(yearStart) === -1) {
            acc.push(yearStart);
          }
          if (acc.indexOf(yearEnd) === -1) {
            acc.push(yearEnd);
          }
          return acc;
        }, [] as Array<number>)
        .join(', ');
    }
    return '';
  };

  const [columns] = useState<GridColDef[]>([
    {
      field: 'newOrder',
      headerName: 'Vu',
      width: 50,
      renderCell: (params: GridCellParams) => {
        const order = params.getValue(params.id, 'newOrder') as Order;
        return (
          <div className="order-table-links">
            {order ? (
              <div className="new-order-icon-admin-order">
                <FontAwesomeIcon icon={faExclamationCircle} />
              </div>
            ) : (
              <div className="not-new-order-icon-admin-order">
                <FontAwesomeIcon icon={faExclamationCircle} />
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: 'id',
      headerName: 'Numéro de commande',
      width: 204,
      renderCell: (params: GridCellParams) => {
        const orderId = params.getValue(params.id, 'id') as Order['orderId'];
        const orderIdReCalculated = params.getValue(params.id, 'orderIdReCalculated') as string;
        return (
          <div className="order-table-links">
            <Link to={`/admin/orderview/${orderId}`}>{orderIdReCalculated}</Link>
          </div>
        );
      },
    },
    {
      field: 'user.lastName',
      headerName: 'Client',
      width: 210,
      renderCell: (params: GridCellParams) => {
        const user = params.getValue(params.id, 'user') as User;
        return (
          <div className="order-table-links">
            <Link to={`/admin/userview/${user.uId}`}>
              {user.firstName} {user.lastName}
            </Link>
          </div>
        );
      },
    },
    {
      headerName: 'Formation',
      field: 'trainingTitle',
      width: 350,
      renderCell: (params: GridCellParams) => {
        const training = params.getValue(params.id, 'training') as FetchedTraining;
        return (
          <div className="order-table-links">
            <Tooltip title={training.title}>
              <Link to={`/admin/trainingview/${training.trainingId}`}>
                {training.title} - {training.location} - {displayTrainingDates(training.dates)}
              </Link>
            </Tooltip>
          </div>
        );
      },
    },
    {
      headerName: 'Lieu',
      field: 'trainingLocation',
      width: 350,
      renderCell: (params: GridCellParams) => {
        const training = params.getValue(params.id, 'training') as FetchedTraining;
        return <div className="order-table-links">{training.location}</div>;
      },
    },
    {
      headerName: 'Années',
      field: 'trainingYears',
      width: 350,
      renderCell: (params: GridCellParams) => {
        const training = params.getValue(params.id, 'training') as FetchedTraining;
        return <div className="order-table-links">{displayTrainingDates(training.dates)}</div>;
      },
    },
    {
      headerName: 'Date de commande',
      field: 'date',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const date = params.getValue(params.id, 'date') as number;
        return <div className="order-table-row">{getFormatedDate(date)}</div>;
      },
    },
    {
      headerName: 'Type de paiement',
      field: 'paymentType',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const paymentType = params.getValue(params.id, 'paymentType') as string;
        return <div className="order-table-row">{translatePaymentMethod(paymentType)}</div>;
      },
    },
    {
      headerName: 'Statut',
      field: 'paymentState',
      width: 150,
      renderCell: (params: GridCellParams) => {
        const paymentState = params.getValue(params.id, 'paymentState') as string;
        return <div className="order-table-row">{convertPaymentState(paymentState)}</div>;
      },
    },
    {
      headerName: 'Prix',
      field: 'price',
      width: 100,
      renderCell: (params: GridCellParams) => {
        const price = params.getValue(params.id, 'price') as string;
        return <div className="order-table-row">{`${price} €`}</div>;
      },
    },
  ]);

  // GET ALL ORDER FROM FIRSTORE

  const getAllOrdersFromStore = async () => {
    const ordersFromStore = await getAllOrders();
    if (ordersFromStore) {
      setAllOrders(ordersFromStore);
    }
  };

  const getAllOrdersAndUsersAndTrainingsFromStore = async () => {
    const ordersFromStore = await getAllOrders();
    const remapOrdersFromStore = await Promise.all(
      ordersFromStore.map(async (order) => {
        const training = (await getTraining(order.trainingId)) as unknown as FetchedTraining;
        const user = (await getSingleUser(order.userId)) as User;
        return {
          id: order.orderId,
          newOrder: order.newOrder,
          date: order.timeStamp.seconds,
          training,
          order,
          user,
          trainingTitle: training.title,
          trainingLocation: training.location,
          paymentType: order.paymentType,
          paymentState: order.paymentState,
          price: training.price,
          orderIdReCalculated: (await getOrderPosition(order.orderId)).toString(),
        };
      }),
    );
    setAllOrdersAndUsersAndTrainings(remapOrdersFromStore);
  };

  useEffect(() => {
    getAllOrdersFromStore();
    getAllOrdersAndUsersAndTrainingsFromStore();
  }, []);

  const createArrayUserOrderTraining = async () => {
    if (allOrders) {
      const orderTrainingAndUser = await Promise.all(
        allOrders.map(async (order) => {
          const training = (await getTraining(order.trainingId)) as unknown as FetchedTraining;
          const user = (await getSingleUser(order.userId)) as User;
          return { training, order, user };
        }),
      );
      if (orderTrainingAndUser) {
        setOrderWithTrainingAndUser(orderTrainingAndUser);
      }
    }
  };

  useEffect(() => {
    createArrayUserOrderTraining();
  }, [allOrders]);

  return (
    <div className="main-container-admin-orders">
      {orderWithTrainingAndUser ? (
        <div style={{ height: 1000, width: '100%' }}>
          <DataGrid rows={allOrdersAndUsersAndTrainings} columns={columns} pageSize={50} hideFooterSelectedRowCount />
        </div>
      ) : null}
    </div>
  );
}
