import { faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import React, { useEffect } from 'react';
import BreadCrumbs from '../../components/Bread-Crumbs/BreadCrumbs';
import './support.css';

export default function Support() {
  const breadCrumbs = [
    {
      link: '/',
      name: 'Accueil',
    },
    {
      link: '/support',
      name: 'Prise en charge',
    },
  ];

  useEffect(() => {
    document.title = `IFFCO - Prise en charge`;
  }, []);

  return (
    <div className="main-container-support">
      <BreadCrumbs breadCrumb={breadCrumbs} />
      <div className="container-banner-support">
        <FontAwesomeIcon icon={faHandsHelping} />
        <h2>Prise en charge</h2>
      </div>
      <div className="subtitle-banner-support">
        <h4>Retrouvez ci-dessous les critères et modalitées de prise en de votre formation</h4>
      </div>
      <div className="main-content-support">
        <div className="sub-container-support">
          <div className="first-content-support">
            <h2 className="titles-support">Les critères et modalitées de prise en charge de votre formations</h2>
            <div>
              Concernant les Ostéopathes, c’est le FIFPL (Fonds Interprofessionnel de formation des professionnels
              libéraux) qui arrête chaque année ces critères.
            </div>
            <div>
              Tout ostéopathe qui souhaite bénéficier d’une prise en charge de sa formation doit compléter au préalable
              sa demande de prise en charge, directement en ligne sur le site du FIFPL, en ayant préalablement créé son
              propre compte.
            </div>
            <div>
              Les formations proposées par notre organisme sont pensées de façon à répondre au plus proche aux critères
              et thèmes définis chaque année par le FIFPL.
            </div>
          </div>
          <div className="second-content-support">
            <h2 className="titles-support">Prise en charge de ma formation avec le FIFPL</h2>
            <li>1) Connectez vous au https://netopca.fifpl.fr/</li>
            <li>2) Créer votre compte ou connectez vous</li>
            <li>3) Cliquer sur « Saisissez votre demande préalable de prise en charge »</li>
            <li> 4) Action de formation </li>
            <div className="list-content-support">
              <ul>
                <li>A. Choisissez « Non assujetti à la TVA »</li>
                <li>B. Répondez oui ou non à « auto entrepreneur »</li>
                <li>C. Précisez votre domaine d’activité : ostéopathe, kinésithérapeute, …</li>
                <li>D. Type de formation : choisissez « action de formation »</li>
                <li>
                  E. Organisme de Formation : choisissez IFFCO-Institut Français de formation continue en ostéopathie
                </li>
                <li>51 Rue de la Méditerranée, 34000 Montpellier</li>
                <li>F. Formation à distance : NON</li>
                <li>G. Lieu : Dans l’organisme, en France/DOM TOM</li>
                <li>H. Intitulé de la formation : Indiquez le nom de la formation</li>
                <li>I. Date de début : Indiquez la date de début de la formation</li>
                <li>J. Date de fin : Indiquez la date de fin de la formation</li>
                <li>K. Nombre total d’heures : Indiquez le nombre d’heures de la formation</li>
                <li>L. Nombre de jours de formation : Indiquez le nombre de jours de la formation</li>
                <li>M. Cout pédagogique par stagiaire : Indiquez le coût de la formation par stagiaire</li>
                <li>N. Montant TTC : Indiquez le montant de la formation</li>
              </ul>
            </div>
            <div>5) Pièces à joindre :</div>
            <div className="list-content-support">
              <li>A. Votre RIB en PDF</li>
              <li>B. Votre attestation de versement URSSAF en PDF</li>
              <li>C. Programme détaillé : joignez votre contrat de formation que vous avez signé</li>
              <li>D. Devis ou convention de stage : joignez votre contrat de formation que vous avez signé</li>
              <li>
                E. Attestation de présence et de règlement : joignez l’attestation de présence et de règlement que nous
                vous avons envoyé par mail. (ATTENTION : nous ne fournissons pas d’attestations de présence et de
                règlement avant la FIN du séminaire). Vous devrez donc fournir ce papier une fois le séminaire effectué.
              </li>
            </div>
          </div>
          <div className="container-button-support">
            <a href="http://www.fifpl.fr/" target="_blank" rel="noreferrer">
              <Button variant="contained" color="primary">
                Lien vers le site du fifpl
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
