/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-danger */
import React, { useState, useEffect, ChangeEvent } from 'react';
import './OrderContract.css';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import format from 'date-fns/format';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import signatureIffco from '../../images/signatureIffcoTransparent.jpg';
import iffcoLogo from '../../images/logo-header.png';
import { getTraining } from '../../firestore/trainings';
import { FetchedTraining, TrainingsWithNameAndProfessionNames } from '../../types/trainings';
import { getTrainerName } from '../../firestore/trainers';
import { RootReducer } from '../../store';
import { UserInfo } from '../../reducers/app';
import { getFormatedDate } from '../Admin-Trainings/AdminTrainings';
import { iffcoB64, pdgSignature } from '../../images/iffcobase64';
import { addContractStorage } from '../../storage/storage';
import { Contract } from '../../types/contract';
import { functions } from '../../config/fire';
import b64toBlob from '../../utils/b64toBlob';
import { addContractStore } from '../../firestore/contracts';
import { updateUserHandicappedInfo, updateUserInfo } from '../../firestore/user';
import { User } from '../../types/users';

interface Order {
  trainingId: string | undefined;
  handleNext: () => void;
}

export default function OrderContract({ trainingId, handleNext }: Order) {
  const userInfo = useSelector<RootReducer, UserInfo | null>((state) => state.app.user);
  const [training, setTraining] = useState<FetchedTraining>();
  const [loading, setLoading] = useState<Boolean>(false);
  const [errorText, setErrorText] = useState<string>('Veuillez signer le contrat avant de continuer');
  const [error, setError] = useState<boolean>(false);
  const [readAndApproveField, setReadAndApproveField] = useState<string>();
  const [newTrainingWithName, setNewTrainingWithName] = useState<TrainingsWithNameAndProfessionNames>();
  const [handicap, setHandicap] = React.useState(false);

  // FETCH THE REQUIRED TRAINING

  const fetchTraining = async () => {
    if (trainingId) {
      const trainingInfos = await getTraining(trainingId);
      if (trainingInfos) {
        setTraining(trainingInfos);
      }
    }
  };

  useEffect(() => {
    document.title = 'IFFCO - Contrat';
    fetchTraining();
  }, [trainingId]);

  // GET THE DATE AND HOUR FOR THE SIGNATURE
  const getDate = () => {
    const formattedDate = format(new Date(), 'dd/MM/yyyy');
    return formattedDate;
  };

  const getHour = () => {
    const formatedHour = format(new Date(), 'HH:mm');
    return formatedHour;
  };

  // MAKING THE NEW TRAINING WITH TRAINER NAMES AND PROFESSION FETCHED FROM THE STORE

  const newTrainingList = async () => {
    if (training) {
      const clonedTraining = JSON.parse(JSON.stringify(training)) as TrainingsWithNameAndProfessionNames;
      const trainingObjectWithName = await Promise.all(clonedTraining.trainers.map((i) => getTrainerName(i)));
      clonedTraining.trainerName = trainingObjectWithName;
      setNewTrainingWithName(clonedTraining);
    }
  };

  useEffect(() => {
    newTrainingList();
  }, [training]);

  // HANDLING INPUTS

  const handleInputValue = (e: { target: { value: string } }) => {
    setReadAndApproveField(e.target.value);
  };

  const getUserIp = async () => {
    const userIp = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
    const ip = await userIp.text();
    const regex = /ip=(.*)\n/;
    const resultRegex = regex.exec(ip);
    if (resultRegex) {
      return resultRegex[1];
    }
    return '';
  };
  // PDF CALL TO THE BACK END TO RECEIVE A BLOB AND UPDATE IT TO THE STORE / STORAGE

  const pdf = async () => {
    setLoading(true);
    // CREATING HTML DOCUMENT
    const html = document.createElement('html');

    // CREATING IMG
    const divImg = document.createElement('div');
    divImg.style.textAlign = 'center';
    divImg.style.paddingBottom = '18px';
    const img = document.createElement('img');
    img.src = iffcoB64;
    divImg.appendChild(img);
    html.appendChild(divImg);

    // CONTRACT AND DYNAMIC CONTENT
    const firstContainerOrderContract = document.querySelector<HTMLElement>('.fist-container-order-contract');
    if (firstContainerOrderContract) {
      const cloned = firstContainerOrderContract.cloneNode(true) as HTMLElement;
      cloned.style.textAlign = 'center';
      html.appendChild(cloned);
    }
    const secondContainerOrderContract = document.querySelector<HTMLElement>('.second-container-order-contract');
    if (secondContainerOrderContract) {
      const cloned = secondContainerOrderContract.cloneNode(true) as HTMLElement;
      cloned.style.textAlign = 'center';
      html.appendChild(cloned);
    }

    const thirdContainerOrderContract = document.querySelector<HTMLElement>('.third-container-order-contract');
    if (thirdContainerOrderContract) {
      const cloned = thirdContainerOrderContract.cloneNode(true) as HTMLElement;
      cloned.style.textAlign = 'center';
      html.appendChild(cloned);
    }

    const fourthContainerOrderContract = document.querySelector<HTMLElement>('.fourth-container-order-contract');
    if (fourthContainerOrderContract) {
      const cloned = fourthContainerOrderContract.cloneNode(true) as HTMLElement;
      cloned.style.textAlign = 'center';
      html.appendChild(cloned);
    }

    const fifthContainerOrderContract = document.querySelector<HTMLElement>('.fifth-container-order-contract');
    if (fifthContainerOrderContract) {
      const cloned = fifthContainerOrderContract.cloneNode(true) as HTMLElement;
      cloned.style.textAlign = 'center';
      html.appendChild(cloned);
    }

    const sixthContainerOrderContract = document.querySelector<HTMLElement>('.title-sixth-container-order-contract');
    if (sixthContainerOrderContract) {
      const cloned = sixthContainerOrderContract.cloneNode(true) as HTMLElement;
      cloned.style.textAlign = 'center';
      html.appendChild(cloned);
    }

    // CREATING BOTTOM IMAGE OF THE CONTRACT
    const divImgSignature = document.createElement('div');
    divImgSignature.style.textAlign = 'center';
    const imgSignature = document.createElement('img');
    imgSignature.style.width = '150px';
    imgSignature.src = pdgSignature;
    divImgSignature.appendChild(imgSignature);
    html.appendChild(divImgSignature);

    const seventhContainerOrderContract = document.querySelector<HTMLElement>('.container-pdf-display-none');
    if (seventhContainerOrderContract) {
      const cloned = seventhContainerOrderContract.cloneNode(true) as HTMLElement;
      cloned.style.textAlign = 'center';
      html.appendChild(cloned);
    }

    const eigthContainerOrderContract = document.querySelector<HTMLElement>('.container-client-sign-display-none');
    if (eigthContainerOrderContract) {
      const cloned = eigthContainerOrderContract.cloneNode(true) as HTMLElement;
      cloned.style.textAlign = 'center';
      html.appendChild(cloned);
    }

    const userIp: string = await getUserIp();

    const signedDate = getDate();
    const signedHour = getHour();

    const apiPdf = functions.httpsCallable('apiPdf');
    const response = await apiPdf({ html: `<html>${html.outerHTML}</html>` });
    const fileToSend = b64toBlob(response.data.file);
    const userName = `${userInfo?.lastName} ${userInfo?.firstName}`;
    const trainingName = training?.title ? training.title : '';
    const addContract = await addContractStorage(fileToSend, userName, trainingName);
    const userData: User = {
      handicapped: handicap,
    };
    if (userInfo?.uId) {
      await updateUserHandicappedInfo(userInfo?.uId, userData);
    }
    const contract: Contract = {
      asDownload: false,
      signedHour,
      signedDate,
      userId: userInfo?.uId || '',
      trainingId: training?.trainingId || '',
      contractUrl: addContract || '',
      asSigned: true,
      userIp,
    };
    await addContractStore(contract);
    handleNext();
    setLoading(false);
  };
  const toSearchFormatedString = (str?: string) => {
    if (str) {
      return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-zA-Z0-9]+/g, '')
        .toLowerCase();
    }
    return '';
  };

  const submitSignature = async () => {
    const userData: User = {
      handicapped: handicap || false,
    };
    if (readAndApproveField) {
      const answerToLowerCase = toSearchFormatedString(readAndApproveField.toLowerCase());
      if (answerToLowerCase === 'luetapprouve') {
        if (userInfo?.uId && handicap) {
          await updateUserHandicappedInfo(userInfo.uId, userData);
        }
        pdf();
        setError(false);
      } else {
        setError(true);
        setErrorText('La saisie ne correspond pas');
      }
    } else {
      setError(true);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHandicap(event.target.value === 'yes');
  };

  return (
    <div className="main-container-order-contract">
      <div className="sub-container-order-contract" id="toto">
        <div className="container-img-order-contract">
          <img src={iffcoLogo} alt="logo" />
        </div>
        <div>
          <div>
            <div className="fist-container-order-contract">
              <div>Contrat {newTrainingWithName?.title}</div>
              <div>{newTrainingWithName?.trainerName}</div>
              <div>IFFCO</div>
              <div>
                {(newTrainingWithName && newTrainingWithName?.dates?.length) || 0 > 1
                  ? 'La formation se déroulera sur plusieurs dates :'
                  : null}
              </div>
              <div>
                {newTrainingWithName
                  ? newTrainingWithName &&
                    newTrainingWithName?.dates?.map((date) => {
                      return (
                        <div key={date.id}>
                          Du {getFormatedDate(date.startDate.seconds)} au {getFormatedDate(date.endDate.seconds)}
                        </div>
                      );
                    })
                  : ''}
              </div>
            </div>
            <div className="second-container-order-contract">
              <div>
                <div>INSTITUT FRANÇAIS DE FORMATION CONTINUE EN OSTEOPATHIE – IFFCO</div>
                <div>51, Rue de la Méditerranée, 34000 Montpellier</div>
              </div>
              <div>
                <div>Téléphone : 04.90.57.80.83 Email : iffco.contact@gmail.com</div>
                <div>Numéro de déclaration : 76340916034</div>
              </div>
            </div>
            <div className="third-container-order-contract">
              <div>
                CONTRAT DE FORMATION PROFESSIONNELLE
                <br />
                (Articles L.6353-3 à L.6353-7 du Code du Travail)
              </div>
              <div>
                ENTRE : L’ORGANISME DE FORMATION : INSTITUT FRANÇAIS DE FORMATION CONTINUE EN OSTEOPATHIE (I.F.F.C.O.),
                Société par Actions Simplifiée au capital de 7.500 Euros, immatriculée au registre du Commerce et des
                Sociétés de MONTPELLIER sous le numéro 821 623 550, dont le siège social est situé à 51, Rue de la
                Méditerranée, 34000 Montpellier, Pris en la personne de son représentant légal en exercice, à savoir
                Monsieur Mathieu LETURCQ en qualité de Président, Déclaration d’activité de l’organisme de formation
                enregistrée sous le numéro 76340916034 auprès du Préfet de la Région Languedoc Roussillon (cet
                enregistrement ne vaut pas agrément de l’État).
              </div>
            </div>
            <div className="fourth-container-order-contract">
              <div>Et le contractant :</div>
              <div>Nom : {userInfo?.lastName}</div>
              <div>Prénom : {userInfo?.firstName}</div>
              <div>Date de naissance : {userInfo?.birthDate}</div>
              <div>Lieu de naissance : {userInfo?.birthPlace}</div>
              <div>Adresse professionelle : {userInfo?.address}</div>
              <div>Profession : {userInfo?.profession}</div>
              <div>Téléphone: {userInfo?.phone}</div>
              <div>E-mail: {userInfo?.email}</div>
            </div>
            <div className="fifth-container-order-contract">
              <div>CONTENU DU PRESENT CONTRAT</div>
              <div
                className="dynamic-content-order-contract"
                dangerouslySetInnerHTML={{ __html: newTrainingWithName?.contract || '' }}
              />
            </div>
            <div className="sixth-container-order-contract">
              <div>
                <Tooltip placement="top" title="Votre réponse restera strictement confidentielle">
                  <div className="handicap-selection-contract">
                    Etes-vous une personne en situation de handicap particulier, même invisible ?*
                  </div>
                </Tooltip>
                <FormControl>
                  <RadioGroup row onChange={handleChange} defaultValue="no">
                    <FormControlLabel value="yes" control={<Radio color="primary" size="small" />} label="Oui" />
                    <FormControlLabel value="no" control={<Radio color="primary" size="small" />} label="Non" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="title-sixth-container-order-contract">Pour le stagiaire : </div>
              <div className="signature-container-order-contract">
                <div>
                  <div className="name-date-signature-order-contract">
                    {userInfo?.lastName} {userInfo?.firstName}
                  </div>
                  <TextField
                    id="agreed"
                    variant="outlined"
                    label="Ecrire Lu et Approuvé"
                    onChange={handleInputValue}
                    error={error}
                  />
                  <div className="name-date-signature-order-contract">
                    {getDate()} {getHour()}
                  </div>
                </div>
                <div className="signature-iffco-order-contract">
                  <img src={signatureIffco} alt="iffco" />
                  <div>Signé le 04/12/2020</div>
                  <div>Par LETURCQ Mathieu Président IFFCO</div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-error-order-contract">
            {error ? <Alert severity="error">{errorText}</Alert> : ''}
          </div>
          {loading ? (
            <div className="container-circular-progress">
              <CircularProgress />
            </div>
          ) : (
            <div className="container-submit-button-order-contract">
              <Button variant="contained" color="primary" onClick={submitSignature}>
                Accepter et signer
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="container-pdf-display-none">
        <div>Signé le 04/12/2020</div>
        <div>Par LETURCQ Mathieu Président IFFCO</div>
      </div>
      <div className="container-client-sign-display-none">
        <div className="name-date-signature-order-contract">
          {userInfo?.lastName} {userInfo?.firstName}
        </div>
        <div>
          Lu et approuvé le {getDate()} à {getHour()}
        </div>
      </div>
    </div>
  );
}
