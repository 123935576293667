import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getOrder } from '../../firestore/orders';
import { UserInfo } from '../../reducers/app';
import { RootReducer } from '../../store';
import { Order } from '../../types/orders';
import { TrainingsWithNameAndProfessionNames } from '../../types/trainings';
import showDates from '../../utils/dates-utils';
import { diffDays } from '../Admin-Trainings/AdminTrainings';
import './OrderSepaConfirmation.css';

interface Props {
  newTrainingWithName: TrainingsWithNameAndProfessionNames | null;
}

export default function OrderSepaConfirmation({ newTrainingWithName }: Props) {
  const userInfo = useSelector<RootReducer, UserInfo | null>((state) => state.app.user);
  const [currentOrder, setCurrentOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getOrderFromStore = async () => {
    if (userInfo?.uId && newTrainingWithName?.trainingId) {
      setLoading(true);
      const order = await getOrder(newTrainingWithName.trainingId, userInfo.uId);
      setCurrentOrder(order);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderFromStore();
  }, [newTrainingWithName]);

  return (
    <>
      {newTrainingWithName ? (
        <div className="main-container-sepa-confirmation">
          {loading ? (
            <div className="container-circularprogress-sepa-confirmation">
              <CircularProgress />
            </div>
          ) : (
            <div className="sub-container-sepa-confirmation">
              <div>
                <FontAwesomeIcon icon={faCheckCircle} />
              </div>
              <div className="main-content-sepa-confirmation">
                <div>
                  <span>Confirmation de la pré-inscription à la formation</span> : {newTrainingWithName?.title}
                </div>
                <div>
                  <span>Dispensé par le(s) formateur(s)</span> :
                  {newTrainingWithName?.trainerName ? newTrainingWithName.trainerName.join(' & ') : ''}
                </div>
                <div>
                  <span>D&apos;une durée de</span> :
                  {newTrainingWithName
                    ? ` ${
                        newTrainingWithName?.dates?.reduce((acc, item) => {
                          return diffDays(item.endDate.seconds, item.startDate.seconds) + acc;
                        }, 0) || 0 + 1
                      } jour(s)`
                    : ''}
                </div>
                <div>
                  <span>Date(s) </span>
                  {`: 
            ${
              newTrainingWithName
                ? newTrainingWithName.dates?.map((date) => {
                    return showDates(date.startDate.seconds, date.endDate.seconds);
                  })
                : ''
            }`}
                </div>
              </div>
              <div className="main-content-sepa-confirmation link-sepa-confirmation">
                Vous avez choisi d’effectuer votre règlement d’un montant de{' '}
                <span>{newTrainingWithName?.price} € </span> par virement bancaire et nous vous en remercions.
              </div>
              <div className="main-content-sepa-confirmation link-sepa-confirmation">
                Le paiement se fait directement depuis votre compte bancaire en ligne. Nous vous remercions de bien
                vouloir utiliser les identifiants de votre commande comme référence du paiement :{' '}
                <span>{currentOrder?.orderId}</span>, ainsi que nos coordonnées bancaires qui s’affiche ci dessous, un
                duplicata de ce message vous à été envoyé par mail.
              </div>
              <div className="main-content-sepa-confirmation link-sepa-confirmation">Nos coordonées bancaire :</div>
              <div className="table-rib-sepa-confirmation">
                <table>
                  <thead>
                    <tr>
                      <th>Banque</th>
                      <th>Numéro de compte</th>
                      <th>Code de guichet</th>
                      <th>IBAN</th>
                      <th>BIC</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-label="Banque">30004</td>
                      <td data-label="Numéro de compte">00010073621</td>
                      <td data-label="Code de guichet">02563</td>
                      <td data-label="IBAN">FR76 3000 4025 6300 0100 7362 190</td>
                      <td data-label="BIC">BNPA FR PP XXX</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="main-content-sepa-confirmation link-sepa-confirmation">
                <div>Nous vous remercions par avance pour votre paiement.</div>
                <div>
                  Suite à la récéption de votre paiement un mail devrait vous parvenir vous confirmation l’inscription
                  <div>
                    Vous pourrez accéder au suivi de celle-ci ainsi que consulter votre facture dans votre
                    <Link target="_blank" to="/myspace/orders">
                      espace client.
                    </Link>
                  </div>
                </div>
              </div>
              <div className="main-content-sepa-confirmation">
                <div>Veuillez recevoir nos sincères salutations,</div>
                <div>L’équipe Iffco</div>
              </div>
            </div>
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
}
