import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { app, AppState } from './reducers/app';

export interface RootReducer {
  app: AppState;
}

const rootReducer = combineReducers({
  app: app.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
