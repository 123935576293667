/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { UserInfo } from '../../reducers/app';
import { getUserInfo, updateUserInfo } from '../../firestore/user';
import { RootReducer } from '../../store';
import './UpdatingUserInfos.css';
import { User } from '../../types/users';

export interface InputValues {
  field: string;
  error: boolean;
  value: string;
  type?: string;
  label: string;
  required: boolean;
  errorMessage: string;
  defaultValue: string | number | undefined;
  disable?: boolean;
}

export default function OrderUserAddress() {
  const userInfos = useSelector<RootReducer, UserInfo | null>((state) => state.app.user);
  const [loading, setLoading] = useState<Boolean>(false);

  const [validationMessage, setValidationMessage] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [inputValues, setInputValues] = useState<Array<InputValues>>([
    {
      field: 'lastName',
      label: 'Nom*',
      value: userInfos?.firstName ? userInfos?.firstName : '',
      defaultValue: userInfos?.firstName,
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'firstName',
      label: 'Prénom*',
      value: userInfos?.lastName ? userInfos?.lastName : '',
      defaultValue: userInfos?.lastName,
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'profession',
      label: 'Profession*',
      value: userInfos?.profession || '',
      defaultValue: userInfos?.profession || '',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'birthDate',
      label: 'Date de naissance',
      type: 'date',
      defaultValue: userInfos?.birthDate || '2021-01-01',
      error: false,
      value: userInfos?.birthDate || '',
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'birthPlace',
      label: 'Lieu de naissance*',
      value: userInfos?.birthPlace || '',
      defaultValue: userInfos?.birthPlace || '',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'country',
      label: 'Pays',
      value: 'France',
      defaultValue: 'France',
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'address',
      label: 'Adresse*',
      value: userInfos?.address ? userInfos.address : '',
      defaultValue: userInfos?.address,
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'compAddress',
      label: "Complément d'adresse",
      value: userInfos?.compAddress ? userInfos.compAddress : '',
      defaultValue: userInfos?.compAddress,
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: false,
    },
    {
      field: 'zipCode',
      label: 'Code Postal*',
      value: userInfos?.zipCode ? userInfos.zipCode : '',
      defaultValue: userInfos?.zipCode,
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'city',
      label: 'Ville*',
      value: userInfos?.city ? userInfos.city : '',
      defaultValue: userInfos?.city,
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
    {
      field: 'phone',
      label: 'Téléphone*',
      value: userInfos?.phone ? userInfos?.phone : '',
      defaultValue: userInfos?.phone,
      error: false,
      errorMessage: 'Veuillez renseigner ce champ',
      required: true,
    },
  ]);

  const changeHandler = (e: { target: { name: string; value: string } }) => {
    const index = inputValues.findIndex((a) => a.field === e.target.name);
    inputValues[index].value = e.target.value;
    setInputValues(inputValues);
  };

  const submitValues = async () => {
    const newAllValues = inputValues.map((value) => {
      if (!value.value && value.required === true) {
        value.error = true;
      } else {
        value.error = false;
      }
      return value;
    });

    setInputValues(newAllValues);
    if (newAllValues.some((v) => v.error)) {
      setErrorMessage('Veuillez renseigner tout les champs');
    } else if (userInfos?.uId) {
      const userData: User = {
        lastName: inputValues[0].value || userInfos?.firstName,
        firstName: inputValues[1].value || userInfos?.lastName,
        profession: inputValues[2].value || userInfos?.profession,
        birthDate: inputValues[3].value || userInfos?.birthPlace,
        birthPlace: inputValues[4].value || userInfos?.birthPlace,
        country: inputValues[5].value || 'France',
        address: inputValues[6].value || userInfos?.address,
        compAddress: inputValues[7].value || userInfos?.compAddress || '',
        zipCode: inputValues[8].value || userInfos?.zipCode,
        city: inputValues[9].value || userInfos?.city,
        phone: inputValues[10].value || userInfos?.phone,
      };
      setLoading(true);
      await updateUserInfo(userInfos?.uId, userData);
      await getUserInfo(userInfos?.uId);
      setValidationMessage(true);
      setLoading(false);
    }
  };

  return (
    <div className="main-container-updating-user-info">
      <div className="sub-container-updating-user-info">
        <div className="main-title-updating-user-info">Informations personnelle</div>
        {inputValues.map((value) => (
          <div className="container-text-field-updating-user-info" key={value.field}>
            <TextField
              defaultValue={value.defaultValue}
              error={value.error}
              key={value.field}
              name={value.field}
              label={value.label}
              helperText={value.error ? value.errorMessage : ''}
              fullWidth
              variant="outlined"
              type={value.type ? value.type : ''}
              onChange={changeHandler}
              disabled={value.disable}
            />
          </div>
        ))}
        <div className="container-text-field-updating-user-info">
          <TextField
            defaultValue={userInfos?.email}
            label="E-mail"
            helperText="Pour modifier votre adresse mail, veuillez vous rendre dans la rubrique détails du compte"
            fullWidth
            variant="outlined"
            onChange={changeHandler}
            disabled
          />
        </div>
        {validationMessage ? (
          <div className="validation-message-user-infos">
            <Alert severity="success">Vos informations on étaient mis à jour</Alert>
          </div>
        ) : (
          ''
        )}
        {errorMessage ? (
          <div className="validation-message-user-infos">
            <Alert severity="error">{errorMessage}</Alert>
          </div>
        ) : (
          ''
        )}

        <div className="next-buttons-container-updating-user-info">
          {loading ? (
            <div className="circular-progress-user-info">
              <CircularProgress />
            </div>
          ) : (
            <Button variant="contained" color="primary" onClick={submitValues}>
              Enregistrer
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
